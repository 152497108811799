import { Button, Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent } from '@mui/material';

type ConfirmDialogType = {
	open: boolean;
	onClose: () => void;
	title: string;
	message: string;
	cancelLabel: string;
	saveLabel: string;
	onApply: () => void;
};
function ConfirmDialog({ open, onClose, title, message, cancelLabel, saveLabel, onApply }: ConfirmDialogType) {
	return (
		<>
			<Dialog
				open={open}
				onClose={onClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title' sx={{ textAlign: 'center' }}>
					{title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description' sx={{ textAlign: 'center' }}>
						{message}
					</DialogContentText>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button variant='outlined' sx={{ textTransform: 'capitalize', width: '160px' }} onClick={onClose}>
						{cancelLabel}
					</Button>
					<Button
						variant='contained'
						sx={{ textTransform: 'capitalize', width: '160px' }}
						onClick={onApply}
						autoFocus
					>
						{saveLabel}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default ConfirmDialog;
