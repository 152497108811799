import React, { useState, useEffect } from 'react';
import {
	Grid,
	Box,
	Typography,
	TextField,
	Stack,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Snackbar,
	Chip,
	Menu,
	MenuItem,
	ListItemIcon,
	IconButton,
} from '@mui/material';
import { DataGridPremium, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MenuProps } from '@mui/material/Menu';
import { styled, alpha } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LocalizationProvider as LocalizationProviderPro } from '@mui/x-date-pickers-pro';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {
	TabletMac as TabletMacIcon,
	Person as PersonIcon,
	PhoneAndroid as PhoneAndroidIcon,
	KeyboardArrowDown as KeyboardArrowDownIcon,
	MoreVert as MoreVertIcon,
	Delete as DeleteIcon,
	CheckCircle as CheckCircleIcon,
	Cancel as CancelIcon,
	AccessTime as AccessTimeIcon,
	ModeEdit as ModeEditIcon,
	TableChart as TableChartIcon,
	TableRows as TableRowsIcon,
} from '@mui/icons-material';

import axios from '../../axiosInstance';
import { API } from '../../api';
import Report from './Report';
import ConfirmDialog from '../../Components/ConfirmDialog';
import { generateFlatRows } from '../../lib';
import Excel from './Excel';

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
			},
		},
	},
}));

type monthsListType = {
	id: string;
	name: string;
	hourly_rate: number;
	total_hours: number;
	amount_earned: number;
	days: {};
}[];
type dataReportType = {
	name: string;
	department: string;
	twd_id: string;
	from: string;
	to: string;
	total_hours: number;
	hourly_rate: number;
	amount_earned: number;
	months: monthsListType;
}[];
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
function CustomToolbar() {
	return (
		<GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
			<GridToolbarExport printOptions={{ disableToolbarButton: true }} excelOptions={{ disableToolbarButton: true }} />
		</GridToolbarContainer>
	);
}
function History() {
	//#region status
	let { id } = useParams();
	//const Moment = MomentRange.extendMoment(moment);
	const [anchorElPrint, setAnchorElPrint] = React.useState<null | HTMLElement>(null);
	const openPrint = Boolean(anchorElPrint);
	const [anchorElIconsRequested, setAnchorElIconsRequested] = React.useState<null | HTMLElement>(null);
	const openIconsRequested = Boolean(anchorElIconsRequested);
	const [openApprove, setOpenApprove] = React.useState(false);
	const [OpenDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [OpenCkeckOut, setOpenCkeckOut] = useState(false);
	const [openReject, setOpenReject] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const [OpenExcel, setOpenExcel] = useState(false);
	const [OpenReport, setOpenReport] = useState(false);
	const [OpenMessage, setOpenMessage] = useState(false);
	const [ErrorMessage, setErrorMessage] = useState(null);
	const [validDateMessage, setvalidDateMessage] = useState('');
	const [DisabledManual, setDisabledManual] = useState(true);
	const [IsError, setIsError] = useState(false);
	const [SelectedAttendance, setSelectedAttendance] = useState<{ id: object } | any>({});
	const [RefreshData, setRefreshData] = useState(false);
	const [date, setDate] = React.useState<Date | null>(new Date());
	const [ReportDate, setReportDate] = React.useState<DateRange<Date>>([null, null]);
	const [ReportData, setReportData] = useState<dataReportType>([]);
	const [DiableReport, setDiableReport] = useState(false);
	const [EmployeeInfo, setEmployeeInfo] = useState({
		name: '',
		department: '',
		twd_id: '',
		from: '',
		to: '',
		total_hours: '--',
		hourly_rate: 0,
		amount_earned: 0,
	});
	const [Weeks, setWeeks] = useState<
		{
			id: number;
			name: string;
			days: {}[];
			time: number;
		}[]
	>([]);
	const [Data, setData] = useState({
		day: null,
		checkIn: null,
		checkOut: null,
		reason: undefined,
	});
	const [OpenUpdate, setOpenUpdate] = useState(false);
	const [validUpdateDateMessage, setValidUpdateDateMessage] = useState('');
	const [DisabledUpdateDate, setDisabledUpdateDate] = useState(false);
	const [UpdateDataAttendance, setUpdateDataAttendance] = useState({
		day: null,
		checkIn: null,
		checkOut: null,
		reason: undefined,
	});
	const [ExcelData, setExcelData] = useState<any>([]);
	const [ExcelDate, setExcelDate] = React.useState<DateRange<Date>>([null, null]);
	const [DisableExcel, setDisableExcel] = useState(true);

	const [IsTableView, setIsTableView] = useState(false);
	const [TableData, setTableData] = useState<{}[]>([]);

	const color = {
		Pending: '#a6a832',
		Accepted: '#007F5F',
		Rejected: '#dd0000',
	};
	const types = {
		Tablet: <TabletMacIcon />,
		Admin: <PersonIcon />,
		Mobile: <PhoneAndroidIcon />,
		//Automated: <AutoModeIcon />,
	};
	//#endregion status
	function getHours(num: number) {
		let hours = Math.trunc(num / 60);
		let mins = num % 60;
		return `${hours}h ${mins}m`;
	}
	//#region useEffect
	useEffect(() => {
		axios
			.get(API.attendance.attendance, {
				params: {
					employee: id,
					from: moment(date).startOf('month').toISOString(),
					to: moment(date).endOf('month').toISOString(),
				},
			})
			.then((res) => {
				let data =
					res?.data?.data?.sort((a: any, b: any) => {
						let fa = a?.checkIn?.at,
							fb = b?.checkIn?.at;

						if (fa < fb) {
							return -1;
						}
						if (fa > fb) {
							return 1;
						}
						return 0;
					}) || [];
				//console.log(data);
				let insialValue: {
					id: number;
					name: string;
					time: number;
					days: {}[];
				}[] = [
					{
						id: 0,
						name: 'Week 1',
						time: 0,
						days: [],
					},
					{
						id: 1,
						name: 'Week 2',
						time: 0,
						days: [],
					},
					{
						id: 2,
						name: 'Week 3',
						time: 0,
						days: [],
					},
					{
						id: 3,
						name: 'Week 4',
						time: 0,
						days: [],
					},
					{
						id: 4,
						name: 'Week 5',
						time: 0,
						days: [],
					},
					{
						id: 5,
						name: 'Week 6',
						time: 0,
						days: [],
					},
				];

				let table_data: any = [];
				console.log(insialValue);

				data.forEach((element: any, i: number) => {
					table_data.push({
						id: element?.id,
						date: element?.checkIn?.at ? moment(element?.checkIn?.at).format('dddd DD MMMM YYYY') : '',
						check_in_time: element?.checkIn?.at ? moment(element?.checkIn?.at).format('hh:mm a') : '--:--',
						check_out_time: element?.checkOut?.at ? moment(element?.checkOut?.at).format('hh:mm a') : '--:--',
						status: element.status,
						hrs: element.workingHours ? getHours(element.workingHours) : '--:--',
						reason: element?.reason,
						requestedAt: element.requestedAt ? true : false,
						check_in_type: element?.checkIn ? element?.checkIn?.type : 'Automated',
						check_out_type: element?.checkOut ? element?.checkOut?.type : 'Automated',
						check_in: element?.checkIn?.at ? element?.checkIn?.at : undefined,
						check_out: element?.checkOut?.at ? element?.checkOut?.at : undefined,
					});

					let week_num = moment(element.checkIn.at).week() - moment(element.checkIn.at).startOf('month').week();
					console.log('week_num', week_num);

					insialValue[week_num]?.days?.push({
						id: element?.id,
						date: element?.checkIn?.at ? moment(element?.checkIn?.at).format('ddd DD MMMM YYYY') : '',
						check_in: element?.checkIn?.at ? element?.checkIn?.at : undefined,
						check_out: element?.checkOut?.at ? element?.checkOut?.at : undefined,
						reason: element?.reason,
						check_in_time: element?.checkIn?.at ? moment(element?.checkIn?.at).format('hh:mm a') : '--:--',
						check_out_time: element?.checkOut?.at ? moment(element?.checkOut?.at).format('hh:mm a') : '--:--',
						has_check_out: element?.checkOut?.at ? true : false,
						hrs: element.workingHours ? getHours(element.workingHours) : '--:--',
						hrs_num: element.workingHours ? element.workingHours : 0,
						status: element.status, //'Rejected',
						requestedAt: element.requestedAt ? true : false,
						check_in_type: element?.checkIn ? element?.checkIn?.type : 'Automated',
						check_out_type: element?.checkOut ? element?.checkOut?.type : 'Automated',
					});
					insialValue[week_num].time += element.workingHours ? element.workingHours : 0;
				});
				setTableData([...table_data]);
				setWeeks([...insialValue]);
			});
	}, [date, RefreshData]);
	// Get user info for report
	useEffect(() => {
		axios
			.get(`${API.user.user}/${id}`)
			.then((res) => {
				let data = res?.data?.data;
				setEmployeeInfo((prev) => ({
					...prev,
					name: `${data?.firstName} ${data?.lastName}`,
					department: data?.departments?.map((val: any) => val.name).join(', ') || '',
					twd_id: data?.tmw_id || '',
					hourly_rate: data.rate ? data.rate : 0,
				}));
			})
			.catch((err) => {});
	}, []);
	// Check CheckIN / CheckOut for Add Manual
	useEffect(() => {
		if (Data.checkIn === null || Data.day === null) {
			setDisabledManual(true);
		} else {
			setDisabledManual(false);
		}
	}, [Data]);
	useEffect(() => {
		if (UpdateDataAttendance.checkIn === null) {
			setDisabledUpdateDate(true);
		} else {
			setDisabledUpdateDate(false);
		}
	}, [UpdateDataAttendance]);
	//#endregion useEffect

	//#region Functions
	const handleClosePrint = () => {
		setAnchorElPrint(null);
	};
	const handleCloseIconsRequested = () => {
		setAnchorElIconsRequested(null);
	};
	const renderCard = (day: any) => (
		<Box p={2 / 3} sx={{ bgcolor: '#F1F1F1', borderRadius: '8px', height: '115px', borderLeft: 8 }}>
			<Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1} width={1}>
				<Typography variant='subtitle1' gutterBottom sx={{ width: '90%', whiteSpace: 'nowrap', fontSize: '14px' }}>
					{day?.date}
				</Typography>
				<Box>
					<IconButton
						aria-label='more'
						id={`requested-buttons-${day.id}`}
						aria-controls={openIconsRequested ? `menu-requested-${day.id}` : undefined}
						aria-expanded={openIconsRequested ? 'true' : undefined}
						aria-haspopup='true'
						onClick={(event: React.MouseEvent<HTMLElement>) => {
							setSelectedAttendance(day);
							setData((prev) => ({
								...prev,
								day: day?.check_in,
								checkIn: day?.check_in,
								checkOut: null,
								reason: undefined,
							}));
							setUpdateDataAttendance({
								day: day?.check_in,
								checkIn: day?.check_in,
								checkOut: day?.check_out ? day?.check_out : null,
								reason: day.reason,
							});
							setAnchorElIconsRequested(event.currentTarget);
						}}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						id={`menu-requested-${day.id}`}
						className='menu-actions'
						MenuListProps={{ 'aria-labelledby': `requested-buttons-${day.id}` }}
						anchorEl={anchorElIconsRequested}
						open={openIconsRequested}
						onClose={handleCloseIconsRequested}
						PaperProps={{
							style: { width: '15ch', opacity: SelectedAttendance.id !== day.id ? 0 : 1 },
						}}
					>
						{SelectedAttendance.status === 'Pending' && (
							<MenuItem
								className={SelectedAttendance.status !== 'Pending' ? 'hide-pending' : ''}
								onClick={() => setOpenApprove(true)}
								sx={{ display: SelectedAttendance.status !== 'Pending' ? 'none' : 'flex' }}
							>
								<ListItemIcon>
									<CheckCircleIcon fontSize='small' />
								</ListItemIcon>
								<Typography variant='inherit'>Accept</Typography>
							</MenuItem>
						)}
						{SelectedAttendance.status === 'Pending' && (
							<MenuItem
								className={SelectedAttendance.status !== 'Pending' ? 'hide-pending' : ''}
								onClick={() => setOpenReject(true)}
								sx={{ display: SelectedAttendance.status !== 'Pending' ? 'none' : 'flex' }}
							>
								<ListItemIcon sx={{ display: SelectedAttendance.status !== 'Pending' ? 'none' : 'flex' }}>
									<CancelIcon fontSize='small' />
								</ListItemIcon>
								<Typography
									sx={{ display: SelectedAttendance.status !== 'Pending' ? 'none' : 'flex' }}
									variant='inherit'
								>
									Reject
								</Typography>
							</MenuItem>
						)}
						{SelectedAttendance.has_check_out === false ? (
							<MenuItem
								className={SelectedAttendance.has_check_out !== false ? 'hide-checkout' : ''}
								onClick={() => {
									setOpenCkeckOut(true);
								}}
								sx={{ display: SelectedAttendance.has_check_out !== false ? 'none' : 'flex' }}
							>
								<ListItemIcon sx={{ display: SelectedAttendance.has_check_out !== false ? 'none' : 'flex' }}>
									<AccessTimeIcon fontSize='small' />
								</ListItemIcon>
								<Typography
									sx={{ display: SelectedAttendance.has_check_out !== false ? 'none' : 'flex' }}
									variant='inherit'
								>
									CheckOut
								</Typography>
							</MenuItem>
						) : null}
						<MenuItem
							onClick={() => {
								// setSelectedAttendance(day);
								// setData((prev) => ({
								// 	...prev,
								// 	day: day?.check_in,
								// 	checkIn: day?.check_in,
								// 	checkOut: null,
								// 	reason: undefined,
								// }));
								// setUpdateDataAttendance({
								// 	day: day?.check_in,
								// 	checkIn: day?.check_in,
								// 	checkOut: day?.check_out ? day?.check_out : null,
								// 	reason: day.reason,
								// });
								setOpenUpdate(true);
							}}
						>
							<ListItemIcon>
								<ModeEditIcon fontSize='small' />
							</ListItemIcon>
							<Typography variant='inherit'>Edit</Typography>
						</MenuItem>
						<MenuItem onClick={() => setOpenDeleteDialog(true)}>
							<ListItemIcon>
								<DeleteIcon fontSize='small' />
							</ListItemIcon>
							<Typography variant='inherit'>Delete</Typography>
						</MenuItem>
					</Menu>
				</Box>
			</Stack>

			<Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
				<Typography variant='caption' gutterBottom sx={{ display: 'flex', alignItems: 'center', color: '#007F5F' }}>
					{day.check_in_type !== 'Automated' ? (
						(types as any)[day.check_in_type]
					) : (
						<Box component='img' alt='' src={'/images/check_in.png'} />
					)}
					&nbsp;
					{day?.check_in_time}
				</Typography>
				<Typography variant='caption' gutterBottom sx={{ display: 'flex', alignItems: 'center', color: '#707070' }}>
					{day.check_out_type !== 'Automated' ? (
						(types as any)[day.check_out_type]
					) : (
						<Box component='img' alt='' src={'/images/check_out.png'} />
					)}
					&nbsp; {day?.check_out_time}
				</Typography>{' '}
			</Stack>
			<Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
				<Typography variant='caption' gutterBottom sx={{ display: 'flex', alignItems: 'center', color: '#000000' }}>
					&nbsp;
					<Box sx={{ width: '16px' }} component='img' alt='' src={'/images/check.png'} />
					&nbsp;{day?.hrs}
				</Typography>
				{day.requestedAt && (
					<Chip
						size='small'
						label='Manual Time'
						sx={{ borderRadius: '3px', bgcolor: (color as any)[day.status], lineHeight: '18px', color: '#fff' }}
					/>
				)}
			</Stack>
		</Box>
	);
	const onClickManual = () => {
		setData({
			day: null,
			checkIn: null,
			checkOut: null,
			reason: undefined,
		});
		setOpen(true);
	};
	const handleChange_Date = (newValue: Date | null, name: string) => {
		setvalidDateMessage('');

		// let hour = moment(newValue).hours();
		// let min = moment(newValue).minutes();

		// newValue = moment(Data.day || newValue)
		// 	.startOf('day')
		// 	.add(hour, 'hours')
		// 	.add(min, 'minutes')
		// 	.toDate();

		if (!moment(newValue).isSameOrBefore(new Date())) {
			setvalidDateMessage('The value must be less than or equal to the current time.');
		}
		if (name === 'checkOut') {
			if (moment(newValue) <= moment(Data.checkIn)) {
				setvalidDateMessage('CheckOut should be greater than checKIn');
			}
			let duration = moment.duration(moment(newValue).diff(Data.checkIn));
			let hours = duration.asHours();
			if (hours > 24) {
				setvalidDateMessage('The difference between checkIn and checkOut must be less than or equal to 24 hours');
			}
			//console.log('hours', hours);
		}
		if (name === 'checkIn') {
			if (moment(newValue) > moment(Data.checkOut)) {
				setvalidDateMessage('CheckIn should be less than checKOut');
			}
			let duration = moment.duration(moment(Data.checkOut).diff(newValue));
			let hours = duration.asHours();
			if (hours > 24) {
				setvalidDateMessage('The difference between check in and check out must be less than or equal to 24 hours');
			}
			//console.log('hours', hours);
		}
		setData((prev) => ({
			...prev,
			[name]: newValue,
		}));
	};
	const addManual = () => {
		// let year = moment(Data.day).set({ hour: 0, minute: 0, second: 0 }).years();
		// let month = moment(Data.day).set({ hour: 0, minute: 0, second: 0 }).month(); //+ 1;
		// let day = moment(Data.day).set({ hour: 0, minute: 0, second: 0 }).date();

		// function getCheckDate(date: Date | null) {
		// 	let hour = moment(date).hours();
		// 	let min = moment(date).minutes();
		// 	let _date = moment(date)
		// 		.set({ year: year, month: month, date: day, hour: hour, minute: min, second: 0 })
		// 		.toISOString();
		// 	return _date;
		// }
		let requestBody: {
			employee: string | undefined;
			checkIn: {
				at: string | undefined;
			};
			checkOut?: {
				at: string | undefined;
			};
			reason: undefined;
		} = {
			employee: id,
			checkIn: {
				at: moment(Data.checkIn).toISOString(),
				// "gate": "000000040000000000000003" // optional .. for admin
			},
			checkOut: {
				// optional
				at: Data.checkOut ? moment(Data.checkOut).toISOString() : undefined,
				// "gate": "000000040000000000000003" // optional .. for admin
			},
			reason: Data.reason ? Data.reason : undefined,
		};
		if (Data.checkOut === null) {
			requestBody = {
				employee: id,
				checkIn: {
					at: moment(Data.checkIn).toISOString(),
					// "gate": "000000040000000000000003" // optional .. for admin
				},
				reason: Data.reason ? Data.reason : undefined,
			};
		}
		axios
			.post(API.attendance.attendance, requestBody)
			.then((res) => {
				setIsError(false);
				setRefreshData(!RefreshData);
				setErrorMessage(null);
				setOpenMessage(true);
			})
			.catch((err) => {
				setErrorMessage(err.response.data.msg);
				setIsError(true);
				setOpenMessage(true);
			});
		setOpen(false);
	};
	const handleChange_Date_Report = (newValue: DateRange<Date>) => {
		setDiableReport(true);

		const from = moment(newValue[0]).startOf('day');
		const to = moment(newValue[1]).endOf('day');
		setReportDate(newValue);
		axios
			.get(API.attendance.attendance, {
				params: {
					employee: id,
					from: moment(newValue[0]).toISOString(),
					to: moment(newValue[1]).endOf('day').toISOString(),
					status: 'Accepted',
					limit: 100000,
				},
			})
			.then((res) => {
				let result = generateFlatRows(from, to, res?.data?.data, 5 * 60 + 30, -30);
				let total_hours = 0;
				let groupedResult = result.reduce((acc, cur) => {
					let index = acc.findIndex((val: any) => val.date === cur.date.month() + 1);
					if (index === -1) {
						acc.push({
							date: cur.date.month() + 1,
							data: [
								{ workingHours: 0, data: [] },
								{ workingHours: 0, data: [] },
							],
							workingHours: 0,
						});
						index = acc.length - 1;
					}

					const semiIndex = cur.date.date() <= 15 ? 0 : 1;
					acc[index].data[semiIndex].data.push(cur);

					const addedHours = (cur.workingHours || 0) + (cur.lunchBreak || 0);
					acc[index].data[semiIndex].workingHours += addedHours;
					acc[index].workingHours += addedHours;

					total_hours += addedHours;
					return acc;
				}, []);

				setReportData([
					{
						name: EmployeeInfo?.name || '',
						department: EmployeeInfo?.department || '',
						twd_id: EmployeeInfo?.twd_id || '',
						from: moment(newValue[0]).format('DD/MM/YYYY'),
						to: moment(newValue[1]).format('DD/MM/YYYY'),
						total_hours: total_hours ? total_hours : 0,
						hourly_rate: EmployeeInfo.hourly_rate,
						amount_earned: total_hours ? EmployeeInfo.hourly_rate * (total_hours / 60) : 0,
						months: [...groupedResult],
					},
				]);
				setTimeout(() => {
					setDiableReport(false);
				}, 300);
			})
			.catch((err) => {
				setDiableReport(false);
			});
	};
	const handleChange_Update_Date = (newValue: Date | null, name: string) => {
		setValidUpdateDateMessage('');
		// let year = moment(UpdateDataAttendance.day).set({ hour: 0, minute: 0, second: 0 }).years();
		// let month = moment(UpdateDataAttendance.day).set({ hour: 0, minute: 0, second: 0 }).month(); //+ 1;
		// let day = moment(UpdateDataAttendance.day).set({ hour: 0, minute: 0, second: 0 }).date();

		// function getCheckDate(date: Date | null) {
		// 	let hour = moment(date).hours();
		// 	let min = moment(date).minutes();
		// 	let _date = moment(Data.day).startOf('day').add(hour, 'hours').add(min, 'minutes').toISOString();
		// 	return _date;
		// }

		// if (UpdateDataAttendance.checkOut) {
		// 	if (moment(getCheckDate(newValue)) < moment(UpdateDataAttendance.checkIn)) {
		// 		setValidUpdateDateMessage('CheckOut should be greater than checKIn');
		// 	}

		// 	if (name === 'checkIn' && moment(getCheckDate(newValue)) > moment(UpdateDataAttendance.checkOut)) {
		// 		setValidUpdateDateMessage('CheckIn should be less than checKOut');
		// 	}
		// }
		if (!moment(newValue).isSameOrBefore(new Date())) {
			setValidUpdateDateMessage('The value must be less than or equal to the current time.');
		}
		if (name === 'checkOut') {
			if (moment(newValue) <= moment(UpdateDataAttendance.checkIn)) {
				setValidUpdateDateMessage('CheckOut should be greater than checKIn');
			}
			let duration = moment.duration(moment(newValue).diff(UpdateDataAttendance.checkIn));
			let hours = duration.asHours();
			if (hours > 24) {
				setValidUpdateDateMessage(
					'The difference between checkIn and checkOut must be less than or equal to 24 hours'
				);
			}
			//console.log('hours', hours);
		}
		if (name === 'checkIn') {
			if (moment(newValue) > moment(UpdateDataAttendance.checkOut)) {
				setValidUpdateDateMessage('CheckIn should be less than checKOut');
			}
			let duration = moment.duration(moment(UpdateDataAttendance.checkOut).diff(newValue));
			let hours = duration.asHours();
			if (hours > 24) {
				setValidUpdateDateMessage(
					'The difference between check in and check out must be less than or equal to 24 hours'
				);
			}
			//console.log('hours', hours);
		}
		setUpdateDataAttendance((prev) => ({
			...prev,
			[name]: newValue,
		}));
	};
	const handleChange_Date_Excel = (newValue: DateRange<Date>) => {
		setDisableExcel(true);
		setExcelDate(newValue);
		const from = moment(newValue[0]).startOf('day');
		const to = moment(newValue[1]).endOf('day');
		axios
			.get(API.attendance.attendance, {
				params: {
					employee: id,
					from: moment(newValue[0]).toISOString(),
					to: moment(newValue[1]).endOf('day').toISOString(),
					status: 'Accepted',
					limit: 100000,
				},
			})
			.then((res) => {
				let result = generateFlatRows(from, to, res?.data?.data, 5 * 60 + 30, -30);
				let total_hours = 0;
				let groupedResult = result.reduce((acc, cur) => {
					let index = acc.findIndex((val: any) => val.date === cur.date.month() + 1);
					if (index === -1) {
						acc.push({
							date: cur.date.month() + 1,
							data: [
								{ workingHours: 0, data: [] },
								{ workingHours: 0, data: [] },
							],
							workingHours: 0,
						});
						index = acc.length - 1;
					}

					const semiIndex = cur.date.date() <= 15 ? 0 : 1;
					acc[index].data[semiIndex].data.push(cur);

					const addedHours = (cur.workingHours || 0) + (cur.lunchBreak || 0);
					acc[index].data[semiIndex].workingHours += addedHours;
					acc[index].workingHours += addedHours;

					total_hours += addedHours;
					return acc;
				}, []);
				//console.log('groupedResult', groupedResult);
				let emptyData = [
					{},
					{},
					{
						name: 'Date',
						department: 'Check In',
						twd_id: 'Check Out',
						from: 'Lunch Break',
						to: 'Total Hours',
						total_hours: '',
						hourly_rate: '',
						amount_earned: '',
						date: null,
						checkIn: null,
						checkOut: null,
						lunchBreak: null,
						workingHours: null,
					},
					{},
				];
				let _groupedResult: any = [];

				groupedResult.forEach((element: any) => {
					element.data.forEach((data: any) => {
						let newData: any = data.data.map((day: any) => ({
							name: `${day?.dayString || '--'}, ${day?.dayDate || ''}`,
							department: `${day?.checkIn || '--:--'}`,
							twd_id: `${day?.checkOut || '--:--'}`,
							from: `${day?.lunchBreak ? day?.lunchBreak + 'm' : '--'}`,
							to: `${getHours((day?.workingHours || 0) + (day?.lunchBreak || 0))}`,
						}));
						if (data.data.length > 0) {
							_groupedResult = [
								..._groupedResult,
								...emptyData,
								...newData,
								{},
								{ name: 'Semi Monthly Total Hours:', department: getHours(data.workingHours) },
							];
						}
					});
					_groupedResult.push({
						name: 'Month Total Hours:',
						department: getHours(element.workingHours),
					});
				});
				//console.log('_groupedResult', _groupedResult);
				setExcelData([
					[
						{
							name: EmployeeInfo?.name || '',
							department: EmployeeInfo?.department || '',
							twd_id: EmployeeInfo?.twd_id || '',
							from: moment(newValue[0]).toLocaleString(),
							to: moment(newValue[1]).toLocaleString(),
							total_hours: total_hours ? getHours(total_hours) : getHours(0),
							hourly_rate: EmployeeInfo.hourly_rate,
							amount_earned: total_hours ? EmployeeInfo.hourly_rate * (total_hours / 60) : 0,
							date: null,
							checkIn: null,
							checkOut: null,
							lunchBreak: null,
							workingHours: null,
						},
						..._groupedResult,
					],
				]);
				setTimeout(() => {
					setDisableExcel(false);
				}, 200);
			})
			.catch((err) => {
				setDiableReport(false);
			});
	};
	const onAccept = () => {
		axios
			.post(`${API.attendance.attendance}/${SelectedAttendance?.id}/accept`)
			.then((res) => {
				setIsError(false);
				setOpenApprove(false);
				setOpenMessage(true);
				setRefreshData(!RefreshData);
			})
			.catch((err) => {
				setIsError(true);
				setOpenApprove(false);
				setOpenMessage(true);
			});
		handleCloseIconsRequested();
		setSelectedAttendance({});
	};
	const onReject = () => {
		axios
			.post(`${API.attendance.attendance}/${SelectedAttendance?.id}/reject`)
			.then((res) => {
				setIsError(false);
				setOpenReject(false);
				setOpenMessage(true);
				setRefreshData(!RefreshData);
			})
			.catch((err) => {
				setIsError(true);
				setOpenReject(false);
				setOpenMessage(true);
			});
		handleCloseIconsRequested();
		setSelectedAttendance({});
	};
	const addCkeckOut = () => {
		// let year = moment(Data.day).set({ hour: 0, minute: 0, second: 0 }).years();
		// let month = moment(Data.day).set({ hour: 0, minute: 0, second: 0 }).month(); //+ 1;
		// let day = moment(Data.day).set({ hour: 0, minute: 0, second: 0 }).date();

		// function getCheckDate(date: Date | null) {
		// 	let hour = moment(date).hours();
		// 	let min = moment(date).minutes();
		// 	let _date = moment(Data.day).startOf('day').add(hour, 'hours').add(min, 'minutes').toISOString();
		// 	return _date;
		// }
		let requestBody = {
			at: Data.checkOut ? Data.checkOut : undefined, // Data.checkOut ? getCheckDate(Data.checkOut) : undefined,
			reason: Data.reason ? Data.reason : undefined,
		};
		console.log('requestBody', requestBody);
		axios
			.post(`/attendance/${SelectedAttendance?.id}/check-out`, requestBody)
			.then((res) => {
				setIsError(false);
				setRefreshData(!RefreshData);
				setErrorMessage(null);
				setOpenMessage(true);
			})
			.catch((err) => {
				setErrorMessage(err.response.data.msg);
				setIsError(true);
				setOpenMessage(true);
			});
		setOpenCkeckOut(false);
		setvalidDateMessage('');
		handleCloseIconsRequested();
		setSelectedAttendance({});
	};
	const updateManual = () => {
		let requestBody: {
			checkIn: {
				at: any;
			};
			checkOut?: {
				at: any;
			};
			reason: any;
		} = {
			checkIn: {
				at: UpdateDataAttendance.checkIn,
			},
			reason: UpdateDataAttendance.reason ? UpdateDataAttendance.reason : undefined,
		};
		if (UpdateDataAttendance.checkOut) {
			requestBody = {
				checkIn: {
					at: UpdateDataAttendance.checkIn,
				},
				checkOut: {
					// optional
					at: UpdateDataAttendance.checkOut ? UpdateDataAttendance.checkOut : undefined,
				},
				reason: UpdateDataAttendance.reason ? UpdateDataAttendance.reason : undefined,
			};
		}
		axios
			.put(`${API.attendance.attendance}/${SelectedAttendance?.id}`, requestBody)
			.then((res) => {
				setIsError(false);
				setRefreshData(!RefreshData);
				setOpenMessage(true);
			})
			.catch((err) => {
				setIsError(true);
				setOpenMessage(true);
			});
		setOpenUpdate(false);
		handleCloseIconsRequested();
		setSelectedAttendance({});
		setValidUpdateDateMessage('');
		setvalidDateMessage('');
	};
	const onDelete = () => {
		axios
			.delete(`${API.attendance.attendance}/${SelectedAttendance?.id}`)
			.then((res) => {
				setOpenDeleteDialog(false);
				setIsError(false);
				setOpenMessage(true);
				setRefreshData(!RefreshData);
			})
			.catch((err) => {
				setOpenDeleteDialog(false);
				setIsError(true);
				setOpenMessage(true);
			});
		handleCloseIconsRequested();
		setSelectedAttendance({});
	};
	//#endregion functions

	const columns: GridColDef[] = [
		{
			field: 'date',
			headerName: 'Date',
			align: 'center',
			headerAlign: 'center',
			flex: 2,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'check_in_time',
			headerName: 'Check in',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
			renderCell: (params: any) => {
				return (
					<>
						<Typography
							variant='caption'
							gutterBottom
							sx={{ display: 'flex', alignItems: 'center', color: '#007F5F' }}
						>
							{params?.row?.check_in_type !== 'Automated' ? (
								(types as any)[params?.row?.check_in_type]
							) : (
								<Box component='img' alt='' src={'/images/check_in.png'} />
							)}
							&nbsp;
							{params?.row?.check_in_time}
						</Typography>
					</>
				);
			},
		},
		{
			field: 'check_out_time',
			headerName: 'Check out',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
			renderCell: (params: any) => {
				return (
					<>
						<Typography
							variant='caption'
							gutterBottom
							sx={{ display: 'flex', alignItems: 'center', color: '#007F5F' }}
						>
							{params?.row?.check_out_type !== 'Automated' ? (
								(types as any)[params?.row?.check_out_type]
							) : (
								<Box component='img' alt='' src={'/images/check_out.png'} />
							)}
							&nbsp;
							{params?.row?.check_out_time}
						</Typography>
					</>
				);
			},
		},
		{
			field: 'hrs',
			headerName: 'Working  Hr’s',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'requestedAt',
			headerName: 'Manual Time',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
			renderCell: (params: any) => {
				return (
					<>
						{params?.row?.requestedAt && (
							<Chip
								size='small'
								label='Manual Time'
								sx={{
									borderRadius: '3px',
									bgcolor: (color as any)[params?.row?.status],
									lineHeight: '18px',
									color: '#fff',
								}}
							/>
						)}
					</>
				);
			},
		},
		{
			field: 'reason',
			headerName: 'Reason',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'action',
			headerName: 'Actions',
			align: 'center',
			headerAlign: 'center',
			flex: 2,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			disableExport: true,
			headerClassName: 'table-header right-corner',
			renderCell: (params: any) => {
				//console.log(params);
				return (
					<Box>
						{params?.row?.status === 'Pending' && (
							<CheckCircleIcon
								sx={{ color: '#007F5F', cursor: 'pointer' }}
								onClick={() => {
									if (params.field === 'action') {
										setOpenApprove(true);
										//setOpenInfoDialog(false);
									}
								}}
							/>
						)}
						{params?.row?.status === 'Pending' && (
							<CancelIcon
								sx={{ color: '#FE0000', cursor: 'pointer' }}
								onClick={() => {
									if (params.field === 'action') setOpenReject(true);
								}}
							/>
						)}
						<ModeEditIcon
							sx={{ color: 'grey', cursor: 'pointer' }}
							onClick={() => {
								if (params.field === 'action') setOpenUpdate(true);
							}}
						/>
						<DeleteIcon sx={{ cursor: 'pointer' }} onClick={() => setOpenDeleteDialog(true)} />
					</Box>
				);
			},
		},
	];
	return (
		<>
			<Box p={3} height={1}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box width={1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<Typography variant='h5' gutterBottom fontWeight={'600'}>
								History
							</Typography>
							<Box width={1} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
								<Box>
									<Button
										id='demo-customized-button-options'
										aria-controls={openPrint ? 'demo-customized-menu-options' : undefined}
										aria-haspopup='true'
										aria-expanded={openPrint ? 'true' : undefined}
										variant='contained'
										disableElevation
										onClick={(event: React.MouseEvent<HTMLElement>) => {
											setAnchorElPrint(event.currentTarget);
										}}
										sx={{ textTransform: 'capitalize' }}
										endIcon={<KeyboardArrowDownIcon />}
									>
										Report
									</Button>
									<StyledMenu
										id='demo-customized-menu-options'
										MenuListProps={{
											'aria-labelledby': 'demo-customized-button-options',
										}}
										anchorEl={anchorElPrint}
										open={openPrint}
										onClose={handleClosePrint}
									>
										<MenuItem onClick={() => setOpenReport(true)} disableRipple>
											{/* <PictureAsPdfIcon /> */}
											PDF
										</MenuItem>
										<MenuItem onClick={() => setOpenExcel(true)} disableRipple>
											{/* <PictureAsPdfIcon /> */}
											Excel
										</MenuItem>
									</StyledMenu>
								</Box>
								&nbsp; &nbsp;
								<Button
									onClick={onClickManual}
									sx={{ textTransform: 'capitalize', marginRight: '20px' }}
									variant='contained'
								>
									Add Manual
								</Button>
								<IconButton
									color={IsTableView ? 'secondary' : 'primary'}
									aria-label='upload picture'
									component='label'
									onClick={() => setIsTableView(false)}
								>
									<TableChartIcon sx={{ fontSize: '35px' }} />
								</IconButton>
								<IconButton
									color={!IsTableView ? 'secondary' : 'primary'}
									aria-label='upload picture'
									component='label'
									onClick={() => setIsTableView(true)}
								>
									<TableRowsIcon sx={{ fontSize: '35px' }} />
								</IconButton>
							</Box>
						</Box>
					</Grid>

					{/** Cards View */}
					{!IsTableView && (
						<>
							<Grid item xs={12}>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										bgcolor: '#F2F2F2',
										borderRadius: '7px',
									}}
								>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DatePicker
											views={['year', 'month']}
											label=''
											value={date}
											onChange={(newValue) => {
												setDate(newValue);
											}}
											renderInput={(params) => <TextField {...params} helperText={null} />}
										/>
									</LocalizationProvider>
								</Box>
							</Grid>

							<Box width={1} sx={{ flexWrap: 'nowrap', display: 'flex' }}>
								{Weeks.map((val, i) => (
									<Grid key={i} item xs={3} m={1} width='15%' minWidth={'15%'}>
										<Grid container item xs={12} spacing={1}>
											<Grid item xs={12}>
												<Stack
													sx={{
														bgcolor: '#707070',
														borderRadius: '8px',
														height: '40px',
														color: 'white',
													}}
													direction='row'
													justifyContent='space-between'
													alignItems='center'
													spacing={1}
													p={1}
												>
													<Typography variant='subtitle1' gutterBottom>
														{val?.name}
													</Typography>
													<Typography variant='subtitle2' gutterBottom>
														{getHours(val?.time)}
													</Typography>
												</Stack>
											</Grid>
											{val?.days.map((day, j) => (
												<Grid key={j} item xs={12}>
													{renderCard(day)}
												</Grid>
											))}
										</Grid>
									</Grid>
								))}
							</Box>
						</>
					)}
				</Grid>
				{/** Table View */}
				{IsTableView && (
					<Box
						height={'95%'}
						sx={{
							width: 1,
							'& .table-header': {
								color: '#707070',
							},
							'& .css-f3jnds-MuiDataGrid-columnHeaders': {
								borderTopRightRadius: '15px !important',
								borderTopLeftRadius: '15px !important',
								background: '#dadada !important',
							},
						}}
					>
						<DataGridPremium
							className='custom-grid-class'
							columns={columns}
							rows={TableData || []}
							//apiRef={apiRef}
							disableSelectionOnClick
							disableColumnResize
							disableColumnReorder
							disableChildrenSorting
							components={{
								Toolbar: CustomToolbar,
							}}
							onRowClick={(rowData: any) => {
								setSelectedAttendance(rowData?.row);
								setData((prev) => ({
									...prev,
									day: rowData?.row?.check_in,
									checkIn: rowData?.row?.check_in,
									checkOut: null,
									reason: undefined,
								}));
								setUpdateDataAttendance({
									day: rowData?.row?.check_in,
									checkIn: rowData?.row?.check_in,
									checkOut: rowData?.row?.check_out ? rowData?.row?.check_out : null,
									reason: rowData?.row.reason,
								});
							}}
						/>
					</Box>
				)}
			</Box>

			{/** Add Manual */}
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogContent>
					<Grid container spacing={2} height={'100%'}>
						<Grid item xs={6}>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Check in Time
							</Typography>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DateTimePicker
									label=''
									value={Data.checkIn}
									onChange={(newValue: Date | null) => handleChange_Date(newValue, 'checkIn')}
									renderInput={(params: any) => {
										params.inputProps.placeholder = 'mm/dd/yyyy hh:mm';
										return <TextField className='input-style' {...params} />;
									}}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={6}>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Check Out Time
							</Typography>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DateTimePicker
									label=''
									value={Data.checkOut}
									onChange={(newValue: Date | null) => handleChange_Date(newValue, 'checkOut')}
									renderInput={(params: any) => {
										params.inputProps.placeholder = 'mm/dd/yyyy hh:mm';
										return <TextField className='input-style' {...params} />;
									}}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='caption' style={{ color: '#FE0000' }} gutterBottom component='div'>
								{validDateMessage}
							</Typography>{' '}
						</Grid>
						<Grid item xs={12}>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Reason
							</Typography>
							<TextField
								name='reason'
								value={Data.reason}
								onChange={(e) => setData((prev: any) => ({ ...prev, reason: e.target.value }))}
								style={{ width: '100%' }}
								InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button
						variant='outlined'
						sx={{ textTransform: 'capitalize', width: '160px' }}
						onClick={() => setOpen(false)}
					>
						Cancel
					</Button>
					<Button
						variant='contained'
						sx={{ textTransform: 'capitalize', width: '160px' }}
						onClick={addManual}
						autoFocus
						disabled={validDateMessage.length > 0 || Data.checkIn === null}
					>
						Add
					</Button>
				</DialogActions>
			</Dialog>

			{/** Open Report to choose date */}
			<Dialog
				open={OpenReport}
				onClose={() => setOpenReport(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogContent>
					<Typography variant='subtitle1' gutterBottom component='div'>
						Select Month:
					</Typography>
					<LocalizationProviderPro dateAdapter={AdapterDateFns} localeText={{ start: 'From', end: 'To' }}>
						<DateRangePicker
							value={ReportDate}
							onChange={(newValue: DateRange<Date>) => handleChange_Date_Report(newValue)}
							renderInput={(startProps, endProps) => (
								<React.Fragment>
									<TextField className='input-style' {...startProps} />
									<Box sx={{ mx: 2 }}> to </Box>
									<TextField className='input-style' {...endProps} />
								</React.Fragment>
							)}
						/>
					</LocalizationProviderPro>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button
						variant='outlined'
						sx={{ textTransform: 'capitalize', width: '160px' }}
						onClick={() => {
							handleClosePrint();
							setOpenReport(false);
							setReportDate([null, null]);
							setEmployeeInfo((prev) => ({
								...prev,
								from: '',
								to: '',
								total_hours: '--',
								hourly_rate: 0,
								amount_earned: 0,
							}));
						}}
					>
						Cancel
					</Button>
					<Report ReportData={ReportData} DiableReport={DiableReport} setDiableReport={setDiableReport} />
				</DialogActions>
			</Dialog>

			{/** Excel  */}
			<Dialog
				open={OpenExcel}
				onClose={() => setOpenExcel(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogContent>
					<Typography variant='subtitle1' gutterBottom component='div'>
						Select Month:
					</Typography>
					<LocalizationProviderPro dateAdapter={AdapterDateFns} localeText={{ start: 'From', end: 'To' }}>
						<DateRangePicker
							value={ExcelDate}
							onChange={(newValue: DateRange<Date>) => handleChange_Date_Excel(newValue)}
							renderInput={(startProps, endProps) => (
								<React.Fragment>
									<TextField className='input-style' {...startProps} />
									<Box sx={{ mx: 2 }}> to </Box>
									<TextField className='input-style' {...endProps} />
								</React.Fragment>
							)}
						/>
					</LocalizationProviderPro>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button
						variant='outlined'
						sx={{ textTransform: 'capitalize', margin: '5px' }}
						onClick={() => {
							handleClosePrint();
							setOpenExcel(false);
							setExcelDate([null, null]);
							setEmployeeInfo((prev) => ({
								...prev,
								from: '',
								to: '',
								total_hours: '--',
								hourly_rate: 0,
								amount_earned: 0,
							}));
						}}
					>
						Cancel
					</Button>
					<Excel ExcelData={ExcelData} DisableExcel={DisableExcel} />
				</DialogActions>
			</Dialog>

			{/** Approve */}
			<ConfirmDialog
				open={openApprove}
				onClose={() => {
					setOpenApprove(false);
					handleCloseIconsRequested();
				}}
				title='Approve Vacation'
				message={`Are you sure you want to approve this request from ${EmployeeInfo?.name}`}
				cancelLabel='Close'
				saveLabel='Approve'
				onApply={onAccept}
			/>
			{/** Reject */}
			<ConfirmDialog
				open={openReject}
				onClose={() => {
					setOpenReject(false);
					handleCloseIconsRequested();
				}}
				title='Reject Vacation'
				message={`Are you sure you want to reject this request from ${EmployeeInfo?.name}`}
				cancelLabel='Close'
				saveLabel='Reject'
				onApply={onReject}
			/>
			{/** Delete */}
			<ConfirmDialog
				open={OpenDeleteDialog}
				onClose={() => {
					setOpenDeleteDialog(false);
					handleCloseIconsRequested();
				}}
				title=''
				message='Are you sure you want to delete this request?'
				cancelLabel='Close'
				saveLabel='Delete'
				onApply={onDelete}
			/>
			{/** Check out */}
			<Dialog
				open={OpenCkeckOut}
				onClose={() => setOpenCkeckOut(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogContent>
					<Grid container spacing={2} height={'100%'}>
						<Grid item xs={12}>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Check Out Time
							</Typography>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DateTimePicker
									label=''
									value={Data.checkOut}
									onChange={(newValue: Date | null) => handleChange_Date(newValue, 'checkOut')}
									renderInput={(params: any) => {
										params.inputProps.placeholder = 'mm/dd/yyyy hh:mm';
										return <TextField fullWidth className='input-style' {...params} />;
									}}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='caption' style={{ color: '#FE0000' }} gutterBottom component='div'>
								{validDateMessage}
							</Typography>{' '}
						</Grid>
						<Grid item xs={12}>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Reason
							</Typography>
							<TextField
								name='reason'
								value={Data.reason}
								onChange={(e) => setData((prev: any) => ({ ...prev, reason: e.target.value }))}
								style={{ width: '100%' }}
								InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button
						variant='outlined'
						sx={{ textTransform: 'capitalize', width: '160px' }}
						onClick={() => {
							setvalidDateMessage('');
							setOpenCkeckOut(false);
							handleCloseIconsRequested();
						}}
					>
						Cancel
					</Button>
					<Button
						variant='contained'
						sx={{ textTransform: 'capitalize', width: '160px' }}
						onClick={addCkeckOut}
						autoFocus
						disabled={validDateMessage.length > 0 || DisabledManual}
					>
						Add
					</Button>
				</DialogActions>
			</Dialog>

			{/** Update Attendance  */}
			<Dialog
				open={OpenUpdate}
				onClose={() => setOpenUpdate(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogContent>
					<Grid container spacing={2} height={'100%'}>
						<Grid item xs={6}>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Check in Time
							</Typography>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DateTimePicker
									label=''
									value={UpdateDataAttendance.checkIn}
									onChange={(newValue: Date | null) => handleChange_Update_Date(newValue, 'checkIn')}
									renderInput={(params: any) => {
										params.inputProps.placeholder = 'mm/dd/yyyy hh:mm';
										return <TextField className='input-style' {...params} />;
									}}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={6}>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Check Out Time
							</Typography>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DateTimePicker
									label=''
									value={UpdateDataAttendance.checkOut}
									disabled={UpdateDataAttendance.checkOut ? false : true}
									onChange={(newValue: Date | null) => handleChange_Update_Date(newValue, 'checkOut')}
									renderInput={(params: any) => {
										params.inputProps.placeholder = 'mm/dd/yyyy hh:mm';
										return <TextField className='input-style' {...params} />;
									}}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='caption' style={{ color: '#FE0000' }} gutterBottom component='div'>
								{validUpdateDateMessage}
							</Typography>{' '}
						</Grid>
						<Grid item xs={12}>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Reason
							</Typography>
							<TextField
								name='reason'
								value={UpdateDataAttendance.reason}
								onChange={(e) => setUpdateDataAttendance((prev: any) => ({ ...prev, reason: e.target.value }))}
								style={{ width: '100%' }}
								InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button
						variant='outlined'
						sx={{ textTransform: 'capitalize', width: '160px' }}
						onClick={() => {
							handleCloseIconsRequested();
							setSelectedAttendance({});
							setValidUpdateDateMessage('');
							setUpdateDataAttendance({ day: null, checkIn: null, checkOut: null, reason: undefined });
							setOpenUpdate(false);
						}}
					>
						Cancel
					</Button>
					<Button
						variant='contained'
						sx={{ textTransform: 'capitalize', width: '160px' }}
						onClick={updateManual}
						autoFocus
						disabled={validUpdateDateMessage.length > 0 || DisabledUpdateDate}
					>
						Update
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar open={OpenMessage} autoHideDuration={6000} onClose={() => setOpenMessage(false)}>
				{IsError ? (
					<Alert onClose={() => setOpenMessage(false)} severity='error' sx={{ width: '100%' }}>
						{ErrorMessage !== null ? `${ErrorMessage}` : 'Request failed!'}
					</Alert>
				) : (
					<Alert onClose={() => setOpenMessage(false)} severity='success' sx={{ width: '100%' }}>
						Request succeeded.
					</Alert>
				)}
			</Snackbar>
		</>
	);
}

export default History;
