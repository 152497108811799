import { useState, useEffect } from 'react';
import { Grid, Box, Typography, Button, Dialog, IconButton } from '@mui/material';
import QRCode, { QRCodeSVG } from 'qrcode.react';
import {
	Refresh as RefreshIcon,
	FileDownload as FileDownloadIcon,
	ChangeCircle as ChangeCircleIcon,
} from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { io } from 'socket.io-client';

import axios from '../../axiosInstance';
import { API } from '../../api';
import MapComp from './Map';

type DataType = {
	name: string;
	qr_code: string;
	location: string;
	permanentToken: string;
	polygon: any;
	circle:
		| {
				longitude: number;
				latitude: number;
				radius: number; //  in meters
		  }
		| undefined;
};
function Profile() {
	let { id } = useParams();
	const [Data, setData] = useState<DataType>();
	const [RefreshData, setRefreshData] = useState(false);
	const [OpenDialog, setOpenDialog] = useState(false);
	const [MapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
	const [MapZoom, setMapZoom] = useState(1.5);

	useEffect(() => {
		axios
			.get(`${API.gate.gate}/${id}`)
			.then((res) => {
				let Data = {
					name: res?.data?.data?.name,
					qr_code: res?.data?.data?.qrCode,
					permanentToken: res?.data?.data?.permanentToken,
					location: res?.data?.data?.address?.location,
					polygon: res?.data?.data?.polygon
						? res?.data?.data?.polygon.map((val: any) => ({
								lng: val[0],
								lat: val[1],
						  }))
						: [],
					circle: res?.data?.data?.circle ? res?.data?.data?.circle : undefined,
				};
				if (res?.data?.data?.address?.latitude && res?.data?.data?.address?.longitude) {
					setMapZoom(5);
				}
				setMapCenter({
					lat: res?.data?.data?.address?.latitude ? res?.data?.data?.address?.latitude : 0,
					lng: res?.data?.data?.address?.longitude ? res?.data?.data?.address?.longitude : 0,
				});
				setData(Data);
			})
			.catch((err) => {});
	}, [id, RefreshData]);
	//console.log('CheckInOutToken', CheckInOutToken);
	const onRefreshAllQrCode = () => {
		axios
			.post(`${API.gate.gate}/${id}/logout`)
			.then((res) => {
				setRefreshData(!RefreshData);
			})
			.catch((err) => {});
	};
	const downloadQR = () => {
		const canvas = document.getElementById('qr_code_id') as HTMLCanvasElement;
		//let ctx = (canvas as any)[0]?.getContext('2d');
		//canvas.style.padding = '20px';

		// $croppedCanvas[0].getContext("2d").drawImage(canvas,
		// 	cropLeft, cropTop, cropWidth, cropHeight,
		// 	0, 0, imgWidth, imgHeight);
		//(canvas as any)[0]?.getContext('2d').drawImage(canvas, 15, 15);
		const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
		//(pngUrl as any)['style'] = { padding: '2rem' };

		//ctx.drawImage(pngUrl, 10, 10);

		let downloadLink = document.createElement('a');
		downloadLink.href = pngUrl;
		downloadLink.download = '123456.png';
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};
	const onRefreshPermanentToken = () => {
		axios
			.post(`${API.gate.gate}/${id}/permanent-token/refresh`)
			.then((res) => {
				setRefreshData(!RefreshData);
			})
			.catch((err) => {});
	};
	return (
		<>
			<Box height={'100%'} p={3}>
				<Grid container spacing={2}>
					<Grid item xs={8} pb={5}>
						<Grid container item spacing={3}>
							<Grid item xs={6}>
								<Typography variant='subtitle2' gutterBottom>
									<Typography variant='caption' sx={{ color: '#707070' }} component='div'>
										Name
									</Typography>
									{Data?.name}
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<IconButton
									onClick={onRefreshAllQrCode}
									color='primary'
									aria-label='upload picture'
									component='span'
								>
									<RefreshIcon />
								</IconButton>
								<Typography variant='caption' sx={{ color: '#707070' }}>
									Logout
								</Typography>
								<Typography variant='subtitle2' gutterBottom ml={2}>
									<QRCode
										size={70}
										value={`${Data?.qr_code}`}
										onClick={() => setOpenDialog(true)}
										style={{ cursor: 'pointer' }}
									/>
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant='subtitle2' gutterBottom>
									<Typography variant='caption' sx={{ color: '#707070' }} component='div'>
										Location
									</Typography>
									{Data?.location}
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography id='qr_container' variant='subtitle2' gutterBottom>
									<Typography variant='caption' sx={{ color: '#707070' }} component='div'>
										Permanent Check In/Out QR Code
									</Typography>
									<QRCode
										id='qr_code_id'
										size={275}
										value={`${Data?.permanentToken}`}
										//onClick={() => setOpenDialog(true)}
										style={{ cursor: 'pointer' }}
									/>
								</Typography>
								<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<Button
										sx={{ textTransform: 'capitalize', width: '110px' }}
										variant='outlined'
										onClick={downloadQR}
										startIcon={<FileDownloadIcon />}
									>
										Download
									</Button>
									<Button
										sx={{ textTransform: 'capitalize', width: '110px', margin: '5px' }}
										variant='contained'
										onClick={onRefreshPermanentToken}
										startIcon={<ChangeCircleIcon />}
									>
										Refresh
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={4}>
						<Box
							component='img'
							sx={{ width: '90%', margin: '60px auto auto', display: 'block' }}
							alt='/'
							src='/images/gateway.svg'
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography variant='h6' gutterBottom>
							Geofence
						</Typography>
						<MapComp
							MapCenter={MapCenter}
							MapZoom={MapZoom}
							polygonPaths={Data?.polygon}
							circleInfo={Data?.circle}
							drawingControl={false}
							ShowShape={true}
							hideDrawingControl={true}
						/>
					</Grid>
				</Grid>
			</Box>

			<Dialog
				open={OpenDialog}
				keepMounted
				onClose={() => setOpenDialog(false)}
				aria-describedby='alert-dialog-slide-description'
			>
				<Box p={2}>
					<QRCodeSVG size={300} value={`${Data?.qr_code}`} />
				</Box>
			</Dialog>
		</>
	);
}

export default Profile;
