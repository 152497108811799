import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, Stack, Chip } from '@mui/material';
import { DataGridPremium, useGridApiRef, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import moment from 'moment';

import axios from '../../axiosInstance';
import { API } from '../../api';
function CustomToolbar() {
	return (
		<GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
			<GridToolbarExport printOptions={{ disableToolbarButton: true }} excelOptions={{ disableToolbarButton: true }} />
		</GridToolbarContainer>
	);
}
function Home() {
	const [AttendeesEmployees, setAttendeesEmployees] = useState([]);
	const [AbsenteesEmployees, setAbsenteesEmployees] = useState([]);
	const [SearchByName, setSearchByName] = useState('');
	const [Data, setData] = useState({
		Attendees: 0,
		Absentees: 0,
	});
	const [selected_day, setselected_day] = useState(new Date());
	let days = [
		{
			label: moment(selected_day).add(-3, 'day').format('ddd'),
			num: moment(selected_day).add(-3, 'day').format('D'),
			date: moment(selected_day).add(-3, 'day'),
		},
		{
			label: moment(selected_day).add(-2, 'day').format('ddd'),
			num: moment(selected_day).add(-2, 'day').format('D'),
			date: moment(selected_day).add(-2, 'day'),
		},
		{
			label: moment(selected_day).add(-1, 'day').format('ddd'),
			num: moment(selected_day).add(-1, 'day').format('D'),
			date: moment(selected_day).add(-1, 'day'),
		},
		{
			label: moment(selected_day).add(0, 'day').format('ddd'),
			num: moment(selected_day).add(0, 'day').format('D'),
			date: moment(selected_day).add(0, 'day'),
			bgcolor: '#000',
			color: '#fff',
		},
		{
			label: moment(selected_day).add(1, 'day').format('ddd'),
			num: moment(selected_day).add(1, 'day').format('D'),
			date: moment(selected_day).add(1, 'day'),
		},
		{
			label: moment(selected_day).add(2, 'day').format('ddd'),
			num: moment(selected_day).add(2, 'day').format('D'),
			date: moment(selected_day).add(2, 'day'),
		},
		{
			label: moment(selected_day).add(3, 'day').format('ddd'),
			num: moment(selected_day).add(3, 'day').format('D'),
			date: moment(selected_day).add(3, 'day'),
		},
	];

	const apiRef = useGridApiRef();

	function getHours(num: number) {
		let hours = Math.trunc(num / 60);
		let mins = num % 60;
		return `${hours}h ${mins}m`;
	}
	useEffect(() => {
		// counters
		axios
			.get(API.analysis.counters, {
				params: {
					from: moment(selected_day).startOf('day').toISOString(),
					to: moment(selected_day).endOf('day').toISOString(),
				},
			})
			.then((res) => {
				let data = res?.data?.data;
				setData({
					Attendees: data.attendees,
					Absentees: data.total - data.attendees,
				});
			})
			.catch((err) => {});
		// Absentees Employees
		axios
			.get(API.analysis.absentees, {
				params: {
					total: true,
					from: moment(selected_day).startOf('day').toISOString(),
					to: moment(selected_day).endOf('day').toISOString(),
				},
			})
			.then((res) => {
				let data = res?.data?.data.map((val: any) => ({
					id: val?.id,
					name: `${val?.firstName} ${val?.lastName}`,
					img: val?.avatar?.url || '/images/error_img.jpg',
					email: val?.email,
					align: 'center',
				}));
				setAbsenteesEmployees(data);
			})
			.catch((err) => {
				setAbsenteesEmployees([]);
			});
	}, [selected_day]);
	useEffect(() => {
		// Attendees Employees
		axios
			.get(API.analysis.attendees, {
				params: {
					total: true,
					firstName: SearchByName ? SearchByName : undefined,
					from: moment(selected_day).startOf('day').toISOString(),
					to: moment(selected_day).endOf('day').toISOString(),
				},
			})
			.then((res) => {
				let data = res?.data?.data.map((val: any) => ({
					id: val?.id,
					name: `${val?.firstName} ${val?.lastName}`,
					img: val?.avatar?.url || '/images/error_img.jpg',
					check_in: val?.checkIn ? moment(val?.checkIn).format('hh:mm a') : '--:--',
					check_out: val?.checkOut ? moment(val?.checkOut).format('hh:mm a') : '--:--',
					working_hours: val?.workingHours ? getHours(val?.workingHours) : '--:--',
					align: 'center',
				}));
				setAttendeesEmployees(data);
			})
			.catch((err) => {
				setAttendeesEmployees([]);
			});
	}, [SearchByName, selected_day]);

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Name',
			align: 'left',
			headerAlign: 'center',
			flex: 2,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
			renderCell: (params: any) => {
				return (
					<>
						<Stack direction='row' spacing={2} justifyContent='flex-start' alignItems='center'>
							<img
								src={params?.row?.img}
								width='50px'
								height={'30px'}
								onLoad={() => {}}
								onError={(e) => {
									e.currentTarget.onerror = null; // prevents looping
									e.currentTarget.src = '/images/error_img.jpg';
								}}
							/>
							<Typography variant='subtitle2' gutterBottom sx={{ width: '70%', color: '#000' }}>
								{params?.row?.name}
							</Typography>
						</Stack>
					</>
				);
			},
		},
		{
			field: 'check_in',
			headerName: 'Check in',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'check_out',
			headerName: 'Check out',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'working_hours',
			headerName: 'Working  Hr’s',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
	];

	return (
		<>
			<Box
				height={1}
				sx={{ bgcolor: '#DEDEDE', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
				p={1}
			>
				<Grid container spacing={2} height={1}>
					<Grid item xs={6}>
						<Typography variant='h6' height={'4%'} gutterBottom component='div' sx={{ color: '#000000' }}>
							Dashboard
						</Typography>
						<Box height={'95%'} sx={{ bgcolor: '#fff', borderRadius: '12px' }} p={1}>
							<Box
								height={1 / 12}
								sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
							>
								<Typography variant='subtitle1' fontWeight={500} gutterBottom>
									Attendees Employees
								</Typography>
								<input
									id='search-icon'
									style={{ textIndent: '40px', width: '65%' }}
									type='text'
									className='input-style'
									placeholder='Search by Employee'
									value={SearchByName}
									onChange={(e) => setSearchByName(e.target.value)}
								/>
							</Box>

							{AttendeesEmployees.length > 0 ? (
								<Box
									height={10 / 12}
									p={1}
									sx={{
										width: 1,
										'& .table-header': {
											color: '#707070',
										},
									}}
								>
									<DataGridPremium
										className='custom-grid-class'
										columns={columns}
										rows={AttendeesEmployees || []}
										apiRef={apiRef}
										disableSelectionOnClick
										disableColumnResize
										disableColumnReorder
										disableChildrenSorting
										components={{
											Toolbar: CustomToolbar,
										}}
									/>
								</Box>
							) : (
								<Stack
									sx={{ bgcolor: '#fff', borderRadius: '12px' }}
									height={10 / 12}
									direction='column'
									justifyContent='center'
									alignItems='center'
									spacing={2}
								>
									<Box
										component='img'
										sx={{ width: '30%', display: 'block' }}
										alt='/'
										src='/images/absentees_employees_empty.png'
									/>
									<Typography variant='h6' gutterBottom fontWeight={'500'}>
										No Employees Attend Today..!
									</Typography>
									<Typography variant='subtitle2' display='block' gutterBottom sx={{ color: '#707070' }}>
										Ohh..there is no employees attend today I think it’s like a weekend.. 😉
									</Typography>
								</Stack>
							)}
						</Box>
					</Grid>
					<Grid item xs={6}>
						<Box display={'flex'} flexDirection='column' justifyContent={'space-between'} height={1}>
							<Box sx={{ height: '270px', marginBottom: '10px' }}>
								<Box sx={{ bgcolor: '#fff', borderRadius: '12px' }} p={1} height={1}>
									<Grid container item spacing={3}>
										<Grid item xs={12}>
											<Box
												sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
											>
												<Typography
													variant='subtitle1'
													fontWeight={600}
													color='primary'
													sx={{ marginBottom: '0px' }}
												>
													{moment(selected_day).format('MMMM, YYYY')}
												</Typography>
												<Typography
													onClick={() => setselected_day(new Date())}
													variant='subtitle1'
													sx={{ cursor: 'pointer', marginBottom: '0px' }}
												>
													Today
												</Typography>
											</Box>
										</Grid>
										<Grid item xs={12}>
											<Box
												sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}
											>
												<ArrowBackIcon
													fontSize='small'
													sx={{
														bgcolor: '#000',
														color: '#fff',
														cursor: 'pointer',
														borderRadius: '5px',
													}}
													onClick={() => {
														setselected_day(
															new Date(moment(selected_day).add(-1, 'day').toISOString())
														);
													}}
												/>
												{days.map((val, i) => (
													<Box key={i}>
														<Typography
															sx={{
																color: val.color ? '#000' : '#707070',
																fontWeight: val.color ? '700' : '400',
																textAlign: 'center',
															}}
															variant='caption'
															display='block'
															gutterBottom
														>
															{val.label}
														</Typography>
														<Chip
															label={val.num}
															sx={{
																bgcolor: val.bgcolor ? val.bgcolor : '#F2F2F2',
																color: val.color ? val.color : '#707070',
																cursor: 'pointer',
															}}
															onClick={() => {
																setselected_day(new Date(val.date.toISOString()));
															}}
														/>
													</Box>
												))}
												<ArrowForwardIcon
													fontSize='small'
													sx={{
														bgcolor: '#000',
														color: '#fff',
														cursor: 'pointer',
														borderRadius: '5px',
													}}
													onClick={() => {
														setselected_day(
															new Date(moment(selected_day).add(1, 'day').toISOString())
														);
													}}
												/>
											</Box>
										</Grid>
										<Grid item xs={6}>
											<Box sx={{ bgcolor: '#F1F1F1', borderRadius: '8px' }} p={1}>
												<Typography variant='body2' gutterBottom>
													Attendees
												</Typography>
												<Typography variant='h6' gutterBottom>
													{Data.Attendees}
												</Typography>
											</Box>
										</Grid>
										<Grid item xs={6}>
											<Box sx={{ bgcolor: '#F1F1F1', borderRadius: '8px' }} p={1}>
												<Typography variant='body2' gutterBottom>
													Absentees
												</Typography>
												<Typography variant='h6' gutterBottom>
													{Data.Absentees}
												</Typography>
											</Box>
										</Grid>
									</Grid>
								</Box>
							</Box>
							{AbsenteesEmployees.length > 0 ? (
								<Box
									height={1}
									p={1}
									sx={{
										bgcolor: '#fff',
										borderRadius: '12px',
										width: 1,
										'& .table-header': {
											color: '#000000',
										},
									}}
								>
									<DataGridPremium
										className='custom-grid-class'
										columns={[
											{
												field: 'name',
												headerName: 'Absentees Employees',
												align: 'left',
												headerAlign: 'left',
												flex: 2,
												hideSortIcons: true,
												disableColumnMenu: true,
												sortable: false,
												resizable: false,
												headerClassName: 'table-header',
												renderCell: (params: any) => {
													return (
														<>
															<Stack
																direction='row'
																spacing={2}
																justifyContent='flex-start'
																alignItems='center'
															>
																<img
																	src={params?.row?.img}
																	width='50px'
																	height={'30px'}
																	onLoad={() => {}}
																	onError={(e) => {
																		e.currentTarget.onerror = null; // prevents looping
																		e.currentTarget.src = '/images/error_img.jpg';
																	}}
																/>
																<Typography
																	variant='subtitle2'
																	gutterBottom
																	sx={{ width: '70%', color: '#000' }}
																>
																	{params?.row?.name} <br />
																	<Typography
																		variant='body1'
																		gutterBottom
																		sx={{ color: '#707070', fontSize: '14px' }}
																	>
																		{params?.row?.email}
																	</Typography>
																</Typography>
															</Stack>
														</>
													);
												},
											},
										]}
										rows={AbsenteesEmployees || []}
										disableSelectionOnClick
										disableColumnResize
										disableColumnReorder
										disableChildrenSorting
										components={{
											Toolbar: CustomToolbar,
										}}
									/>
								</Box>
							) : (
								<Stack
									sx={{ bgcolor: '#fff', borderRadius: '12px', flexGrow: '1' }}
									height={1}
									direction='column'
									justifyContent='center'
									alignItems='center'
									spacing={2}
								>
									<Box
										component='img'
										sx={{ width: '30%', display: 'block' }}
										alt='/'
										src='/images/absentees_employees_empty.png'
									/>
									<Typography variant='h6' gutterBottom fontWeight={'500'}>
										No Employees Absences Today..!
									</Typography>
									<Typography variant='subtitle2' display='block' gutterBottom sx={{ color: '#707070' }}>
										Ohh..there is no employees absences today it’s great Day..😊
									</Typography>
								</Stack>
							)}
						</Box>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default Home;
