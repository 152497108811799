import axios from 'axios';
import { API } from './api';

const baseURL = process.env.REACT_APP_API_HOSTNAME || '';

const instance = axios.create({
	baseURL: baseURL,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
});
instance.interceptors.request.use(
	function (config) {
		const token = `Bearer ${localStorage.getItem('accessToken')}`;
		config.headers = { Authorization: token };
		return config;
	},
	function (error) {
		console.log('error', error.status);
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response;
	},
	function (error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		//const context = React.useContext(AuthContext);
		if (error?.response?.status !== 401) {
			return Promise.reject(error);
		}

		//axios.interceptors.response.eject(interceptor);
		if (error?.response?.status === 401) {
			let refreshToken = `${localStorage.getItem('refreshToken')}`;
			return axios
				.post(`${baseURL}${API.refreshtoken}`, { token: refreshToken })
				.then((response) => {
					localStorage.setItem('refreshToken', response.data.data.refreshToken);
					//context?.setAccessToken(response.data.data.accessToken);
					localStorage.setItem('accessToken', response.data.data.accessToken);
					return instance(error.response.config);
				})
				.catch((error) => {
					localStorage.clear();
					//navigate('/login');
					window.location.assign('/login');
					return Promise.reject(error);
				});
		}
	}
);
export default instance;
