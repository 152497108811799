import React, { useState, useEffect } from 'react';
import { Stack, Grid, Box, Typography, Button, IconButton, Menu, MenuItem, Dialog, Snackbar, Skeleton } from '@mui/material';
import { DataGridPremium, useGridApiRef, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useNavigate } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import axios from '../../axiosInstance';
import { API } from '../../api';
import ConfirmDialog from '../../Components/ConfirmDialog';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

function CustomToolbar() {
	return (
		<GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
			<GridToolbarExport printOptions={{ disableToolbarButton: true }} excelOptions={{ disableToolbarButton: true }} />
		</GridToolbarContainer>
	);
}
function Gateways() {
	const navigate = useNavigate();
	const [Gateways, setGateways] = useState([]);
	const [totalRecords, settotalRecords] = useState(0);
	const [pageSize, setPageSize] = React.useState<number>(20);
	const [page, setPage] = React.useState(0);
	const [RowData, setRowData] = useState<any>();
	const [OpenDialog, setOpenDialog] = useState(false);
	const [OpenDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [OpenMessage, setOpenMessage] = useState(false);
	const [IsError, setIsError] = useState(false);
	const [SearchByName, setSearchByName] = useState('');
	const [RefreshData, setRefreshData] = useState(false);
	const [Loading, setLoading] = useState(false);
	const [contextMenu, setContextMenu] = React.useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const apiRef = useGridApiRef();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setContextMenu(null);
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	useEffect(() => {
		setLoading(true);
		setAnchorEl(null);
		axios
			.get(API.gate.gate, {
				params: {
					total: true,
					skip: page * (pageSize === undefined ? 0 : pageSize),
					limit: pageSize,
					name: SearchByName.length > 0 ? SearchByName : undefined,
				},
			})
			.then((res) => {
				settotalRecords(res?.data?.totalRecords);
				let data = res?.data?.data.map((val: any) => ({
					id: val?.id,
					name: val?.name,
					employees: val?.employees,
					location: val?.address?.location || '',
					qr_code: val?.qrCode,
					align: 'center',
				}));
				setGateways(data);
				setLoading(false);
			})
			.catch((err) => {
				setGateways([]);
				setLoading(false);
			});
	}, [pageSize, page, SearchByName, RefreshData]);
	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Gateway',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header left-corner',
		},
		{
			field: 'employees',
			headerName: 'Employees',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'location',
			headerName: 'Location',
			align: 'center',
			headerAlign: 'center',
			flex: 2,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'qr_code',
			headerName: 'QR Code',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
			renderCell: (params: any) => (
				<QRCodeSVG
					onClick={() => setOpenDialog(true)}
					style={{ cursor: 'pointer' }}
					size={40}
					value={params.row.qr_code}
				/>
			),
		},
		{
			field: 'action',
			headerName: 'Actions',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			disableExport: true,
			headerClassName: 'table-header right-corner',
			renderCell: (params: any) => {
				//console.log(params);
				return (
					<Box>
						<IconButton
							aria-label='action'
							aria-controls={open ? 'basic-menu' : undefined}
							aria-haspopup='true'
							aria-expanded={open ? 'true' : undefined}
							onClick={handleClick}
						>
							<MoreVertIcon />
						</IconButton>
						{RowData?.id === params?.row?.id && (
							<Menu
								id='basic-menu'
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								MenuListProps={{
									'aria-labelledby': 'basic-button',
								}}
							>
								<MenuItem
									onClick={() => {
										navigate(`/gateways/new-gateway/${RowData.id}`);
										//console.log(params);
									}}
									sx={{ width: '100px' }}
								>
									<ModeEditIcon fontSize='small' /> &nbsp; Edit
								</MenuItem>
								<MenuItem onClick={() => setOpenDeleteDialog(true)} sx={{ width: '100px' }}>
									<DeleteIcon fontSize='small' /> &nbsp; Delete
								</MenuItem>
							</Menu>
						)}
					</Box>
				);
			},
		},
	];
	const handleContextMenu = (event: React.MouseEvent) => {
		event.preventDefault();
		setAnchorEl(null);
		//console.log(event.currentTarget.getAttribute('data-id'));
		let rowData = Gateways.filter((val: any) => val.id === event.currentTarget.getAttribute('data-id'));
		setRowData(rowData[0]);
		setContextMenu(contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
	};
	const onDelete = () => {
		axios
			.delete(`${API.gate.gate}/${RowData?.id}`)
			.then((res) => {
				setOpenDeleteDialog(false);
				setIsError(false);
				setOpenMessage(true);
				setRefreshData(!RefreshData);
			})
			.catch((err) => {
				setOpenDeleteDialog(false);
				setIsError(true);
				setOpenMessage(true);
			});
		setContextMenu(null);
		setAnchorEl(null);
	};
	return (
		<>
			<Stack spacing={2} p={3} height={1}>
				<Box>
					<Grid container spacing={2}>
						<Grid item xs={2}>
							<Typography variant='h5' gutterBottom fontWeight={'600'}>
								Gateways
							</Typography>
						</Grid>
						<Grid item xs={5}>
							<input
								id='search-icon'
								style={{ textIndent: '40px', width: '100%' }}
								type='text'
								className='input-style'
								placeholder='Search by Gateway'
								value={SearchByName}
								onChange={(e) => {
									setSearchByName(e.target.value);
									setPage(0);
								}}
							/>
						</Grid>
						<Grid item xs={1} />
						{Gateways.length > 0 && (
							<Grid item xs={3}>
								<Link to='/gateways/new-gateway'>
									<Button sx={{ textTransform: 'capitalize', width: '100%' }} variant='contained'>
										Add New Gateway
									</Button>
								</Link>
							</Grid>
						)}
						<Grid item xs={1} />
					</Grid>
				</Box>
				{Loading && <Skeleton variant='rectangular' width={'100%'} height={118} />}
				{Gateways.length > 0 && !Loading && (
					<Box
						height={1}
						sx={{
							'& .table-header': {
								backgroundColor: '#dadada',
							},
							'& .left-corner': {
								borderTopLeftRadius: '15px',
							},
							'& .right-corner': {
								borderTopRightRadius: '15px',
							},
							'& .css-f3jnds-MuiDataGrid-columnHeaders': {
								borderTopRightRadius: '15px !important',
								borderTopLeftRadius: '15px !important',
								background: '#dadada !important',
							},
						}}
					>
						<DataGridPremium
							className='custom-grid-class'
							onRowClick={(rowData) => {
								setRowData(rowData?.row);
								//console.log(rowData?.row);
							}}
							onCellClick={(rowData) => {
								if (rowData.field !== 'action') {
									navigate(`/gateways/profile/${rowData?.row?.id}`);
								}
							}}
							columns={columns}
							rows={Gateways || []}
							apiRef={apiRef}
							disableSelectionOnClick
							pagination
							paginationMode='server'
							page={page}
							onPageChange={(newPage) => setPage(newPage)}
							pageSize={pageSize}
							onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50]}
							rowCount={totalRecords}
							disableColumnResize
							disableColumnReorder
							disableChildrenSorting
							componentsProps={{
								row: {
									onContextMenu: handleContextMenu,
									style: { cursor: 'context-menu' },
								},
							}}
							components={{
								Toolbar: CustomToolbar,
							}}
						/>
						<Menu
							open={contextMenu !== null}
							onClose={() => setContextMenu(null)}
							anchorReference='anchorPosition'
							anchorPosition={
								contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
							}
							componentsProps={{
								root: {
									onContextMenu: (e: any) => {
										e.preventDefault();
										setContextMenu(null);
									},
								},
							}}
						>
							<MenuItem
								onClick={() => {
									navigate(`/gateways/new-gateway/${RowData.id}`);
									//console.log(params);
								}}
								sx={{ width: '100px' }}
							>
								<ModeEditIcon fontSize='small' /> &nbsp; Edit
							</MenuItem>
							<MenuItem onClick={() => setOpenDeleteDialog(true)} sx={{ width: '100px' }}>
								<DeleteIcon fontSize='small' /> &nbsp; Delete
							</MenuItem>
						</Menu>
					</Box>
				)}
				{Gateways.length === 0 && !Loading && (
					<Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
						<Box component='img' sx={{ width: '30%', display: 'block' }} alt='/' src='/images/gateway.svg' />
						<Typography variant='h5' gutterBottom fontWeight={'500'}>
							No Gateways Yet!
						</Typography>
						<Typography variant='subtitle2' display='block' gutterBottom sx={{ color: '#707070' }}>
							You not have any Gateways there You can add new Gateway from below.
						</Typography>
						<Link to='/gateways/new-gateway'>
							<Button sx={{ textTransform: 'capitalize', width: '100%' }} variant='contained'>
								Add New Gateway
							</Button>
						</Link>
					</Stack>
				)}
			</Stack>

			<Dialog
				open={OpenDialog}
				keepMounted
				onClose={() => setOpenDialog(false)}
				aria-describedby='alert-dialog-slide-description'
			>
				<Box p={2}>
					<QRCodeSVG size={300} value={RowData?.qr_code} />
				</Box>
			</Dialog>

			{/** Delete */}
			<ConfirmDialog
				open={OpenDeleteDialog}
				onClose={() => setOpenDeleteDialog(false)}
				title=''
				message='Are you sure you want to delete this gateway?'
				cancelLabel='Close'
				saveLabel='Delete'
				onApply={onDelete}
			/>
			<Snackbar open={OpenMessage} autoHideDuration={6000} onClose={() => setOpenMessage(false)}>
				{IsError ? (
					<Alert onClose={() => setOpenMessage(false)} severity='error' sx={{ width: '100%' }}>
						Request failed!
					</Alert>
				) : (
					<Alert onClose={() => setOpenMessage(false)} severity='success' sx={{ width: '100%' }}>
						Request succeeded.
					</Alert>
				)}
			</Snackbar>
		</>
	);
}

export default Gateways;
