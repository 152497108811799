//const moment = require('moment');
import moment from 'moment';

export function generateFlatRows(from: any, to: any, data: any, lunchBreakThreshold: any, lunchBreakDuration: any) {
	let newdata = data
		.filter((val: any) => val?.checkOut?.at !== undefined)
		.sort((a: any, b: any) => {
			if (moment(a.checkIn.at).isSameOrBefore(moment(b.checkIn.at))) return -1;
			return 1;
		});

	newdata = newdata.map((val: any) => {
		const result: {
			date: moment.Moment;
			checkIn: string;
			checkOut: string;
			workingHours: any;
			lunchBreak?: any;
		} = {
			date: moment(val.checkIn.at).startOf('day'),
			checkIn: moment(val.checkIn.at).format('hh:mm A'),
			checkOut: moment(val.checkOut?.at).format('hh:mm A'),
			workingHours: val.workingHours,
		};
		if (result.workingHours >= lunchBreakThreshold) result.lunchBreak = lunchBreakDuration;

		return result;
	});

	const days = [];
	const length = to.diff(from, 'days');
	for (let i = 0; i <= length; i++) {
		days.push(moment(from).add(i, 'days'));
	}

	const rows = days.map((val) => {
		const data = newdata.filter((value: any) => val.isSame(value.date));
		return {
			dayString: val.format('dddd'),
			dayDate: val.format('DD/MM/YYYY'),
			date: val,
			data,
		};
	});

	const flatRows = rows.flatMap((val) => {
		if (val.data.length === 0)
			return {
				dayString: val.dayString,
				dayDate: val.dayDate,
				date: val.date,
			};

		val.data[0] = {
			...val.data[0],
			dayString: val.dayString,
			dayDate: val.dayDate,
			date: val.date,
		};

		return val.data;
	});

	return flatRows;
}
//let result = generateFlatRows(from, to, data, 5 * 60, -30);

// let groupedResult = result.reduce((acc, cur) => {
// 	let index = acc.findIndex((val) => val.date === cur.date.month() + 1);
// 	if (index === -1) {
// 		acc.push({
// 			date: cur.date.month() + 1,
// 			data: [
// 				{ workingHours: 0, data: [] },
// 				{ workingHours: 0, data: [] },
// 			],
// 			workingHours: 0,
// 		});
// 		index = acc.length - 1;
// 	}

// 	const semiIndex = cur.date.date() <= 15 ? 0 : 1;
// 	acc[index].data[semiIndex].data.push(cur);

// 	const addedHours = (cur.workingHours || 0) + (cur.lunchBreak || 0);
// 	acc[index].data[semiIndex].workingHours += addedHours;
// 	acc[index].workingHours += addedHours;

// 	return acc;
// }, []);
const from = moment('2022-07-01');

const to = moment('2022-07-31');

let data = {
	data: [
		{
			employee: {
				firstName: 'Malcolm',
				lastName: 'Underwood',
				id: '000000020000000000000010',
				department: {
					name: 'Management',
					id: '000000060000000000000001',
				},
			},
			status: 'Accepted',
			checkIn: {
				type: 'Mobile',
				at: '2022-07-17T01:56:41.874Z',
			},
			requestedAt: '2022-07-19T17:27:42.390Z',
			reason: 'Lorem ipsum',
			id: '62d6e98eefe397af7e26b008',
		},
		{
			employee: {
				firstName: 'Malcolm',
				lastName: 'Underwood',
				id: '000000020000000000000010',
				department: {
					name: 'Management',
					id: '000000060000000000000001',
				},
			},
			status: 'Accepted',
			checkIn: {
				type: 'Mobile',
				at: '2022-07-16T22:00:40.874Z',
			},
			requestedAt: '2022-07-17T12:02:43.196Z',
			reason: 'Lorem ipsum',
			checkOut: {
				at: '2022-07-16T23:00:00.939Z',
				type: 'Admin',
			},
			id: '62d3fa636557e8f84a6c827f',
			workingHours: 60,
		},
		{
			employee: {
				firstName: 'Malcolm',
				lastName: 'Underwood',
				id: '000000020000000000000010',
				department: {
					name: 'Management',
					id: '000000060000000000000001',
				},
			},
			status: 'Accepted',
			checkIn: {
				type: 'Mobile',
				at: '2022-07-16T12:00:00.000Z',
			},
			requestedAt: '2022-07-17T12:02:43.196Z',
			reason: 'Lorem ipsum',
			checkOut: {
				at: '2022-07-16T17:00:00.000Z',
				type: 'Admin',
			},
			id: '62d3fa636557e8f84a6c827f',
			workingHours: 300,
		},
		{
			employee: {
				firstName: 'Malcolm',
				lastName: 'Underwood',
				id: '000000020000000000000010',
				department: {
					name: 'Management',
					id: '000000060000000000000001',
				},
			},
			status: 'Accepted',
			checkIn: {
				type: 'Mobile',
				at: '2022-07-16T01:00:00.000Z',
			},
			requestedAt: '2022-07-17T12:02:43.196Z',
			reason: 'Lorem ipsum',
			checkOut: {
				at: '2022-07-16T03:00:00.000Z',
				type: 'Admin',
			},
			id: '62d3fa636557e8f84a6c827f',
			workingHours: 120,
		},
	],
};

//let result = generateFlatRows(from, to, data, 5 * 60, -30);

// workingHours for flatRows - lunchBreak
//let workingHoursSum = result.reduce((acc, cur) => (acc += (cur.workingHours || 0) + (cur.lunchBreak || 0)), 0);

// workingHoursSum To Hours format
//let workingHoursSumText = `${parseInt(workingHoursSum / 60)}h ${parseInt(workingHoursSum % 60)}m`;

//console.log(result);
// console.log(workingHoursSum);
// console.log(workingHoursSumText);

//let FirstSemiMonthlyRows = result.filter((val) => val.date.date() <= 15);

// console.log(FirstSemiMonthlyRows);

//let SecondSemiMonthlyRows = result.filter((val) => val.date.date() > 15);

// console.log(SecondSemiMonthlyRows);
