import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, FormControl, Select, MenuItem, Chip, Snackbar } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { SelectChangeEvent } from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useParams, useNavigate } from 'react-router-dom';

import axios from '../../axiosInstance';
import { API } from '../../api';
import Joi from 'joi';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
function NewDepartments() {
	let { id } = useParams();
	let navigate = useNavigate();
	const [Loading, setLoading] = useState(false);
	const [OpenMessage, setOpenMessage] = useState(false);
	const [IsError, setIsError] = useState(false);
	const [Employees, setEmployees] = React.useState<string[]>([]);
	const [EmployeesDDL, setEmployeesDDL] = useState<{ name: string; id: object }[]>([]);
	const [Data, setData] = useState({
		name: '',
		managers: [],
	});
	const [Errors, setErrors] = useState<any>({});
	let save_schema = Joi.object({
		name: Joi.string().required(),
		managers: Joi.array(),
		employees: Joi.array(),
	});
	let update_schema = Joi.object({
		name: Joi.string(),
		managers: Joi.array(),
		employees: Joi.array(),
	});
	useEffect(() => {
		if (id) {
			axios.get(`${API.department.department}/${id}`).then((res) => {
				setData({
					name: res?.data?.data?.name,
					managers: res?.data?.data?.managers?.map((ele: any) => ele.id),
				});
				setEmployees(res?.data?.data?.employees?.map((ele: any) => ele.id) || []);
			});
		}
		axios
			.get(API.user.basic, {
				params: {
					total: true,
					isActive: true,
				},
			})
			.then((res) => {
				let data: { name: string; id: object }[] = res?.data?.data.map((val: any) => ({
					name: `${val?.firstName} ${val?.lastName}`,
					id: val?.id,
				}));
				setEmployeesDDL([...data]);
			})
			.catch((err) => {
				setEmployeesDDL([]);
			});
	}, [id]);
	const handleChange_Employees = (event: SelectChangeEvent<typeof Employees>) => {
		const {
			target: { value },
		} = event;
		setEmployees(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value
		);
	};
	const validateFunc = (data: any) => {
		let schema = id ? update_schema : save_schema;
		schema
			.validateAsync(data)
			.then((val) => {
				//console.log('val', val);
				setErrors({});
			})
			.catch((err) => {
				console.log('err', err.details);
				setErrors((prev: any) => ({
					[err.details[0].context.label]: err.details[0].message,
				}));
				//throw new Error('Failed to validate input ' + err.details[0].message);
			});
	};
	const onChange_Data = (e: any) => {
		const { name, value } = e.target;
		setData((prev) => ({
			...prev,
			[name]: value,
		}));
		if (name === 'name') {
			validateFunc({
				[name]: value,
			});
		}
	};
	function getUserName(id: any) {
		let findUser = EmployeesDDL.find((val: any) => val?.id === id);
		return findUser ? findUser?.name : '';
	}
	const onSave = () => {
		setLoading(true);
		let requestBody = {
			...Data,
			employees: Employees,
		};
		let request = id
			? axios.patch(`${API.department.department}/${id}`, requestBody)
			: axios.post(API.department.department, requestBody);
		request
			.then((res) => {
				navigate('/departments');
			})
			.catch((err) => {
				setErrors({});
				setLoading(false);
				setIsError(true);
				setOpenMessage(true);
				if (err.response.data.args) {
					err.response.data.args.map((val: any) => {
						setErrors((prev: any) => ({
							...prev,
							[val.path.split('.')[1]]: val.message.split('.')[1],
						}));
					});
				}
			});
	};
	return (
		<>
			<Box height={'100%'} p={3}>
				<Grid container spacing={2} height={'100%'}>
					<Grid item xs={6} pb={5}>
						<Grid container item spacing={3}>
							<Grid item xs={12}>
								<Typography variant='h5' gutterBottom fontWeight={'600'}>
									{id ? 'Update Department' : 'Add New Department'}
								</Typography>
								<Typography variant='subtitle2' gutterBottom sx={{ color: '#707070' }}>
									Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
									been the industry's standard dummy text ever since the 1500s,
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Name
								</Typography>
								<input
									name='name'
									value={Data.name}
									onChange={onChange_Data}
									type={'text'}
									className='input-style'
									style={{ width: '100%' }}
								/>
								{Errors?.name && (
									<Typography variant='caption' style={{ color: '#FE0000' }} gutterBottom component='div'>
										{Errors?.name}
									</Typography>
								)}
							</Grid>
							<Grid item xs={12}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Manager
								</Typography>
								<FormControl fullWidth>
									<Select
										multiple
										name='managers'
										value={Data.managers}
										onChange={onChange_Data}
										label='Manager'
										className='input-style'
										sx={{ maxWidth: '100% !important' }}
									>
										{EmployeesDDL.map((val, i) => (
											<MenuItem key={i} value={`${val.id}`}>
												{val.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Employees
								</Typography>
								<FormControl fullWidth>
									<Select
										multiple
										label='Employees'
										className='input-style'
										value={Employees}
										onChange={handleChange_Employees}
										sx={{ maxWidth: '100% !important' }}
									>
										{EmployeesDDL.map((val, i) => (
											<MenuItem key={i} value={`${val.id}`}>
												{val.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							{Employees.map((val) => (
								<Grid item xs={4}>
									<Chip
										sx={{ width: '100%', bgcolor: '#707070', color: '#FFFFFF' }}
										label={getUserName(val)}
										onClick={() => {}}
										onDelete={() => {
											let employees = [...Employees].filter((ele) => ele !== val);
											setEmployees([...employees]);
										}}
										deleteIcon={<CloseIcon sx={{ color: '#ffffff !important' }} />}
									/>
								</Grid>
							))}
							<Grid item xs={12}>
								<LoadingButton
									loading={Loading}
									sx={{ textTransform: 'capitalize', width: '100%' }}
									variant='contained'
									onClick={onSave}
									disabled={Object.keys(Errors).length > 0}
								>
									Save
								</LoadingButton>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={1} />
					<Grid item xs={5}>
						<Box
							component='img'
							sx={{ width: '90%', margin: '60px auto auto', display: 'block' }}
							alt='/'
							src='/images/departments.svg'
						/>
					</Grid>
				</Grid>
			</Box>
			<Snackbar open={OpenMessage} autoHideDuration={6000} onClose={() => setOpenMessage(false)}>
				{IsError ? (
					<Alert onClose={() => setOpenMessage(false)} severity='error' sx={{ width: '100%' }}>
						Request failed!
					</Alert>
				) : (
					<Alert onClose={() => setOpenMessage(false)} severity='success' sx={{ width: '100%' }}>
						Request succeeded.
					</Alert>
				)}
			</Snackbar>
		</>
	);
}

export default NewDepartments;
