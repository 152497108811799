import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, FormControl, Select, MenuItem, TextField, Snackbar } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import axiosInstance from '../../axiosInstance';
import axios from 'axios';
import { API } from '../../api';
import Joi from 'joi';
import { parsePhoneNumber } from 'libphonenumber-js';
import Gateways from '../Gateways';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
type ddlType = { name: string; id: object }[];
type DataType = {
	avatar: undefined | Blob;
	type: string | undefined;
	password: string | undefined;
	firstName: string;
	lastName: string;
	phone: string | undefined;
	email: string | undefined;
	gender: string | undefined;
	address: string | undefined;
	jobTitle: string | undefined;
	tmw_id: string | undefined;
	departments: string[];
	supervisor: string | undefined;
	gates: string[];
	workPhone: string | undefined;
	extension: string;
	workEmail: string | undefined;
	rate: number;
	vacationLevel: string | undefined;
	vacationType: string | undefined;
	birthDate: Date | null | undefined;
	hireDate: Date | null | undefined;
	terminationDate: Date | undefined | null;
	note: string | undefined;
	sick: number | undefined;
	casual: number | undefined;
};
function NewEmployee() {
	let { id } = useParams();
	let navigate = useNavigate();
	const [Loading, setLoading] = useState(false);
	const [alertMessage, setAlertMessage] = useState('Request failed!');
	const [OpenMessage, setOpenMessage] = useState(false);
	const [IsError, setIsError] = useState(false);
	const [Data, setData] = useState<DataType>({
		avatar: undefined,
		type: '',
		password: undefined,
		firstName: '',
		lastName: '',
		phone: undefined,
		email: undefined,
		gender: undefined,
		address: undefined,
		jobTitle: undefined,
		departments: [],
		supervisor: undefined,
		gates: [],
		workPhone: undefined,
		extension: '',
		workEmail: undefined,
		rate: 0,
		vacationLevel: undefined,
		vacationType: undefined,
		birthDate: null,
		hireDate: null,
		terminationDate: null,
		note: undefined,
		tmw_id: undefined,
		sick: undefined,
		casual: undefined,
	});
	const [DDL, setDDL] = useState<{
		Employees: ddlType;
		Department: ddlType;
		Gateways: ddlType;
		VacationLevel: string[];
		VacationType: string[];
	}>({
		Employees: [],
		Department: [],
		Gateways: [],
		VacationLevel: [],
		VacationType: [],
	});
	const [ImageUrl, setImageUrl] = useState<any>(undefined);
	const [Errors, setErrors] = useState<any>({});
	const [ValidData, setValidData] = useState({});
	const phone = () =>
		Joi.custom((value) => parsePhoneNumber(value).number).error((errors: any) => {
			const errIndex = errors.findIndex((val: any) => val.code === 'any.custom');
			if (errIndex >= 0)
				errors[errIndex].message = `${errors[errIndex].local.label} ${errors[errIndex].local.error.message}`;
			return errors;
		});
	let schema = {
		firstName: Joi.string().required(),
		lastName: Joi.string().required(),
		type: Joi.string().required(),
		password: Joi.string(),
		phone: phone(),
		email: Joi.string().email({ tlds: { allow: false } }),
		gender: Joi.string(),
		birthDate: Joi.date(),
		address: Joi.string(),
		jobTitle: Joi.string(),
		tmw_id: Joi.string(),
		workPhone: phone(),
		extension: Joi.string().required(),
		workEmail: Joi.string().email({ tlds: { allow: false } }),
		rate: Joi.number(),
		hireDate: Joi.date(),
		sick: Joi.number(),
		casual: Joi.number(),
	};
	let save_schema = Joi.object({
		firstName: Joi.string().required(),
		lastName: Joi.string().required(),
		type: Joi.string().required(),
		password: Joi.string(),
		phone: phone(),
		email: Joi.string().email({ tlds: { allow: false } }),
		gender: Joi.string(),
		birthDate: Joi.date(),
		address: Joi.string(),
		jobTitle: Joi.string(),
		departments: Joi.array().items(Joi.string()),
		supervisor: Joi.string(),
		gates: Joi.array().items(Joi.string()),
		workPhone: phone(),
		extension: Joi.string().required(),
		workEmail: Joi.string().email({ tlds: { allow: false } }),
		rate: Joi.number(),
		hireDate: Joi.date(),
		vacationLevel: Joi.string(),
		vacationType: Joi.string(),
		sick: Joi.number(),
		casual: Joi.number(),
	});
	let update_schema = Joi.object({
		firstName: Joi.string(),
		lastName: Joi.string(),
		type: Joi.string(),
		password: Joi.string(),
		phone: phone(),
		email: Joi.string().email({ tlds: { allow: false } }),
		gender: Joi.string(),
		birthDate: Joi.date(),
		address: Joi.string(),
		jobTitle: Joi.string(),
		departments: Joi.array().items(Joi.string()),
		supervisor: Joi.string(),
		gates: Joi.array().items(Joi.string()),
		workPhone: phone(),
		extension: Joi.string(),
		workEmail: Joi.string().email({ tlds: { allow: false } }),
		rate: Joi.number(),
		hireDate: Joi.date(),
		vacationLevel: Joi.string(),
		vacationType: Joi.string(),
		sick: Joi.number(),
		casual: Joi.number(),
	});
	useEffect(() => {
		if (id) {
			axiosInstance.get(`${API.user.user}/${id}`).then((res) => {
				let data = res?.data?.data;
				setImageUrl(data?.avatar?.url);
				setData({
					avatar: undefined,
					type: data?.type || undefined,
					password: undefined,
					firstName: data?.firstName,
					lastName: data?.lastName,
					phone: data?.phone,
					email: data?.email,
					gender: data?.gender,
					address: data?.address,
					jobTitle: data?.jobTitle,
					tmw_id: data?.tmw_id || undefined,
					departments: data?.departments.map((val: any) => val.id) || [],
					supervisor: data?.supervisor?.id,
					gates: data?.gates.map((val: any) => val.id) || [],
					workPhone: data?.workPhone,
					extension: data?.extension,
					workEmail: data?.workEmail,
					rate: data?.rate,
					vacationLevel: data?.vacationLevel,
					vacationType: data?.vacationType,
					note: data?.note || undefined,
					birthDate: data?.birthDate ? new Date(data?.birthDate) : undefined,
					hireDate: data?.hireDate ? new Date(data?.hireDate) : undefined,
					terminationDate: data?.terminationDate ? new Date(data?.terminationDate) : undefined,
					sick: data?.vacation?.sick?.used | 0,
					casual: data?.vacation?.casual?.used | 0,
				});
			});
		}

		const requestEmployees = axiosInstance.get(API.user.user);
		const requestDepartment = axiosInstance.get(API.department.department);
		const requestGateways = axiosInstance.get(API.gate.gate);
		const requestEmployeesMetadata = axiosInstance.get(API.user.metadata);

		axios
			.all([requestEmployees, requestDepartment, requestGateways, requestEmployeesMetadata])
			.then(
				axios.spread((...responses) => {
					const responseOne = responses[0]?.data?.data || [];
					const responseTwo = responses[1]?.data?.data || [];
					const responesThree = responses[2]?.data?.data || [];
					const responesFour = responses[3]?.data?.data;
					setDDL({
						Employees: responseOne?.map((val: any) => ({
							name: `${val?.firstName} ${val?.lastName}`,
							id: val?.id,
						})),
						Department: responseTwo?.map((val: any) => ({
							name: val?.name,
							id: val?.id,
						})),
						Gateways: responesThree?.map((val: any) => ({
							name: val?.name,
							id: val?.id,
						})),
						VacationLevel: responesFour['VACATION_LEVELS'],
						VacationType: responesFour['VACATION_TYPES'],
					});
					// use/access the results
				})
			)
			.catch((errors) => {
				// react on errors.
			});
	}, [id]);
	const validateFunc = (data: any) => {
		delete data.avatar;

		delete data.phone;
		delete data.email;
		delete data.gender;
		delete data.address;
		delete data.jobTitle;
		delete data.workPhone;
		delete data.workEmail;
		delete data.rate;
		delete data.birthDate;
		delete data.hireDate;
		delete data.tmw_id;

		delete data.terminationDate;
		delete data.note;
		delete data.departments;
		delete data.gates;
		delete data.supervisor;
		delete data.vacationLevel;
		delete data.vacationType;
		setValidData(data);
		let schema = id ? update_schema : save_schema;
		schema
			.validateAsync(data)
			.then((val) => {
				setValidData(val);
				console.log('val', val);
				setErrors({});
			})
			.catch((err) => {
				// console.log('err', err.details);
			});
	};
	const onImageChange = (event: any) => {
		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0];
			setData((prev) => ({
				...prev,
				avatar: event.target.files[0], //URL.createObjectURL(img),
			}));
			setImageUrl(URL.createObjectURL(img));
			validateFunc({
				...Data,
				avatar: event.target.files[0],
			});
		}
	};
	const handleChange_Date = (newValue: Date | null, name: string) => {
		//setBirthDate(newValue);
		setData((prev) => ({
			...prev,
			[name]: newValue,
		}));
		validateFunc({
			...Data,
			[name]: newValue,
		});
	};

	const onChange_Data = (e: any) => {
		setErrors({});
		const { name, value } = e.target;
		setData((prev) => ({
			...prev,
			[name]: value,
		}));
		validateFunc({
			...Data,
			[name]: value,
		});

		const { error } = Joi.object({ [name]: (schema as any)[name] }).validate({ [name]: value });
		if (error) {
			setErrors({ [name]: error?.details[0].message });
		}
	};
	//console.log('ValidData', ValidData);
	const onSave = () => {
		setLoading(true);

		var data = new FormData();

		Object.keys(ValidData).map((key, index) => {
			if (
				(key === 'birthDate' || key === 'hireDate') &&
				(ValidData as any)[key] !== undefined &&
				(ValidData as any)[key] !== null
			) {
				data.append(key, (ValidData as any)[key]);
			} else if (
				(key === 'sick' || key === 'casual') &&
				(ValidData as any)[key] !== undefined &&
				(ValidData as any)[key] !== null
			) {
				data.append(`vacation[${key}]`, (ValidData as any)[key]);
			} else {
				if ((ValidData as any)[key] !== undefined && (ValidData as any)[key] !== null)
					data.append(key, `${(ValidData as any)[key]}`);
			}
		});

		if (Data?.avatar) data.append('avatar', Data?.avatar);
		if (Data.terminationDate) data.append('terminationDate', moment(Data.terminationDate).toISOString());
		if (Data.note) data.append('note', Data.note);
		if (Data.departments.length > 0) {
			Data.departments.forEach((val) => {
				data.append('departments', val);
			});
		}
		if (Data.gates.length > 0) {
			Data.gates.forEach((val) => {
				data.append('gates', val);
			});
		}
		if (Data.vacationLevel) data.append('vacationLevel', `${Data.vacationLevel}`);
		if (Data.vacationType) data.append('vacationType', `${Data.vacationType}`);

		if (Data?.phone) data.append('phone', Data?.phone);
		if (Data?.email) data.append('email', Data?.email);
		if (Data?.gender) data.append('gender', Data?.gender);
		if (Data?.address) data.append('address', Data?.address);
		if (Data?.jobTitle) data.append('jobTitle', Data?.jobTitle);
		if (Data?.workPhone) data.append('workPhone', Data?.workPhone);
		if (Data?.workEmail) data.append('workEmail', Data?.workEmail);
		if (Data?.rate) data.append('rate', `${Data?.rate}`);
		if (Data?.birthDate !== null && Data?.birthDate !== undefined)
			data.append('birthDate', moment(Data?.birthDate).toISOString());
		if (Data?.hireDate !== null && Data?.hireDate !== undefined)
			data.append('hireDate', moment(Data?.hireDate).toISOString());
		if (Data?.tmw_id) data.append('tmw_id', Data?.tmw_id);
		//if (Data.type === 'Admin' && Data.password) data.append('password', Data.password);

		// data.append('vacation[sick]', '10');
		// data.append('vacation[casual]', '10');

		let request = id ? axiosInstance.patch(`${API.user.user}/${id}`, data) : axiosInstance.post(API.user.user, data);
		request
			.then((res) => {
				setLoading(false);
				setIsError(false);
				setOpenMessage(true);
				navigate('/employees');
			})
			.catch((err) => {
				setErrors({});
				setLoading(false);
				setIsError(true);
				if (Array.isArray(err.response.data.args)) {
					err.response.data.args.map((val: any) => {
						setErrors((prev: any) => ({
							...prev,
							[val.path.split('.')[1] === 'vacation' ? val.path.split('.')[2] : val.path.split('.')[1]]:
								val.path.split('.')[1] === 'vacation' ? val.message.split('.')[2] : val.message.split('.')[1],
						}));
					});
					setAlertMessage(`${err.response.data.msg}`);
				} else {
					Object.keys(err.response.data.args).forEach((key) => {
						err.response.data.args[key] = `${err.response.data.msg} ${key}`;
					});
					setErrors({
						...err.response.data.args,
					});
					setAlertMessage(`${err.response.data.msg}  ${Object.keys(err.response.data.args).join(',')}`);
				}

				setOpenMessage(true);
			});
	};

	const renderErrorMessage = (name: string) => (
		<>
			{Errors[name] && (
				<Typography variant='caption' style={{ color: '#FE0000' }} gutterBottom component='div'>
					{Errors[name]}
				</Typography>
			)}
		</>
	);
	return (
		<>
			<Box height={'100%'} p={3}>
				<Grid container spacing={2} height={'100%'}>
					<Grid item xs={6} pb={5}>
						<Grid container item spacing={3}>
							<Grid item xs={12}>
								<Typography variant='h5' gutterBottom fontWeight={'600'}>
									{id ? 'Update Employee' : 'Add New Employee'}
								</Typography>
								<Typography variant='subtitle2' gutterBottom>
									You can add the employee by entering the required basic information and then generating a
									QR code and checking it through the employee's phone until the login is done
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography
									variant='body1'
									fontWeight={500}
									display='block'
									gutterBottom
									sx={{ color: '#707070' }}
								>
									Personal
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Box
									sx={{
										width: '150px',
										height: '100px',
										bgcolor: '#EAEAEA',
										borderRadius: '8px',
										cursor: 'pointer',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										flexDirection: 'column',
									}}
									onClick={() => document?.getElementById('selectImage')?.click()}
								>
									{!ImageUrl && <PersonIcon sx={{ fontSize: '80px', color: '#939393' }} />}
									{ImageUrl && (
										<Box component='img' sx={{ height: 100, width: '100%' }} alt='' src={ImageUrl} />
									)}
									<input
										id='selectImage'
										type='file'
										name='myImage'
										style={{ display: 'none' }}
										onChange={onImageChange}
									/>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Type
								</Typography>
								<FormControl fullWidth>
									<Select
										name='type'
										value={Data.type}
										onChange={onChange_Data}
										label='type'
										className='input-style'
									>
										<MenuItem value={'Employee'}>Employee</MenuItem>
										<MenuItem value={'Admin'}>Admin</MenuItem>
									</Select>
								</FormControl>
								{renderErrorMessage('type')}
							</Grid>
							{/* {Data.type !== 'Admin' && <Grid item xs={6}></Grid>} */}
							{/* {Data.type === 'Admin' && ( */}
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Password
								</Typography>
								<TextField
									name='password'
									value={Data.password}
									onChange={onChange_Data}
									style={{ width: '100%' }}
									InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
								/>
								{renderErrorMessage('password')}
							</Grid>
							{/* )} */}
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									First Name
								</Typography>
								<TextField
									name='firstName'
									value={Data.firstName}
									onChange={onChange_Data}
									style={{ width: '100%' }}
									InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
								/>
								{renderErrorMessage('firstName')}
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Last Name
								</Typography>
								<TextField
									name='lastName'
									value={Data.lastName}
									onChange={onChange_Data}
									style={{ width: '100%' }}
									InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
								/>
								{renderErrorMessage('lastName')}
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Mobile Number
								</Typography>
								<TextField
									name='phone'
									value={Data.phone}
									onChange={onChange_Data}
									style={{ width: '100%' }}
									InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
								/>
								{renderErrorMessage('phone')}
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Email Address
								</Typography>
								<TextField
									name='email'
									value={Data.email}
									onChange={onChange_Data}
									style={{ width: '100%' }}
									InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
								/>
								{renderErrorMessage('email')}
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Gender
								</Typography>
								<FormControl fullWidth>
									<Select
										name='gender'
										value={Data.gender}
										onChange={onChange_Data}
										label='Gender'
										className='input-style'
									>
										<MenuItem value={'Male'}>Male</MenuItem>
										<MenuItem value={'Female'}>Female</MenuItem>
										<MenuItem value={'Other'}>Other</MenuItem>
									</Select>
								</FormControl>
								{renderErrorMessage('gender')}
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Birth Date
								</Typography>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DesktopDatePicker
										label=''
										inputFormat='MM/dd/yyyy'
										value={Data.birthDate}
										onChange={(newValue: Date | null) => handleChange_Date(newValue, 'birthDate')}
										renderInput={(params) => (
											<TextField sx={{ width: '100%' }} className='input-style' {...params} />
										)}
									/>
								</LocalizationProvider>
								{renderErrorMessage('birthDate')}
							</Grid>
							<Grid item xs={12}>
								<Typography
									variant='body1'
									fontWeight={500}
									display='block'
									gutterBottom
									sx={{ color: '#707070' }}
								>
									Location
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Address
								</Typography>
								<TextField
									name='address'
									value={Data.address}
									onChange={onChange_Data}
									style={{ width: '100%' }}
									InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
								/>
								{renderErrorMessage('address')}
							</Grid>
							<Grid item xs={12}>
								<Typography
									variant='body1'
									fontWeight={500}
									display='block'
									gutterBottom
									sx={{ color: '#707070' }}
								>
									Work Details
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Job Title
								</Typography>
								<TextField
									name='jobTitle'
									value={Data.jobTitle}
									onChange={onChange_Data}
									style={{ width: '100%' }}
									InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
								/>
								{renderErrorMessage('jobTitle')}
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									TMW ID
								</Typography>
								<TextField
									name='tmw_id'
									value={Data.tmw_id}
									onChange={onChange_Data}
									style={{ width: '100%' }}
									InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
								/>
								{renderErrorMessage('tmw_id')}
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Supervisor
								</Typography>
								<FormControl fullWidth>
									<Select
										name='supervisor'
										value={Data.supervisor}
										onChange={onChange_Data}
										label='Supervisor'
										className='input-style'
									>
										{DDL?.Employees?.map((val, i) => (
											<MenuItem key={i} value={`${val.id}`}>
												{val.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								{/* {renderErrorMessage('supervisor')} */}
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Gateways
								</Typography>
								<FormControl fullWidth>
									<Select
										multiple
										name='gates'
										value={Data.gates}
										onChange={onChange_Data}
										label='Gateways'
										className='input-style'
									>
										{DDL?.Gateways?.map((val, i) => (
											<MenuItem key={i} value={`${val.id}`}>
												{val.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								{renderErrorMessage('gates')}
							</Grid>
							<Grid item xs={8}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Mobile Number
								</Typography>
								<TextField
									name='workPhone'
									value={Data.workPhone}
									onChange={onChange_Data}
									style={{ width: '100%' }}
									InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
								/>
								{renderErrorMessage('workPhone')}
							</Grid>
							<Grid item xs={4}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Ex
								</Typography>
								<TextField
									name='extension'
									value={Data.extension}
									onChange={onChange_Data}
									style={{ width: '100%' }}
									InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
								/>
								{renderErrorMessage('extension')}
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Email Address
								</Typography>
								<TextField
									name='workEmail'
									value={Data.workEmail}
									onChange={onChange_Data}
									style={{ width: '100%' }}
									InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
								/>
								{renderErrorMessage('workEmail')}
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Rate
								</Typography>
								<TextField
									name='rate'
									value={Data.rate}
									onChange={onChange_Data}
									type={'number'}
									style={{ width: '100%' }}
									InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
								/>
								{renderErrorMessage('rate')}
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Hire Date
								</Typography>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DesktopDatePicker
										label=''
										inputFormat='MM/dd/yyyy'
										value={Data.hireDate}
										onChange={(newValue: Date | null) => handleChange_Date(newValue, 'hireDate')}
										renderInput={(params) => (
											<TextField sx={{ width: '100%' }} className='input-style' {...params} />
										)}
									/>
								</LocalizationProvider>
								{renderErrorMessage('hireDate')}
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Department
								</Typography>
								<FormControl fullWidth>
									<Select
										name='departments'
										value={Data.departments}
										onChange={onChange_Data}
										multiple
										label='Department'
										className='input-style'
									>
										{DDL?.Department?.map((val, i) => (
											<MenuItem key={i} value={`${val.id}`}>
												{val.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								{renderErrorMessage('departments')}
							</Grid>
							{id && (
								<Grid item xs={12}>
									<Typography variant='subtitle1' gutterBottom component='div'>
										Termination Date
									</Typography>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DesktopDatePicker
											label=''
											inputFormat='MM/dd/yyyy'
											value={Data.terminationDate}
											onChange={(newValue: Date | null) =>
												handleChange_Date(newValue, 'terminationDate')
											}
											renderInput={(params) => (
												<TextField sx={{ width: '100%' }} className='input-style' {...params} />
											)}
										/>
									</LocalizationProvider>
									{renderErrorMessage('terminationDate')}
								</Grid>
							)}
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Vacation Level
								</Typography>
								<FormControl fullWidth>
									<Select
										name='vacationLevel'
										value={Data.vacationLevel}
										onChange={onChange_Data}
										label='VacationLevel'
										className='input-style'
									>
										{DDL.VacationLevel?.map((val, i) => (
											<MenuItem key={i} value={val}>
												{val}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								{renderErrorMessage('vacationLevel')}
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Vacation Type
								</Typography>
								<FormControl fullWidth>
									<Select
										name='vacationType'
										value={Data.vacationType}
										onChange={onChange_Data}
										label='VacationType'
										className='input-style'
									>
										{DDL.VacationType?.map((val, i) => (
											<MenuItem key={i} value={val}>
												{val}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								{renderErrorMessage('vacationType')}
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Sick - Vacation
								</Typography>
								<TextField
									name='sick'
									value={Data.sick}
									onChange={onChange_Data}
									type={'number'}
									style={{ width: '100%' }}
									InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
								/>
								{renderErrorMessage('sick')}
							</Grid>
							<Grid item xs={6}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Casual - Vacation
								</Typography>
								<TextField
									name='casual'
									value={Data.casual}
									onChange={onChange_Data}
									type={'number'}
									style={{ width: '100%' }}
									InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
								/>
								{renderErrorMessage('casual')}
							</Grid>
							<Grid item xs={12}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Note
								</Typography>
								<TextField
									name='note'
									value={Data.note}
									onChange={onChange_Data}
									style={{ width: '100%' }}
									InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
								/>
								{renderErrorMessage('note')}
							</Grid>
							<Grid item xs={12}>
								<LoadingButton
									loading={Loading}
									sx={{ textTransform: 'capitalize', width: '100%' }}
									variant='contained'
									onClick={onSave}
								>
									Save
								</LoadingButton>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={1} />
					<Grid item xs={5}>
						<Box
							component='img'
							sx={{ width: '100%', margin: '60px auto auto', display: 'block' }}
							alt='/'
							src='/images/employees.svg'
						/>
					</Grid>
				</Grid>
			</Box>

			<Snackbar open={OpenMessage} autoHideDuration={6000} onClose={() => setOpenMessage(false)}>
				{IsError ? (
					<Alert onClose={() => setOpenMessage(false)} severity='error' sx={{ width: '100%' }}>
						{alertMessage}
					</Alert>
				) : (
					<Alert onClose={() => setOpenMessage(false)} severity='success' sx={{ width: '100%' }}>
						Request successed.
					</Alert>
				)}
			</Snackbar>
		</>
	);
}

export default NewEmployee;
