import { useState } from 'react';
import { Grid, Box, Stack, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import axios from '../../axiosInstance';
import { API } from '../../api';

function Login() {
	const [loading, setLoading] = useState(false);
	const [IsError, setIsError] = useState(false);
	const [Data, setData] = useState({ email: '', password: '' });
	const onChange = (e: any) => {
		setIsError(false);
		const { name, value } = e.currentTarget;
		setData((prev) => ({ ...prev, [name]: value }));
	};
	const onLogin = () => {
		setLoading(true);
		axios
			.post(API.login, Data)
			.then((res: any) => {
				setLoading(false);
				localStorage.setItem('accessToken', res.data.data.token);
				localStorage.setItem('accessToken', res.data.data.accessToken);
				window.location.href = '/';
			})
			.catch((err) => {
				setLoading(false);
				setIsError(true);
			});
	};
	return (
		<>
			<Box width={1} height={1} p={3} sx={{ margin: '0 auto' }}>
				<Grid container spacing={2}>
					<Grid item xs={5}>
						<Stack spacing={2} p={2} mt={5}>
							<Box component='img' sx={{ width: '224px' }} alt='/' src='/images/logo.png' />
							<Typography variant='h5' gutterBottom component='div'>
								Login
							</Typography>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Email
							</Typography>
							<input name='email' value={Data.email} onChange={onChange} type={'text'} className='input-style' />
							<Typography variant='subtitle1' gutterBottom component='div'>
								Password
							</Typography>
							<input
								name='password'
								value={Data.password}
								onChange={onChange}
								type={'password'}
								className='input-style'
							/>
							{IsError && (
								<Typography sx={{ color: '#DE0030' }} variant='caption' display='block' gutterBottom>
									Invalid authentication username or password.
								</Typography>
							)}
							<LoadingButton
								sx={{ textTransform: 'capitalize', width: '100%' }}
								variant='contained'
								onClick={onLogin}
								loading={loading}
							>
								Login
							</LoadingButton>
						</Stack>
					</Grid>
					<Grid item xs={7}>
						<Box
							component='img'
							sx={{ width: '80%', marginLeft: 'auto', display: 'block' }}
							alt='/'
							src='/images/truck.png'
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default Login;
