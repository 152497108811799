import React, { useRef, useState, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import { Grid, Box, Typography, Button, Divider } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import moment from 'moment';

type PrintProp = {
	Ref: any;
	ReportData: any;
	setDiableReport: React.Dispatch<React.SetStateAction<boolean>>;
	setLoadingReport?: React.Dispatch<React.SetStateAction<boolean>>;
};
type ReportProp = {
	ReportData: any;
	setDiableReport: React.Dispatch<React.SetStateAction<boolean>>;
	DiableReport: boolean;
	LoadingReport?: boolean;
	setLoadingReport?: React.Dispatch<React.SetStateAction<boolean>>;
};
type daysType = {
	day: string;
	date: string;
	_date: string;
	check_in: string;
	check_out: string;
	total_hours: number;
	amount_earned: number;
	num: string;
}[];
type WeeksType = {
	id: number;
	name: string;
	total_hours: number;
	amount_earned: number;
	days: daysType;
}[];

type monthsType = {
	id: string;
	name: string;
	hourly_rate: number;
	total_hours: number;
	amount_earned: number;
	days: daysType;
}[];
type Weeks_Type = {
	days: ({}[] | undefined)[];
	id?: number | undefined;
	name?: string | undefined;
	total_hours?: number | undefined;
	amount_earned?: number | undefined;
}[];
type months_Type = {
	days: ({}[] | undefined)[];
	id?: number | undefined;
	name?: string | undefined;
	total_hours?: number | undefined;
	amount_earned?: number | undefined;
}[];

type DataType = {
	name: string;
	department: string;
	twd_id: string;
	from: string;
	to: string;
	total_hours: number;
	hourly_rate: number;
	amount_earned: number;
	bi_weekly: {} | undefined;
	semi_monthly: {} | undefined;
	weeks: Weeks_Type;
	months: months_Type;
}[];
function ComponentToPrint({ Ref, ReportData, setDiableReport, setLoadingReport }: PrintProp) {
	console.log(ReportData);
	// const [ReportDataFormatted, setReportDataFormatted] = useState<any[] | undefined>([]);
	//console.log('ReportData', ReportData);
	// useEffect(() => {
	//setDiableReport(true);
	// setReportDataFormatted([]);

	function reshape(array: {}[], num: number) {
		let copy_array = [...array];
		let new_array = [];
		while (copy_array.length) new_array.push(copy_array.splice(0, num));
		return new_array;
	}
	// 	const formateData = async () => {
	// 		try {
	// 			let data: {}[] = [];
	// 			ReportData?.forEach((element: any) => {
	// 				let months_formatted: {}[] = [];
	// 				element?.months?.forEach((val: any, i: any) => {
	// 					let part_1 = val?.data[0].data;
	// 					let part_2 = val?.data[1].data;

	// 					if (part_1.length === 0) {
	// 						part_1 = part_2;
	// 						part_2 = [];
	// 					}
	// 					let part1_days_reshape: {}[][] = [];
	// 					let part2_days_reshape: {}[][] = [];
	// 					if (i === 0) {
	// 						if (part_1.length > 12) {
	// 							let first_array = part_1.slice(0, 12); // 13 days cards + header card = 14 cards
	// 							let rest_days = part_1.slice(12) || [];
	// 							part1_days_reshape = [first_array, ...reshape(rest_days, 15)];
	// 						} else if (part_1.length <= 12) {
	// 							part1_days_reshape = [part_1];
	// 						}
	// 					} else {
	// 						if (part_1.length > 15) {
	// 							let first_array = part_1.slice(0, 15); // 13 days cards + header card = 14 cards
	// 							let rest_days = part_1.slice(15) || [];
	// 							part1_days_reshape = [first_array, ...reshape(rest_days, 15)];
	// 						} else if (part_1.length <= 15) {
	// 							part1_days_reshape = [part_1];
	// 						}
	// 					}

	// 					if (part_2.length > 0) {
	// 						part2_days_reshape = reshape(part_2, 15);
	// 					}
	// 					months_formatted.push({
	// 						...val,
	// 						data: [
	// 							{ workingHours: val?.data[0].workingHours, data: part1_days_reshape },
	// 							{ workingHours: val?.data[1].workingHours, data: part2_days_reshape },
	// 						],
	// 					});
	// 				});
	// 				data.push({
	// 					...element,
	// 					months: months_formatted,
	// 				});
	// 			});
	// 			return { data: data };
	// 		} catch (error) {}
	// 	};
	// 	formateData().then((result) => {
	// 		//console.log('result', result?.data);
	// 		//setDiableReport(false);
	// 	});
	// }, [ReportData]);
	// setReportDataFormatted(result?.data);
	//console.log('ReportDataFormatted', ReportDataFormatted);
	function getHours(num: number) {
		let hours = Math.trunc(num / 60);
		let mins = num % 60;
		return `${hours}h ${mins}m`;
	}
	const renderTableHeader: any = () => (
		<Box width={'100%'} sx={{ flexWrap: 'nowrap', display: 'flex' }}>
			{['Date', 'Check In', 'Check Out', 'Lunch Break', 'Total Hours'].map((val, j) => (
				<Box
					key={j}
					height={'48px'}
					mt={'6px'}
					ml={'6px'}
					p={2 / 3}
					sx={{ bgcolor: '#707070', borderRadjus: '2px', color: 'white', flex: '1' }}
				>
					<Typography variant='subtitle1' gutterBottom>
						{val}
					</Typography>
				</Box>
			))}
		</Box>
	);
	const renderLogo = () => (
		<Box height={'122px'}>
			<Box height={'24px'} sx={{ bgcolor: '#fff' }}>
				{' '}
			</Box>
			<Box display={'flex'} height={'98px'} minHeight={'98px'} alignItems={'center'} justifyContent='space-around'>
				<Box height={'70%'} width={'200px'} component='img' src='/images/report_logo.png' />
				<Typography variant='h6' gutterBottom pt={1}>
					DETAIL PAYROLL <br />
					REPORT
				</Typography>
			</Box>
			<Box height={'2px'}>
				<Divider sx={{ borderColor: '#626262', borderWidth: '2px' }} />
			</Box>
		</Box>
	);
	const renderEmployeeInfo = (data_val: any) => (
		<Grid container spacing={1} p={2}>
			<Grid item xs={2} mb={0}>
				<Typography variant='subtitle1' sx={{ color: '#707070' }}>
					&nbsp;Name:
				</Typography>
			</Grid>
			<Grid item xs={4} mb={0}>
				<Typography variant='subtitle2'>{data_val.name}</Typography>
			</Grid>
			<Grid item xs={2} mb={0}>
				<Typography variant='subtitle1' sx={{ color: '#707070' }}>
					&nbsp;Department:
				</Typography>
			</Grid>
			<Grid item xs={4} mb={0}>
				<Typography variant='subtitle2'>{data_val.department}</Typography>
			</Grid>
			<Grid item xs={2} mb={0}>
				<Typography variant='subtitle1' sx={{ color: '#707070' }}>
					&nbsp;From:
				</Typography>
			</Grid>
			<Grid item xs={4} mb={0}>
				<Typography variant='subtitle2'>{data_val.from}</Typography>
			</Grid>
			<Grid item xs={2} mb={0}>
				<Typography variant='subtitle1' sx={{ color: '#707070' }}>
					&nbsp;To:
				</Typography>
			</Grid>
			<Grid item xs={4} mb={0}>
				<Typography variant='subtitle2'>{data_val.to}</Typography>
			</Grid>
			<Grid item xs={4}>
				<Box p={1} pr={2} height={1} sx={{ marginLeft: 'auto', backgroundColor: '#F1F1F1', borderRadius: '8px' }}>
					<Typography variant='subtitle2' gutterBottom>
						TMW ID
					</Typography>
					<Typography variant='h6' gutterBottom fontWeight={600}>
						{data_val.twd_id}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={4}>
				<Box p={1} pr={2} height={1} sx={{ marginLeft: 'auto', backgroundColor: '#F1F1F1', borderRadius: '8px' }}>
					<Typography variant='subtitle2' gutterBottom>
						Total Hours
					</Typography>
					<Typography variant='h6' gutterBottom fontWeight={600}>
						{getHours(data_val.total_hours)}
					</Typography>
				</Box>
			</Grid>
		</Grid>
	);
	const renderFromTo = (from: any, to: any) => (
		<Box mt={'8px'} ml={'8px'}>
			<Grid container spacing={1} p={1}>
				<Grid item xs={2} mb={0}>
					<Typography variant='subtitle1' sx={{ color: '#707070' }}>
						&nbsp;From:
					</Typography>
				</Grid>
				<Grid item xs={4} mb={0}>
					<Typography variant='subtitle2'>{moment(from).format('DD/MM/YYYY')}</Typography>
				</Grid>
				<Grid item xs={2} mb={0}>
					<Typography variant='subtitle1' sx={{ color: '#707070' }}>
						&nbsp;To:
					</Typography>
				</Grid>
				<Grid item xs={4} mb={0}>
					<Typography variant='subtitle2'>{moment(to).format('DD/MM/YYYY')}</Typography>
				</Grid>
			</Grid>
		</Box>
	);
	const renderDay = (day: any) => (
		<Box width={'100%'} sx={{ flexWrap: 'nowrap', display: 'flex' }}>
			<Box
				display={'flex'}
				flexDirection='column'
				justifyContent={'center'}
				height={'48px'}
				mt={'8px'}
				ml={'8px'}
				p={2 / 3}
				sx={{ bgcolor: '#F2F2F2', borderRadjus: '2px', flex: '1' }}
			>
				<Typography variant='body2' gutterBottom>
					{day?.dayString || '--'} <br /> {day?.dayDate || ''}
				</Typography>
			</Box>
			<Box
				display={'flex'}
				flexDirection='column'
				justifyContent={'center'}
				mt={'8px'}
				ml={'8px'}
				p={2 / 3}
				sx={{ bgcolor: '#F2F2F2', borderRadjus: '2px', flex: '1' }}
			>
				<Typography variant='body2' gutterBottom>
					{day?.checkIn || '--:--'}
				</Typography>
			</Box>
			<Box
				display={'flex'}
				flexDirection='column'
				justifyContent={'center'}
				mt={'8px'}
				ml={'8px'}
				p={2 / 3}
				sx={{ bgcolor: '#F2F2F2', borderRadjus: '2px', flex: '1' }}
			>
				<Typography variant='body2' gutterBottom>
					{day?.checkOut || '--:--'}
				</Typography>
			</Box>
			<Box
				display={'flex'}
				flexDirection='column'
				justifyContent={'center'}
				mt={'8px'}
				ml={'8px'}
				p={2 / 3}
				sx={{ bgcolor: '#F2F2F2', borderRadjus: '2px', flex: '1' }}
			>
				<Typography variant='body2' gutterBottom>
					{`${day?.lunchBreak ? day?.lunchBreak + 'm' : '--'}`}
				</Typography>
			</Box>
			<Box
				display={'flex'}
				flexDirection='column'
				justifyContent={'center'}
				mt={'8px'}
				ml={'8px'}
				p={2 / 3}
				sx={{ bgcolor: '#F2F2F2', borderRadjus: '2px', flex: '1' }}
			>
				<Typography variant='body2' gutterBottom>
					{getHours((day?.workingHours || 0) + (day?.lunchBreak || 0))}
					{/* {getHours(day?.total_hours / 60 > 5 ? day?.total_hours - 30 : day?.total_hours)} */}
				</Typography>
			</Box>
			{/* <Box
				display={'flex'}
				flexDirection='column'
				justifyContent={'center'}
				mt={'6px'}
				ml={'6px'}
				p={2 / 3}
				sx={{ bgcolor: '#F2F2F2', borderRadjus: '2px', flex: '1' }}
			>
				<Typography variant='body2' gutterBottom>
					$ {day?.amount_earned.toFixed(2)}
				</Typography>
			</Box> */}
		</Box>
	);
	const renderSemiMonthTotal = (total_hours: number) => (
		<Box
			display='flex'
			alignItems={'center'}
			height={'48px'}
			mt={'6px'}
			ml={'6px'}
			p={2 / 3}
			sx={{ bgcolor: '#C3C3C3', borderRadjus: '2px', flex: '1', width: '100%' }}
		>
			<Typography sx={{ width: '40%' }} variant='subtitle2'>
				Semi Monthly Total Hours: {getHours(total_hours)}
			</Typography>
			{/* <Typography sx={{ width: '50%' }} variant='subtitle2'>
				Week Amount Earned: $ {amount_earned.toFixed(2)}
			</Typography> */}
		</Box>
	);
	const renderMonthTotal = (total_hours: number) => (
		<Box
			display='flex'
			alignItems={'center'}
			height={'48px'}
			mt={'6px'}
			ml={'6px'}
			p={2 / 3}
			sx={{ bgcolor: '#C3C3C3', borderRadjus: '2px', flex: '1', width: '100%' }}
		>
			<Typography sx={{ width: '40%' }} variant='subtitle2'>
				Month Total Hours: {getHours(total_hours)}
			</Typography>
			{/* <Typography sx={{ width: '50%' }} variant='subtitle2'>
				Week Amount Earned: $ {amount_earned.toFixed(2)}
			</Typography> */}
		</Box>
	);

	return (
		<Box ref={Ref} ml={'24px'} mr={'24px'}>
			{ReportData?.map((val: any, i: number) => {
				let firstPage = true;
				return (
					<React.Fragment key={i}>
						{val?.months?.map((month: any, j: any) => (
							<React.Fragment key={j}>
								{month.data.map((semiMonthlyData: any, semiMonthlyPart: number, semiMonthlyDataArr: any) => {
									if (semiMonthlyData.data.length === 0) return <></>;
									let currentRow = 0;
									const currentData: any = [];

									if (firstPage) {
										firstPage = false;
										currentData.push(semiMonthlyData.data.slice(0, 11));
										currentRow += 11;
									}

									const numberOfRows = 13;
									for (let index = currentRow; index < semiMonthlyData.data.length; index += numberOfRows) {
										currentData.push(semiMonthlyData.data.slice(currentRow, currentRow + numberOfRows));
										currentRow += numberOfRows;
									}

									return currentData.map((days: any, index: number) => {
										return (
											<>
												<Box height={'1122px'}>
													{renderLogo()}
													{j === 0 &&
														index === 0 &&
														((semiMonthlyDataArr[0].data.length === 0 && semiMonthlyPart === 1) ||
															semiMonthlyPart === 0) &&
														renderEmployeeInfo(val)}
													{renderFromTo(days[0]?.date, days[days.length - 1]?.date)}
													{renderTableHeader()}

													{days.map((day: any, i: number) => (
														<React.Fragment key={i}>{renderDay(day)}</React.Fragment>
													))}

													{index === currentData.length - 1 &&
														renderSemiMonthTotal(semiMonthlyData?.workingHours as number)}

													{/** total month */}
													{((semiMonthlyDataArr[1].data.length === 0 && semiMonthlyPart === 0) ||
														semiMonthlyPart === 1) &&
														index === currentData.length - 1 &&
														renderMonthTotal(month?.workingHours)}
												</Box>
											</>
										);
									});
								})}
							</React.Fragment>
						))}
					</React.Fragment>
				);
			})}
		</Box>
	);
}
function Report({ ReportData, DiableReport, setDiableReport, LoadingReport, setLoadingReport }: ReportProp) {
	//console.log('Report Comp', ReportData);
	let compRef = useRef<React.ClassAttributes<HTMLDivElement>>(null);
	//const [DiableReport, setDiableReport] = useState(true);

	return (
		<>
			<ReactToPrint
				trigger={() => {
					// NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
					// to the root node of the returned component as it will be overwritten.
					//return <a href='#'>Print this out!</a>;
					return (
						<LoadingButton
							loading={LoadingReport}
							disabled={DiableReport}
							sx={{ textTransform: 'capitalize' }}
							variant='contained'
						>
							Print
						</LoadingButton>
					);
				}}
				content={() => compRef as any}
			/>
			<Box
				sx={{
					display: 'none',
				}}
			>
				<ComponentToPrint
					setDiableReport={setDiableReport}
					setLoadingReport={setLoadingReport}
					ReportData={ReportData}
					Ref={(el: any) => (compRef = el)}
				/>
			</Box>
		</>
	);
}

export default Report;
