import React, { useState, useEffect, useRef } from 'react';
import { Grid, Box, Typography, FormControl, Select, MenuItem, Chip, Snackbar } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useParams, useNavigate } from 'react-router-dom';
import Joi from 'joi';

import axios from '../../axiosInstance';
import { API } from '../../api';
import MapComp from './Map';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
function NewGateway() {
	let { id } = useParams();
	let navigate = useNavigate();
	const [Employees, setEmployees] = React.useState<string[]>([]);
	const [Loading, setLoading] = useState(false);
	const [OpenMessage, setOpenMessage] = useState(false);
	const [IsError, setIsError] = useState(false);
	const [Data, set_Data] = useState<{
		name: string;
		polygon: any[];
		circle:
			| {
					longitude: number;
					latitude: number;
					radius: number; //  in meters
			  }
			| undefined;
	}>({
		//timezone: '',
		name: '',
		polygon: [],
		circle: undefined,
	});
	const [EmployeesDDL, setEmployeesDDL] = useState<{ name: string; id: object }[]>([]);
	const [Errors, setErrors] = useState<any>({});
	const [MapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
	const [MapZoom, setMapZoom] = useState(1.5);
	const [ShowShape, setShowShape] = useState(true);
	let polygonCoordsArray: any = [];
	let circleInfo:
		| {
				longitude: number;
				latitude: number;
				radius: number; //  in meters
		  }
		| undefined = undefined;

	//const setShowShapeRef = useRef<any>(setShowShape);

	let save_schema = Joi.object({
		name: Joi.string().required(),
		longitude: Joi.number().min(-180).max(180),
		latitude: Joi.number().min(-90).max(90),
		employees: Joi.array(),
	});
	let update_schema = Joi.object({
		name: Joi.string(),
		longitude: Joi.number().min(-180).max(180),
		latitude: Joi.number().min(-90).max(90),
		employees: Joi.array(),
	});

	useEffect(() => {
		if (id) {
			axios.get(`${API.gate.gate}/${id}`).then((res) => {
				set_Data({
					name: res?.data?.data?.name,
					polygon: res?.data?.data?.polygon
						? res?.data?.data?.polygon.map((val: any) => ({
								lng: val[0],
								lat: val[1],
						  }))
						: [],
					circle: res?.data?.data?.circle ? res?.data?.data?.circle : undefined,
				});
				setEmployees(res?.data?.data?.employees.map((val: any) => val.id));
				if (res?.data?.data?.address?.latitude && res?.data?.data?.address?.longitude) {
					setMapZoom(5);
				}
				setMapCenter({
					lat: res?.data?.data?.address?.latitude ? res?.data?.data?.address?.latitude : 0,
					lng: res?.data?.data?.address?.longitude ? res?.data?.data?.address?.longitude : 0,
				});
			});
		}
		axios
			.get(API.user.basic, {
				params: {
					total: true,
					isActive: true,
				},
			})
			.then((res) => {
				let data: { name: string; id: object }[] = res?.data?.data.map((val: any) => ({
					name: `${val?.firstName} ${val?.lastName}`,
					id: val?.id,
				}));
				setEmployeesDDL([...data]);
			})
			.catch((err) => {
				setEmployeesDDL([]);
			});
	}, [id]);

	const handleChange_Employees = (event: SelectChangeEvent<typeof Employees>) => {
		const {
			target: { value },
		} = event;
		setEmployees(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value
		);
	};
	const validateFunc = (data: any) => {
		let schema = id ? update_schema : save_schema;
		schema
			.validateAsync(data)
			.then((val) => {
				//console.log('val', val);
				setErrors({});
			})
			.catch((err) => {
				//console.log('err', err.details);
				setErrors((prev: any) => ({
					[err.details[0].context.label]: err.details[0].message,
				}));
				//throw new Error('Failed to validate input ' + err.details[0].message);
			});
	};
	const onChange_Data = (e: any) => {
		const { name, value } = e.target;
		set_Data((prev) => ({
			...prev,
			[name]: value,
		}));
		validateFunc({
			[name]: value,
		});
	};
	function getUserName(id: any) {
		let findUser = EmployeesDDL.find((val: any) => val?.id === id);
		return findUser ? findUser?.name : '';
	}

	const getCoords = (polygon: any) => {
		// For Polygon Shape
		polygonCoordsArray = [];
		let coords = polygon.getPath().getArray();
		//console.log(coords);

		for (let i = 0; i < coords.length; i++) {
			// console.log(coords[i].lat() + "," + coords[i].lng());
			polygonCoordsArray.push([coords[i].lng(), coords[i].lat()]);
		}
		//console.log('coords', polygonCoordsArray);
		//setShowShapeRef?.current(false);
	};
	const getCircle = (circle: any) => {
		circleInfo = {
			longitude: circle.center.lng(),
			latitude: circle.center.lat(),
			radius: Math.round(circle.radius), //  in meters
		};
	};
	const onSave = () => {
		setLoading(true);
		let requestBody = {
			//...Data,
			name: Data.name,
			employees: Employees,
			circle: circleInfo,
			// polygon:
			// 	polygonCoordsArray?.length > 3
			// 		? [...polygonCoordsArray, polygonCoordsArray[0]].filter((val: any) => val !== null)
			// 		: undefined,
		};
		let request = id ? axios.patch(`${API.gate.gate}/${id}`, requestBody) : axios.post(API.gate.gate, requestBody);
		request
			.then((res) => {
				navigate('/gateways');
			})
			.catch((err) => {
				setErrors({});
				setLoading(false);
				setIsError(true);
				if (err.response.data.args) {
					err.response.data.args.map((val: any) => {
						setErrors((prev: any) => ({
							...prev,
							[val.path.split('.')[1]]: val.message.split('.')[1],
						}));
					});
				}
				setOpenMessage(true);
			});
	};
	return (
		<>
			<Box height={'100%'} p={3}>
				<Grid container spacing={2} height={'100%'}>
					<Grid item xs={6} pb={5}>
						<Grid container item spacing={3}>
							<Grid item xs={12}>
								<Typography variant='h5' gutterBottom fontWeight={'600'}>
									{id ? 'Update Gateway' : 'Add New Gateway'}
								</Typography>
								<Typography variant='subtitle2' gutterBottom sx={{ color: '#707070' }}>
									Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
									been the industry's standard dummy text ever since the 1500s,
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Name
								</Typography>
								<input
									name='name'
									value={Data.name}
									onChange={onChange_Data}
									type={'text'}
									className='input-style'
									style={{ width: '100%' }}
								/>
								{Errors?.name && (
									<Typography variant='caption' style={{ color: '#FE0000' }} gutterBottom component='div'>
										{Errors?.name}
									</Typography>
								)}
							</Grid>
							<Grid item xs={12}>
								<Typography variant='subtitle1' gutterBottom component='div'>
									Employees
								</Typography>
								<FormControl fullWidth>
									<Select
										multiple
										label='Employees'
										//className='input-style'
										value={Employees}
										onChange={handleChange_Employees}
										fullWidth
										sx={{
											height: '62px',
											backgroundColor: '#f2f2f2',
											border: '1px solid #f2f2f2 !important',
											borderRadius: '6px',
										}}
									>
										{EmployeesDDL.map((val, i) => (
											<MenuItem key={i} value={`${val.id}`}>
												{val.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							{Employees.map((val) => (
								<Grid item xs={4}>
									<Chip
										sx={{ width: '100%', bgcolor: '#707070', color: '#FFFFFF' }}
										label={getUserName(val)}
										onClick={() => {}}
										onDelete={() => {
											let employees = [...Employees].filter((ele) => ele !== val);
											setEmployees([...employees]);
										}}
										deleteIcon={<CloseIcon sx={{ color: '#ffffff !important' }} />}
									/>
								</Grid>
							))}
							<Grid item xs={12}>
								<Typography variant='h6' gutterBottom>
									Geofence
								</Typography>
								<MapComp
									MapCenter={MapCenter}
									MapZoom={MapZoom}
									getCoords={getCoords}
									getCircle={getCircle}
									polygonPaths={Data.polygon}
									circleInfo={Data.circle}
									drawingControl={true}
									ShowShape={ShowShape}
									setShowShape={setShowShape}
									hideDrawingControl={false}
								/>
							</Grid>
							<Grid item xs={12}>
								<LoadingButton
									loading={Loading}
									sx={{ textTransform: 'capitalize', width: '100%' }}
									variant='contained'
									onClick={onSave}
									//disabled={Object.keys(Errors).length > 0}
								>
									Save
								</LoadingButton>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={1} />
					<Grid item xs={5}>
						<Box
							component='img'
							sx={{ width: '570px', margin: '60px auto auto', display: 'block' }}
							alt='/'
							src='/images/gateway.svg'
						/>
					</Grid>
				</Grid>
			</Box>
			<Snackbar open={OpenMessage} autoHideDuration={6000} onClose={() => setOpenMessage(false)}>
				{IsError ? (
					<Alert onClose={() => setOpenMessage(false)} severity='error' sx={{ width: '100%' }}>
						Request failed!
					</Alert>
				) : (
					<Alert onClose={() => setOpenMessage(false)} severity='success' sx={{ width: '100%' }}>
						Request succeeded.
					</Alert>
				)}
			</Snackbar>
		</>
	);
}

export default NewGateway;
