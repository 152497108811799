import React, { useState, useEffect } from 'react';
import { Stack, Box, Typography, Dialog, Snackbar, Skeleton, Tooltip } from '@mui/material';
import { DataGridPremium, useGridApiRef, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import moment from 'moment';
import ConfirmDialog from '../../../Components/ConfirmDialog';

import axios from '../../../axiosInstance';
import { API } from '../../../api';
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
function CustomToolbar() {
	return (
		<GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
			<GridToolbarExport printOptions={{ disableToolbarButton: true }} excelOptions={{ disableToolbarButton: true }} />
		</GridToolbarContainer>
	);
}
type VacationProps = {
	SelectedUsers: any[];
	SelectedStatus: any[];
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
};
function Vacation({ SelectedUsers, SelectedStatus, page, setPage }: VacationProps) {
	const [Vacations, setVacations] = useState([]);
	const apiRef = useGridApiRef();
	const [totalRecords, settotalRecords] = useState(0);
	const [pageSize, setPageSize] = React.useState<number>(20);
	//const [page, setPage] = React.useState(0);
	const [openReject, setOpenReject] = React.useState(false);
	const [openApprove, setOpenApprove] = React.useState(false);
	const [RowData, setRowData] = useState<any>();
	const [OpenMessage, setOpenMessage] = useState(false);
	const [IsError, setIsError] = useState(false);
	const [OpenDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [RefreshData, setRefreshData] = useState(false);
	const [Loading, setLoading] = useState(false);
	const [OpenReasonDialog, setOpenReasonDialog] = useState(false);
	useEffect(() => {
		setLoading(true);
		setVacations([]);
		axios
			.get(API.vacation.get, {
				params: {
					total: true,
					skip: page * (pageSize === undefined ? 0 : pageSize),
					limit: pageSize,
					status: SelectedStatus,
					employee:
						SelectedUsers.length > 0
							? SelectedUsers.map((val: { id: object; label: string }) => val?.id)
							: undefined,
				},
			})
			.then((res) => {
				settotalRecords(res?.data?.totalRecords);
				let data = res?.data?.data?.map((val: any) => ({
					id: val?.id,
					name: `${val?.employee?.firstName} ${val?.employee?.lastName}`,
					img: val?.employee?.avatar?.url || '/images/error_img.jpg',
					from: moment(val?.from).format('DD-MM-YYYY, hhA'),
					to: moment(val?.to).format('DD-MM-YYYY, hhA'),
					status: val?.status,
					reason: val?.reason ? val?.reason : '',
					type: val?.type,
					department: val?.department?.name,
					align: 'center',
				}));
				setVacations(data);
				//apiRef.current.rows = data;
				setLoading(false);
			})
			.catch((err) => {
				setVacations([]);
				setLoading(false);
			});
	}, [pageSize, page, RefreshData, SelectedUsers, SelectedStatus]);
	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Name',
			align: 'left',
			headerAlign: 'center',
			flex: 2,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header left-corner',
			renderCell: (params: any) => {
				return (
					<>
						<Stack direction='row' spacing={2} justifyContent='flex-start' alignItems='center'>
							<img
								src={params?.row?.img}
								width='50px'
								height={'30px'}
								onLoad={() => {}}
								onError={(e) => {
									e.currentTarget.onerror = null; // prevents looping
									e.currentTarget.src = '/images/error_img.jpg';
								}}
							/>
							<Typography variant='subtitle2' gutterBottom sx={{ width: '70%', color: '#000' }}>
								{params?.row?.name}
							</Typography>
						</Stack>
					</>
				);
			},
		},
		{
			field: 'department',
			headerName: 'Department',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'from',
			headerName: 'From',
			align: 'center',
			headerAlign: 'center',
			flex: 2,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'to',
			headerName: 'To',
			align: 'center',
			headerAlign: 'center',
			flex: 2,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'reason',
			headerName: 'Reason',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
			renderCell: (params: any) => {
				return (
					<>
						<Tooltip title={params?.row?.reason}>
							<Typography variant='body2' gutterBottom onClick={() => setOpenReasonDialog(true)}>
								{params?.row?.reason?.length <= 15
									? params?.row?.reason
									: `${params?.row?.reason?.substring(0, 15)}...`}
							</Typography>
						</Tooltip>
					</>
				);
			},
		},
		{
			field: 'type',
			headerName: 'Type',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'status',
			headerName: 'Status',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'action',
			headerName: 'Actions',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			disableExport: true,
			headerClassName: 'table-header right-corner',
			renderCell: (params: any) => (
				<Box>
					{params.row.status === 'Pending' && (
						<CheckCircleIcon sx={{ color: '#007F5F', cursor: 'pointer' }} onClick={() => setOpenApprove(true)} />
					)}
					{params.row.status === 'Pending' && (
						<CancelIcon sx={{ color: '#FE0000', cursor: 'pointer' }} onClick={() => setOpenReject(true)} />
					)}
					<DeleteIcon sx={{ cursor: 'pointer' }} onClick={() => setOpenDeleteDialog(true)} />
				</Box>
			),
		},
	];
	const onAccept = () => {
		axios
			.post(`${API.vacation.get}/${RowData?.id}/accept`)
			.then((res) => {
				setIsError(false);
				setOpenApprove(false);
				setOpenMessage(true);
				setRefreshData(!RefreshData);
			})
			.catch((err) => {
				setIsError(true);
				setOpenApprove(false);
				setOpenMessage(true);
			});
	};
	const onReject = () => {
		axios
			.post(`${API.vacation.get}/${RowData?.id}/reject`)
			.then((res) => {
				setIsError(false);
				setOpenReject(false);
				setOpenMessage(true);
				setRefreshData(!RefreshData);
			})
			.catch((err) => {
				setIsError(true);
				setOpenReject(false);
				setOpenMessage(true);
			});
	};
	const onDelete = () => {
		axios
			.delete(`${API.vacation.get}/${RowData?.id}`)
			.then((res) => {
				setOpenDeleteDialog(false);
				setIsError(false);
				setOpenMessage(true);
				setRefreshData(!RefreshData);
			})
			.catch((err) => {
				setOpenDeleteDialog(false);
				setIsError(true);
				setOpenMessage(true);
			});
	};
	return (
		<>
			<Stack spacing={2} p={3} height={1}>
				{Loading && <Skeleton variant='rectangular' width={'100%'} height={118} />}
				{Vacations.length > 0 && !Loading && (
					<Box
						height={1}
						sx={{
							'& .table-header': {
								backgroundColor: '#dadada',
							},
							'& .left-corner': {
								borderTopLeftRadius: '15px',
							},
							'& .right-corner': {
								borderTopRightRadius: '15px',
							},
							'& .css-f3jnds-MuiDataGrid-columnHeaders': {
								borderTopRightRadius: '15px !important',
								borderTopLeftRadius: '15px !important',
								background: '#dadada !important',
							},
						}}
					>
						<DataGridPremium
							className='custom-grid-class'
							onRowClick={(rowData) => setRowData(rowData?.row)}
							columns={columns}
							rows={[...Vacations] || []}
							apiRef={apiRef}
							disableSelectionOnClick
							rowCount={totalRecords}
							paginationMode='server'
							pagination
							page={page}
							onPageChange={(newPage) => setPage(newPage)}
							pageSize={pageSize}
							onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50]}
							disableColumnResize
							disableColumnReorder
							disableChildrenSorting
							components={{
								Toolbar: CustomToolbar,
							}}
						/>
					</Box>
				)}
				{Vacations.length === 0 && !Loading && (
					<Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
						<Typography variant='h5' gutterBottom fontWeight={'500'}>
							No Vacations Yet!
						</Typography>
					</Stack>
				)}
			</Stack>

			{/** Approve */}
			<ConfirmDialog
				open={openApprove}
				onClose={() => setOpenApprove(false)}
				title='Approve Vacation'
				message={`Are you sure you want to approve this vacation from ${RowData?.name}`}
				cancelLabel='Close'
				saveLabel='Approve'
				onApply={onAccept}
			/>
			{/** Reject */}
			<ConfirmDialog
				open={openReject}
				onClose={() => setOpenReject(false)}
				title='Reject Vacation'
				message={`Are you sure you want to reject this vacation from ${RowData?.name}`}
				cancelLabel='Close'
				saveLabel='Reject'
				onApply={onReject}
			/>
			{/** Delete */}
			<ConfirmDialog
				open={OpenDeleteDialog}
				onClose={() => setOpenDeleteDialog(false)}
				title=''
				message='Are you sure you want to delete this vacation?'
				cancelLabel='Close'
				saveLabel='Delete'
				onApply={onDelete}
			/>
			<Dialog
				open={OpenReasonDialog}
				keepMounted
				onClose={() => setOpenReasonDialog(false)}
				aria-describedby='alert-dialog-slide-description'
			>
				<Box p={2} sx={{ width: '30vw' }}>
					<Typography variant='h6' gutterBottom>
						{RowData?.reason}
					</Typography>
				</Box>
			</Dialog>
			<Snackbar open={OpenMessage} autoHideDuration={6000} onClose={() => setOpenMessage(false)}>
				{IsError ? (
					<Alert onClose={() => setOpenMessage(false)} severity='error' sx={{ width: '100%' }}>
						Request failed!
					</Alert>
				) : (
					<Alert onClose={() => setOpenMessage(false)} severity='success' sx={{ width: '100%' }}>
						Request succeeded.
					</Alert>
				)}
			</Snackbar>
		</>
	);
}

export default Vacation;
