import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Stack, Typography, Grid, Autocomplete, TextField } from '@mui/material';

import axios from '../../axiosInstance';
import { API } from '../../api';
import Attendances from './Attendances';
import Vacation from './Vacation';

function Requests() {
	const [SelectedUsers, setSelectedUsers] = useState([]);
	const [EmployeesDDL, setEmployeesDDL] = useState<{ name: string; id: object }[]>([]);
	const [StatusDDl, setStatusDDl] = useState<string[]>([]);
	const [SelectedStatus, setSelectedStatus] = useState(['Pending']);
	const [value, setValue] = React.useState(0);
	const [page, setPage] = React.useState(0);

	useEffect(() => {
		axios
			.get(API.user.basic, {
				params: {
					total: true,
					isActive: true,
				},
			})
			.then((res) => {
				let data: { name: string; id: object }[] = res?.data?.data.map((val: any) => ({
					label: `${val?.firstName} ${val?.lastName}`,
					id: val?.id,
				}));
				setEmployeesDDL([...data]);
			})
			.catch((err) => {
				setEmployeesDDL([]);
			});
		axios
			.get(API.vacation.metadata)
			.then((res) => {
				if (res?.data?.data?.STATUSES) setStatusDDl(res?.data?.data?.STATUSES);
			})
			.catch((err) => {
				setStatusDDl([]);
			});
	}, []);
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
		setPage(0);
	};
	return (
		<>
			<Stack spacing={2} p={3} height={1}>
				<Box height={1 / 10}>
					<Grid container spacing={2}>
						<Grid item xs={2}>
							<Typography variant='h5' gutterBottom fontWeight={'600'}>
								Requests
							</Typography>
						</Grid>
						<Grid item xs={5}>
							<Autocomplete
								disablePortal
								multiple
								fullWidth
								options={EmployeesDDL}
								sx={{
									'& input': {
										height: '25px',
									},
								}}
								value={SelectedUsers}
								onChange={(event: any, newValue: any) => {
									setSelectedUsers(newValue);
									setPage(0);
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										//id='search-icon'
										style={{ width: '100%' }}
										//type='text'
										//className='input-style'
										label='Search by Employee'
									/>
								)}
							/>
						</Grid>
						{value === 1 && (
							<Grid item xs={5}>
								<Autocomplete
									disablePortal
									multiple
									fullWidth
									options={StatusDDl}
									sx={{
										'& input': {
											height: '25px',
										},
									}}
									value={SelectedStatus}
									onChange={(event: any, newValue: any) => {
										setSelectedStatus(newValue);
										setPage(0);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											//id='search-icon'
											style={{ width: '100%' }}
											//type='text'
											//className='input-style'
											label='Status'
										/>
									)}
								/>
							</Grid>
						)}
					</Grid>
				</Box>
				<Box height={9 / 10}>
					<Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
						<Tab label='Attendance' />
						<Tab label='Vacations' />
					</Tabs>
					{value === 0 && <Attendances SelectedUsers={SelectedUsers} page={page} setPage={setPage} />}
					{value === 1 && (
						<Vacation
							SelectedUsers={SelectedUsers}
							SelectedStatus={SelectedStatus}
							page={page}
							setPage={setPage}
						/>
					)}
				</Box>
			</Stack>
		</>
	);
}

export default Requests;
