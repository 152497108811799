import React, { useState, useEffect } from 'react';
import {
	Stack,
	Box,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Snackbar,
	Skeleton,
	Tooltip,
	Grid,
	TextField,
	IconButton,
	Modal,
} from '@mui/material';
import { DataGridPremium, useGridApiRef, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import ConfirmDialog from '../../../Components/ConfirmDialog';
import axios from '../../../axiosInstance';
import { API } from '../../../api';
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
function CustomToolbar() {
	return (
		<GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
			<GridToolbarExport printOptions={{ disableToolbarButton: true }} excelOptions={{ disableToolbarButton: true }} />
		</GridToolbarContainer>
	);
}
type AttendancesProps = {
	SelectedUsers: any[];
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
};
function Attendances({ SelectedUsers, page, setPage }: AttendancesProps) {
	const [Requests, setRequests] = useState([]);
	const apiRef = useGridApiRef();
	const [totalRecords, settotalRecords] = useState(0);
	const [pageSize, setPageSize] = React.useState<number>(20);
	//const [page, setPage] = React.useState(0);
	const [openReject, setOpenReject] = React.useState(false);
	const [openApprove, setOpenApprove] = React.useState(false);
	const [OpenUpdate, setOpenUpdate] = useState(false);
	const [RowData, setRowData] = useState<any>({});
	const [OpenMessage, setOpenMessage] = useState(false);
	const [IsError, setIsError] = useState(false);
	const [OpenDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [RefreshData, setRefreshData] = useState(false);
	const [Loading, setLoading] = useState(false);
	const [OpenCheckInImgDialog, setOpenCheckInImgDialog] = useState(false);
	const [OpenCheckOutImgDialog, setOpenCheckOutImgDialog] = useState(false);
	const [validUpdateDateMessage, setValidUpdateDateMessage] = useState('');
	const [DisabledUpdateDate, setDisabledUpdateDate] = useState(false);
	const [UpdateDataAttendance, setUpdateDataAttendance] = useState({
		day: null,
		checkIn: null,
		checkOut: null,
		reason: undefined,
	});
	const [OpenInfoDialog, setOpenInfoDialog] = useState(false);
	const [OpenChildModule, setOpenChildModule] = useState(false);
	const [Img, setImg] = useState('');

	const InfoData = [
		{ label: 'Name', field: 'name' },
		{ label: 'Requested At', field: 'requested_at' },
		{ label: 'Department', field: 'department' },
		{ label: 'Status', field: 'status' },
		{ label: 'Check In', field: 'check_in' },
		{ label: 'Check Out', field: 'check_out' },
		{ label: 'Type', field: 'type' },
		{ label: 'Type', field: 'ckeck_out_type' },
		{ label: 'Photo', field: 'photoCheckIn' },
		{ label: 'Photo', field: 'photoCheckOut' },
		{ label: 'Reason', field: 'reason' },
	];
	const style = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 2,
		px: 4,
		pb: 3,
	};
	//console.log(moment().diff(moment().add(1, 'day'), 'days'));
	useEffect(() => {
		setLoading(true);
		axios
			.get(API.attendance.attendance, {
				params: {
					total: true,
					skip: page * (pageSize === undefined ? 0 : pageSize),
					limit: pageSize,
					status: 'Pending',
					employee:
						SelectedUsers.length > 0
							? SelectedUsers.map((val: { id: object; label: string }) => val?.id)
							: undefined,
					// isRequest: true,
				},
			})
			.then((res) => {
				settotalRecords(res?.data?.totalRecords);
				let data = res?.data?.data.map((val: any) => ({
					id: val?.id,
					name: `${val?.employee?.firstName} ${val?.employee?.lastName}`,
					img: val?.employee?.avatar?.url,
					check_in: val?.checkIn?.at
						? moment(val?.checkIn?.at).diff(val?.checkOut?.at, 'days') === 0
							? moment(val?.checkIn?.at).format('hh:mm a')
							: moment(val?.checkIn?.at).format('DD-MM-YYYY, h:mm a')
						: '--:--',
					check_out: val?.checkOut?.at
						? moment(val?.checkIn?.at).diff(val?.checkOut?.at, 'days') === 0
							? moment(val?.checkOut?.at).format('hh:mm a')
							: moment(val?.checkOut?.at).format('DD-MM-YYYY, h:mm a')
						: '--:--',
					check_in_date: val?.checkIn?.at ? val?.checkIn?.at : undefined,
					check_out_date: val?.checkOut?.at ? val?.checkOut?.at : undefined,
					department: val?.employee?.department?.name || '',
					reason: val?.reason,
					type: val?.checkIn.type,
					photoCheckIn: val?.checkIn?.photo?.url || '/images/error_img.jpg',
					ckeck_out_type: val?.checkOut?.type,
					photoCheckOut: val?.checkOut?.photo?.url || '/images/error_img.jpg',
					requested_at: moment(val?.requestedAt).format('DD-MM-YYYY, h:mm a'),
					status: val?.status,
					align: 'center',
				}));
				setRequests(data);
				setLoading(false);
			})
			.catch((err) => {
				setRequests([]);
				setLoading(false);
			});
	}, [pageSize, page, RefreshData, SelectedUsers]);
	useEffect(() => {
		if (UpdateDataAttendance.checkIn === null || UpdateDataAttendance.day === null) {
			setDisabledUpdateDate(true);
		} else {
			setDisabledUpdateDate(false);
		}
	}, [UpdateDataAttendance]);
	const columns: GridColDef[] = [
		{
			field: 'requested_at',
			headerName: 'Requested At',
			align: 'center',
			headerAlign: 'center',
			flex: 2,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header left-corner',
			renderCell: (params: any) => {
				return (
					<>
						<Typography variant='body1' gutterBottom onClick={() => setOpenInfoDialog(true)}>
							{params?.row?.requested_at}
						</Typography>
					</>
				);
			},
		},
		{
			field: 'name',
			headerName: 'Name',
			align: 'center',
			headerAlign: 'center',
			flex: 2,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
			renderCell: (params: any) => {
				return (
					<>
						<Typography
							variant='subtitle2'
							gutterBottom
							sx={{ color: '#000' }}
							onClick={() => setOpenInfoDialog(true)}
						>
							{params?.row?.name}
						</Typography>
					</>
				);
			},
		},
		{
			field: 'department',
			headerName: 'Department',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
			renderCell: (params: any) => {
				return (
					<>
						<Typography variant='body1' gutterBottom onClick={() => setOpenInfoDialog(true)}>
							{params?.row?.department}
						</Typography>
					</>
				);
			},
		},
		{
			field: 'action',
			headerName: 'Actions',
			align: 'center',
			headerAlign: 'center',
			flex: 2,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			disableExport: true,
			headerClassName: 'table-header right-corner',
			renderCell: (params: any) => {
				//console.log(params);
				return (
					<Box>
						{params?.row?.status === 'Pending' && (
							<CheckCircleIcon
								sx={{ color: '#007F5F', cursor: 'pointer' }}
								onClick={() => {
									if (params.field === 'action') {
										setOpenApprove(true);
										setOpenInfoDialog(false);
									}
								}}
							/>
						)}
						{params?.row?.status === 'Pending' && (
							<CancelIcon
								sx={{ color: '#FE0000', cursor: 'pointer' }}
								onClick={() => {
									if (params.field === 'action') setOpenReject(true);
								}}
							/>
						)}
						<ModeEditIcon
							sx={{ color: 'grey', cursor: 'pointer' }}
							onClick={() => {
								if (params.field === 'action') setOpenUpdate(true);
							}}
						/>
						<DeleteIcon sx={{ cursor: 'pointer' }} onClick={() => setOpenDeleteDialog(true)} />
					</Box>
				);
			},
		},
	];
	const handleChange_Update_Date = (newValue: Date | null, name: string) => {
		setValidUpdateDateMessage('');
		// let year = moment(UpdateDataAttendance.day).set({ hour: 0, minute: 0, second: 0 }).years();
		// let month = moment(UpdateDataAttendance.day).set({ hour: 0, minute: 0, second: 0 }).month(); //+ 1;
		// let day = moment(UpdateDataAttendance.day).set({ hour: 0, minute: 0, second: 0 }).date();

		// function getCheckDate(date: Date | null) {
		// 	let hour = moment(date).hours();
		// 	let min = moment(date).minutes();
		// 	let _date = moment(date).set({ year: year, month: month, date: day, hour: hour, minute: min, second: 0 });
		// 	return _date;
		// }
		// if (UpdateDataAttendance.checkOut) {
		// 	if (moment(getCheckDate(newValue)) < moment(UpdateDataAttendance.checkIn)) {
		// 		setValidUpdateDateMessage('CheckOut should be greater than checKIn');
		// 	}

		// 	if (name === 'checkIn' && moment(getCheckDate(newValue)) > moment(UpdateDataAttendance.checkOut)) {
		// 		setValidUpdateDateMessage('CheckIn should be less than checKOut');
		// 	}
		// }

		// let hour = moment(newValue).hours();
		// let min = moment(newValue).minutes();
		// newValue = moment(UpdateDataAttendance.day || newValue)
		// 	.startOf('day')
		// 	.add(hour, 'hours')
		// 	.add(min, 'minutes')
		// 	.toDate();

		if (!moment(newValue).isSameOrBefore(new Date())) {
			setValidUpdateDateMessage('The value must be less than or equal to the current time.');
		}
		if (UpdateDataAttendance.checkOut) {
			if (name === 'checkOut') {
				if (moment(newValue) <= moment(UpdateDataAttendance.checkIn)) {
					setValidUpdateDateMessage('CheckOut should be greater than checKIn');
				}
				let duration = moment.duration(moment(newValue).diff(UpdateDataAttendance.checkIn));
				let hours = duration.asHours();
				if (hours > 24) {
					setValidUpdateDateMessage(
						'The difference between checkIn and checkOut must be less than or equal to 24 hours'
					);
				}
				//console.log('hours', hours);
			}
		}
		if (name === 'checkIn') {
			if (moment(newValue) > moment(UpdateDataAttendance.checkOut)) {
				setValidUpdateDateMessage('CheckIn should be less than checKOut');
			}
			let duration = moment.duration(moment(UpdateDataAttendance.checkOut).diff(newValue));
			let hours = duration.asHours();
			if (hours > 24) {
				setValidUpdateDateMessage(
					'The difference between check in and check out must be less than or equal to 24 hours'
				);
			}
			//console.log('hours', hours);
		}
		setUpdateDataAttendance((prev) => ({
			...prev,
			[name]: newValue,
		}));
	};
	const updateManual = () => {
		let requestBody: {
			checkIn: {
				at: any;
			};
			checkOut?: {
				at: any;
			};
			reason: any;
		} = {
			checkIn: {
				at: UpdateDataAttendance.checkIn,
			},
			reason: UpdateDataAttendance.reason ? UpdateDataAttendance.reason : undefined,
		};
		if (UpdateDataAttendance.checkOut) {
			requestBody = {
				checkIn: {
					at: UpdateDataAttendance.checkIn,
				},
				checkOut: {
					// optional
					at: UpdateDataAttendance.checkOut ? UpdateDataAttendance.checkOut : undefined,
				},
				reason: UpdateDataAttendance.reason ? UpdateDataAttendance.reason : undefined,
			};
		}
		axios
			.put(`${API.attendance.attendance}/${RowData.id}`, requestBody)
			.then((res) => {
				setIsError(false);
				setRefreshData(!RefreshData);
				setOpenMessage(true);
			})
			.catch((err) => {
				setIsError(true);
				setOpenMessage(true);
			});
		setOpenUpdate(false);
	};
	const onAccept = () => {
		axios
			.post(`${API.attendance.attendance}/${RowData?.id}/accept`)
			.then((res) => {
				setIsError(false);
				setOpenApprove(false);
				setOpenMessage(true);
				setRefreshData(!RefreshData);
			})
			.catch((err) => {
				setIsError(true);
				setOpenApprove(false);
				setOpenMessage(true);
			});
		setOpenInfoDialog(false);
	};
	const onReject = () => {
		axios
			.post(`${API.attendance.attendance}/${RowData?.id}/reject`)
			.then((res) => {
				setIsError(false);
				setOpenReject(false);
				setOpenMessage(true);
				setRefreshData(!RefreshData);
			})
			.catch((err) => {
				setIsError(true);
				setOpenReject(false);
				setOpenMessage(true);
			});
		setOpenInfoDialog(false);
	};
	const onDelete = () => {
		axios
			.delete(`${API.attendance.attendance}/${RowData?.id}`)
			.then((res) => {
				setOpenDeleteDialog(false);
				setIsError(false);
				setOpenMessage(true);
				setRefreshData(!RefreshData);
			})
			.catch((err) => {
				setOpenDeleteDialog(false);
				setIsError(true);
				setOpenMessage(true);
			});
		setOpenInfoDialog(false);
	};
	return (
		<>
			<Stack spacing={2} p={3} height={1}>
				{Loading && <Skeleton variant='rectangular' width={'100%'} height={118} />}
				{Requests.length > 0 && !Loading && (
					<Box
						height={1}
						sx={{
							'& .table-header': {
								backgroundColor: '#dadada',
							},
							'& .left-corner': {
								borderTopLeftRadius: '15px',
							},
							'& .right-corner': {
								borderTopRightRadius: '15px',
							},
							'& .css-f3jnds-MuiDataGrid-columnHeaders': {
								borderTopRightRadius: '15px !important',
								borderTopLeftRadius: '15px !important',
								background: '#dadada !important',
							},
						}}
					>
						<DataGridPremium
							className='custom-grid-class'
							onRowClick={(rowData: any) => {
								//console.log(rowData);
								setRowData(rowData?.row);
								setUpdateDataAttendance({
									day: rowData?.row.check_in_date,
									checkIn: rowData?.row.check_in_date,
									checkOut: rowData?.row.check_out_date ? rowData?.row.check_out_date : null,
									reason: rowData?.row.reason,
								});
							}}
							onCellClick={(params: any, event: any) => {
								if (params.field !== 'action') {
									setOpenInfoDialog(true);
								}
								//console.log(params);
							}}
							columns={columns}
							rows={Requests || []}
							apiRef={apiRef}
							disableSelectionOnClick
							pagination
							paginationMode='server'
							page={page}
							onPageChange={(newPage) => setPage(newPage)}
							pageSize={pageSize}
							onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50]}
							rowCount={totalRecords}
							disableColumnResize
							disableColumnReorder
							disableChildrenSorting
							components={{
								Toolbar: CustomToolbar,
							}}
						/>
					</Box>
				)}
				{Requests.length === 0 && !Loading && (
					<Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
						<Box component='img' sx={{ width: '30%', display: 'block' }} alt='/' src='/images/request.svg' />
						<Typography variant='h5' gutterBottom fontWeight={'500'}>
							No Requests Yet!
						</Typography>
						<Typography variant='subtitle2' display='block' gutterBottom sx={{ color: '#707070' }}>
							You not have any requests today
						</Typography>
					</Stack>
				)}
			</Stack>

			{/** Approve */}
			<ConfirmDialog
				open={openApprove}
				onClose={() => setOpenApprove(false)}
				title='Approve Vacation'
				message={`Are you sure you want to approve this request from ${RowData?.name}`}
				cancelLabel='Close'
				saveLabel='Approve'
				onApply={onAccept}
			/>
			{/** Reject */}
			<ConfirmDialog
				open={openReject}
				onClose={() => setOpenReject(false)}
				title='Reject Vacation'
				message={`Are you sure you want to reject this request from ${RowData?.name}`}
				cancelLabel='Close'
				saveLabel='Reject'
				onApply={onReject}
			/>
			{/** Delete */}
			<ConfirmDialog
				open={OpenDeleteDialog}
				onClose={() => setOpenDeleteDialog(false)}
				title=''
				message='Are you sure you want to delete this request?'
				cancelLabel='Close'
				saveLabel='Delete'
				onApply={onDelete}
			/>

			{/** Update Attendance  */}
			<Dialog
				open={OpenUpdate}
				onClose={() => setOpenUpdate(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogContent>
					<Grid container spacing={2} height={'100%'}>
						<Grid item xs={6}>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Check in Time
							</Typography>
							{/* <LocalizationProvider dateAdapter={AdapterDateFns}>
								<TimePicker
									label=''
									value={UpdateDataAttendance.checkIn}
									onChange={(newValue: Date | null) => handleChange_Update_Date(newValue, 'checkIn')}
									renderInput={(params: any) => {
										params.inputProps.placeholder = 'hh:mm';
										return <TextField className='input-style' {...params} />;
									}}
								/>
							</LocalizationProvider> */}
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DateTimePicker
									label=''
									value={UpdateDataAttendance.checkIn}
									onChange={(newValue: Date | null) => handleChange_Update_Date(newValue, 'checkIn')}
									renderInput={(params: any) => {
										params.inputProps.placeholder = 'mm/dd/yyyy hh:mm';
										return <TextField className='input-style' {...params} />;
									}}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={6}>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Check Out Time
							</Typography>
							{/* <LocalizationProvider dateAdapter={AdapterDateFns}>
								<TimePicker
									label=''
									value={UpdateDataAttendance.checkOut}
									disabled={UpdateDataAttendance.checkOut ? false : true}
									onChange={(newValue: Date | null) => handleChange_Update_Date(newValue, 'checkOut')}
									renderInput={(params: any) => {
										params.inputProps.placeholder = 'hh:mm';
										return <TextField className='input-style' {...params} />;
									}}
								/>
							</LocalizationProvider> */}

							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DateTimePicker
									label=''
									value={UpdateDataAttendance.checkOut}
									onChange={(newValue: Date | null) => handleChange_Update_Date(newValue, 'checkOut')}
									renderInput={(params: any) => {
										params.inputProps.placeholder = 'mm/dd/yyyy hh:mm';
										return <TextField className='input-style' {...params} />;
									}}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='caption' style={{ color: '#FE0000' }} gutterBottom component='div'>
								{validUpdateDateMessage}
							</Typography>{' '}
						</Grid>
						<Grid item xs={12}>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Reason
							</Typography>
							<TextField
								name='reason'
								value={UpdateDataAttendance.reason}
								onChange={(e) => setUpdateDataAttendance((prev: any) => ({ ...prev, reason: e.target.value }))}
								style={{ width: '100%' }}
								InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button
						variant='outlined'
						sx={{ textTransform: 'capitalize', width: '160px' }}
						onClick={() => {
							setValidUpdateDateMessage('');
							setOpenUpdate(false);
						}}
					>
						Cancel
					</Button>
					<Button
						variant='contained'
						sx={{ textTransform: 'capitalize', width: '160px' }}
						onClick={updateManual}
						autoFocus
						disabled={validUpdateDateMessage.length > 0 || DisabledUpdateDate}
					>
						Update
					</Button>
				</DialogActions>
			</Dialog>

			{/** Show image */}
			<Dialog
				open={OpenCheckInImgDialog}
				keepMounted
				onClose={() => setOpenCheckInImgDialog(false)}
				aria-describedby='alert-dialog-slide-description'
			>
				<Box p={2} sx={{ width: '30vw', minHeight: '30vh' }}>
					<img
						src={RowData?.photoCheckIn}
						width='100%'
						height={'100%'}
						style={{ borderRadius: 2 }}
						onLoad={() => console.log('image is loaded')}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src = '/images/error_img.jpg';
						}}
					/>
				</Box>
			</Dialog>
			<Dialog
				open={OpenCheckOutImgDialog}
				keepMounted
				onClose={() => setOpenCheckOutImgDialog(false)}
				aria-describedby='alert-dialog-slide-description'
			>
				<Box p={2} sx={{ width: '30vw', minHeight: '30vh' }}>
					<img
						src={RowData?.photoCheckOut}
						width='100%'
						height={'100%'}
						style={{ borderRadius: 2 }}
						onLoad={() => console.log('image is loaded')}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src = '/images/error_img.jpg';
						}}
					/>
				</Box>
			</Dialog>

			{/** Info Dialog */}
			<Modal
				open={OpenInfoDialog}
				onClose={() => setOpenInfoDialog(false)}
				aria-labelledby='alert-dialog-title-info'
				aria-describedby='alert-dialog-description-info'
			>
				<Box sx={{ ...style, width: 600 }}>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<IconButton
							aria-label='close'
							onClick={() => setOpenInfoDialog(false)}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
						{InfoData.map((ele, i) => (
							<Grid item xs={ele.label === 'Reason' ? 12 : 6} key={i}>
								<Typography variant='subtitle2' gutterBottom>
									{ele.label}:
								</Typography>
								<Typography variant='body1' gutterBottom>
									{(ele.label !== 'Photo' && RowData[ele?.field]) || ''}
									{ele.label === 'Photo' && (
										<img
											src={RowData[ele?.field]}
											width='100px'
											height={'70px'}
											onClick={() => {
												setImg(RowData[ele?.field]);
												setOpenChildModule(true);
											}}
											style={{ cursor: 'pointer' }}
											onLoad={() => {}}
											onError={(e) => {
												e.currentTarget.onerror = null; // prevents looping
												e.currentTarget.src = '/images/error_img.jpg';
											}}
										/>
									)}
								</Typography>
							</Grid>
						))}
					</Grid>
					<Box display={'flex'} alignItems='center' justifyContent={'flex-end'}>
						<CheckCircleIcon sx={{ color: '#007F5F', cursor: 'pointer' }} onClick={() => setOpenApprove(true)} />
						<CancelIcon sx={{ color: '#FE0000', cursor: 'pointer' }} onClick={() => setOpenReject(true)} />
						<ModeEditIcon sx={{ color: 'grey', cursor: 'pointer' }} onClick={() => setOpenUpdate(true)} />
						<DeleteIcon sx={{ cursor: 'pointer' }} onClick={() => setOpenDeleteDialog(true)} />
					</Box>
					<Modal
						//hideBackdrop
						open={OpenChildModule}
						keepMounted
						onClose={() => setOpenChildModule(false)}
						aria-describedby='alert-dialog-slide-description'
					>
						<Box p={2} sx={{ ...style, width: '30vw', minHeight: '30vh' }}>
							<IconButton
								aria-label='close'
								onClick={() => setOpenChildModule(false)}
								sx={{
									position: 'absolute',
									right: 2,
									top: 8,
									color: (theme) => theme.palette.grey[500],
								}}
							>
								<CloseIcon />
							</IconButton>
							<img
								src={Img}
								width='100%'
								height={'100%'}
								style={{ borderRadius: 2 }}
								onLoad={() => console.log('image is loaded')}
								onError={({ currentTarget }) => {
									currentTarget.onerror = null; // prevents looping
									currentTarget.src = '/images/error_img.jpg';
								}}
							/>
						</Box>
					</Modal>
				</Box>
			</Modal>

			<Snackbar open={OpenMessage} autoHideDuration={6000} onClose={() => setOpenMessage(false)}>
				{IsError ? (
					<Alert onClose={() => setOpenMessage(false)} severity='error' sx={{ width: '100%' }}>
						Request failed!
					</Alert>
				) : (
					<Alert onClose={() => setOpenMessage(false)} severity='success' sx={{ width: '100%' }}>
						Request succeeded.
					</Alert>
				)}
			</Snackbar>
		</>
	);
}

export default Attendances;
