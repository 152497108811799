import React, { useState, useEffect } from 'react';
import {
	Stack,
	Box,
	Typography,
	Snackbar,
	Skeleton,
	Grid,
	Autocomplete,
	TextField,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { DataGridPremium, useGridApiRef, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import moment from 'moment';
import { LocalizationProvider as LocalizationProviderPro } from '@mui/x-date-pickers-pro';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import ConfirmDialog from '../../Components/ConfirmDialog';
import axios from '../../axiosInstance';
import { API } from '../../api';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
function CustomToolbar() {
	return (
		<GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
			<GridToolbarExport printOptions={{ disableToolbarButton: true }} excelOptions={{ disableToolbarButton: true }} />
		</GridToolbarContainer>
	);
}
function Alarms() {
	const [SelectedUsers, setSelectedUsers] = useState([]);
	const [EmployeesDDL, setEmployeesDDL] = useState<{ name: string; id: object }[]>([]);
	const [Alarms, setAlarms] = useState([]);
	const apiRef = useGridApiRef();
	const [totalRecords, setTotalRecords] = useState(0);
	const [pageSize, setPageSize] = React.useState<number>(20);
	const [page, setPage] = React.useState(0);
	const [RowData, setRowData] = useState<any>();
	const [OpenMessage, setOpenMessage] = useState(false);
	const [IsError, setIsError] = useState(false);
	const [OpenDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [RefreshData, setRefreshData] = useState(false);
	const [Loading, setLoading] = useState(false);
	const [DateRange, setDateRange] = useState<DateRange<Date>>([null, null]);
	const [markedAsRead, setMarkedAsRead] = useState<string>('true');
	const [MarkDialog, setMarkDialog] = useState(false);

	useEffect(() => {
		// Get users
		axios
			.get(API.user.basic, {
				params: {
					total: true,
					isActive: true,
				},
			})
			.then((res) => {
				let data: { name: string; id: object }[] = res?.data?.data.map((val: any) => ({
					label: `${val?.firstName} ${val?.lastName}`,
					id: val?.id,
				}));
				setEmployeesDDL([...data]);
			})
			.catch((err) => {
				setEmployeesDDL([]);
			});
	}, []);
	useEffect(() => {
		setLoading(true);
		setAlarms([]);
		axios
			.get(API.alarm.get, {
				params: {
					total: true,
					skip: page * (pageSize === undefined ? 0 : pageSize),
					limit: pageSize,
					from: DateRange[0] !== null ? moment(DateRange[0]).startOf('day').toISOString() : undefined,
					to: DateRange[1] !== null ? moment(DateRange[1]).endOf('day').toISOString() : undefined,
					markedAsRead: markedAsRead === 'undefined' ? undefined : markedAsRead === 'true' ? true : false,
					employee:
						SelectedUsers.length > 0
							? SelectedUsers.map((val: { id: object; label: string }) => val?.id)
							: undefined,
				},
			})
			.then((res) => {
				setTotalRecords(res?.data?.totalRecords);
				let data = res?.data?.data?.map((val: any) => ({
					id: val?.id,
					name: `${val?.employee?.firstName} ${val?.employee?.lastName}`,
					img: val?.employee?.avatar?.url || '/images/error_img.jpg',
					createdAt: moment(val?.createdAt).format('DD-MM-YYYY, hhA'),
					status: val?.attendance?.status || '',
					markedAsRead: val?.markedAsRead,
					align: 'center',
				}));
				setAlarms(data);
				//apiRef.current.rows = data;
				setLoading(false);
			})
			.catch((err) => {
				setAlarms([]);
				setLoading(false);
			});
	}, [pageSize, page, RefreshData, SelectedUsers, DateRange, markedAsRead]);
	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Name',
			align: 'left',
			headerAlign: 'center',
			flex: 2,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header left-corner',
			renderCell: (params: any) => {
				return (
					<>
						<Stack direction='row' spacing={2} justifyContent='flex-start' alignItems='center'>
							<img
								src={params?.row?.img}
								width='50px'
								height={'30px'}
								onLoad={() => {}}
								onError={(e) => {
									e.currentTarget.onerror = null; // prevents looping
									e.currentTarget.src = '/images/error_img.jpg';
								}}
							/>
							<Typography variant='subtitle2' gutterBottom sx={{ width: '70%', color: '#000' }}>
								{params?.row?.name}
							</Typography>
						</Stack>
					</>
				);
			},
		},
		{
			field: 'createdAt',
			headerName: 'CreatedAt',
			align: 'center',
			headerAlign: 'center',
			flex: 2,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'status',
			headerName: 'Status',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'action',
			headerName: 'Actions',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			disableExport: true,
			headerClassName: 'table-header right-corner',
			renderCell: (params: any) => (
				<Box>
					{params?.row?.markedAsRead === false && (
						<MarkChatReadIcon sx={{ color: '#FE0000', cursor: 'pointer' }} onClick={() => setMarkDialog(true)} />
					)}
					<DeleteIcon sx={{ cursor: 'pointer' }} onClick={() => setOpenDeleteDialog(true)} />
				</Box>
			),
		},
	];
	const onDelete = () => {
		axios
			.delete(`${API.alarm.get}/${RowData?.id}`)
			.then((res) => {
				setOpenDeleteDialog(false);
				setIsError(false);
				setOpenMessage(true);
				setRefreshData(!RefreshData);
			})
			.catch((err) => {
				setOpenDeleteDialog(false);
				setIsError(true);
				setOpenMessage(true);
			});
	};
	const onMark = () => {
		axios
			.post(`${API.alarm.get}/${RowData?.id}/mark-as-read`)
			.then((res) => {
				setMarkDialog(false);
				setIsError(false);
				setOpenMessage(true);
				setRefreshData(!RefreshData);
			})
			.catch((err) => {
				setMarkDialog(false);
				setIsError(true);
				setOpenMessage(true);
			});
	};
	return (
		<>
			<Stack spacing={2} p={3} height={1}>
				<Box height={1 / 10}>
					<Grid container spacing={2}>
						<Grid item xs={1}>
							<Typography variant='h5' gutterBottom fontWeight={'600'}>
								Alarms
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<Autocomplete
								disablePortal
								multiple
								fullWidth
								options={EmployeesDDL}
								value={SelectedUsers}
								onChange={(event: any, newValue: any) => {
									setSelectedUsers(newValue);
									setPage(0);
								}}
								sx={{
									'& input': {
										height: '25px',
									},
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										//id='search-icon'
										style={{ width: '100%' }}
										//type='text'
										//className='input-style'
										label='Search by Employee'
									/>
								)}
							/>
						</Grid>
						<Grid item xs={6}>
							<LocalizationProviderPro dateAdapter={AdapterDateFns} localeText={{ start: 'From', end: 'To' }}>
								<DateRangePicker
									value={DateRange}
									onChange={(newValue: DateRange<Date>) => {
										setDateRange(newValue);
										setPage(0);
									}}
									renderInput={(startProps, endProps) => (
										<React.Fragment>
											<TextField
												sx={{ width: '49%', height: '59px' }}
												className='input-style'
												{...startProps}
											/>
											<Box sx={{ mx: 2 }}> </Box>
											<TextField
												sx={{ width: '49%', height: '59px' }}
												className='input-style'
												{...endProps}
											/>
										</React.Fragment>
									)}
								/>
							</LocalizationProviderPro>
						</Grid>
						<Grid item xs={2}>
							<FormControl fullWidth>
								<InputLabel>Status</InputLabel>
								<Select
									name='markedAsRead'
									value={markedAsRead}
									onChange={(event: SelectChangeEvent) => {
										setMarkedAsRead(event.target.value as string);
										setPage(0);
									}}
									label='Status'
									//className='input-style'
									sx={{
										height: '62px',
										backgroundColor: '#f2f2f2',
										border: '1px solid #f2f2f2 !important',
										borderRadius: '6px',
									}}
								>
									<MenuItem value={'undefined'}>All</MenuItem>
									<MenuItem value={'true'}>Read</MenuItem>
									<MenuItem value={'false'}>unRead</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</Box>
				{Loading && <Skeleton variant='rectangular' width={'100%'} height={118} />}
				{Alarms.length > 0 && !Loading && (
					<Box
						height={1}
						sx={{
							'& .table-header': {
								backgroundColor: '#dadada',
							},
							'& .left-corner': {
								borderTopLeftRadius: '15px',
							},
							'& .right-corner': {
								borderTopRightRadius: '15px',
							},
							'& .css-f3jnds-MuiDataGrid-columnHeaders': {
								borderTopRightRadius: '15px !important',
								borderTopLeftRadius: '15px !important',
								background: '#dadada !important',
							},
						}}
					>
						<DataGridPremium
							className='custom-grid-class'
							onRowClick={(rowData) => setRowData(rowData?.row)}
							// onCellClick={(rowData) => {
							// 	if (rowData.field !== 'action') {
							// 		navigate(`/Alarms/${rowData?.row?.id}`);
							// 	}
							// }}
							columns={columns}
							rows={Alarms || []}
							apiRef={apiRef}
							disableSelectionOnClick
							pagination
							paginationMode='server'
							page={page}
							onPageChange={(newPage) => setPage(newPage)}
							pageSize={pageSize}
							onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50]}
							rowCount={totalRecords}
							disableColumnResize
							disableColumnReorder
							disableChildrenSorting
							components={{
								Toolbar: CustomToolbar,
							}}
						/>
					</Box>
				)}
				{Alarms.length === 0 && !Loading && (
					<Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
						<Typography variant='h5' gutterBottom fontWeight={'500'}>
							No Alarms Yet!
						</Typography>
					</Stack>
				)}
			</Stack>

			{/** Delete */}
			<ConfirmDialog
				open={OpenDeleteDialog}
				onClose={() => setOpenDeleteDialog(false)}
				title=''
				message='Are you sure you want to delete this alarm?'
				cancelLabel='Close'
				saveLabel='Delete'
				onApply={onDelete}
			/>

			{/** Mark-as-read */}
			<ConfirmDialog
				open={MarkDialog}
				onClose={() => setMarkDialog(false)}
				title='Mark as Read'
				message='Are you sure you want to Mark as Read this alarm?'
				cancelLabel='Close'
				saveLabel='Yes'
				onApply={onMark}
			/>
			<Snackbar open={OpenMessage} autoHideDuration={6000} onClose={() => setOpenMessage(false)}>
				{IsError ? (
					<Alert onClose={() => setOpenMessage(false)} severity='error' sx={{ width: '100%' }}>
						Request failed!
					</Alert>
				) : (
					<Alert onClose={() => setOpenMessage(false)} severity='success' sx={{ width: '100%' }}>
						Request succeeded.
					</Alert>
				)}
			</Snackbar>
		</>
	);
}

export default Alarms;
