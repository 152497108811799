import React, { useState, useRef, useEffect } from 'react';
import { Box, Skeleton } from '@mui/material';
import { GoogleMap, useJsApiLoader, DrawingManager, Marker, Polygon, Circle } from '@react-google-maps/api';

const containerStyle = {
	width: '100%',
	height: '100%',
};

type MapProps = {
	MapCenter: {
		lat: number;
		lng: number;
	};
	MapZoom: number;
	getCoords?: (coords: any) => any;
	getCircle?: (coords: any) => any;
	polygonPaths: { lat: number; lng: number }[];
	circleInfo:
		| {
				longitude: number;
				latitude: number;
				radius: number; //  in meters
		  }
		| undefined;
	drawingControl: boolean;
	ShowShape: boolean;
	setShowShape?: React.Dispatch<React.SetStateAction<any>>;
	hideDrawingControl: boolean;
	//setPolygonCoords: React.Dispatch<React.SetStateAction<any>>;
};
function MapComp({
	MapCenter,
	MapZoom,
	getCoords,
	getCircle,
	polygonPaths,
	circleInfo,
	drawingControl,
	ShowShape,
	setShowShape,
	hideDrawingControl,
}: MapProps) {
	const drawingManagerRef = useRef<any>();
	const setShowShapeRef = useRef<any>(setShowShape);
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyDsC_zRb6eZK5ExpD1LOPefTDZctZ7ULyY',
		libraries: ['places', 'geometry', 'visualization', 'drawing'],
	});
	const [ShowDraw, setShowDraw] = useState(false);
	let selectedShape: any;
	useEffect(() => {
		if (isLoaded) {
			setShowDraw(true);
		}
	}, [isLoaded]);
	const onLoad = (drawingManager: any) => {
		drawingManagerRef.current = drawingManager;
		//mapRef.current = drawingManager.map;
	};
	const onPolygonComplete = (polygon: any) => {
		//polygon.setMap(null);
		console.log('polygon', polygon);

		let polygonCoordsArray = [];
		let coords = polygon.getPath().getArray();
		console.log(coords);

		for (let i = 0; i < coords.length; i++) {
			// console.log(coords[i].lat() + "," + coords[i].lng());
			polygonCoordsArray.push([coords[i].lng(), coords[i].lat()]);
		}
		console.log(polygonCoordsArray);

		//setShowShapeRef?.current(false);
		if (getCoords) getCoords(polygon);
	};
	const onCircleComplete = (circle: any) => {
		// console.log('circle', circle);
		// console.log('radius', circle.radius);
		// console.log('center', circle.center.lat(), circle.center.lng());
		if (getCircle) getCircle(circle);
	};
	function clearSelection() {
		if (selectedShape) {
			selectedShape = null;
		}
	}

	function setSelection(shape: any) {
		clearSelection();
		selectedShape = shape;
	}

	function deleteSelectedShape() {
		if (selectedShape) {
			selectedShape.setMap(null);
			// To show:
			drawingManagerRef.current.setOptions({
				drawingControl: true,
			});
		}
	}

	const onOverlayComplete = (e: any) => {
		deleteSelectedShape();
		if (e.type != google.maps.drawing.OverlayType.MARKER) {
			// Switch back to non-drawing mode after drawing a shape.
			drawingManagerRef.current.setDrawingMode(null);
			// To hide:
			// drawingManagerRef.current.setOptions({
			// 	drawingControl: false,
			// });

			// Add an event listener that selects the newly-drawn shape when the user
			// mouses down on it.
			let newShape = e.overlay;
			newShape.type = e.type;
			google.maps.event.addListener(newShape, 'click', function () {
				setSelection(newShape);
			});
			setSelection(newShape);
		}
	};
	return (
		<>
			<Box height={500} width={1}>
				{isLoaded ? (
					<GoogleMap mapContainerStyle={containerStyle} center={MapCenter} zoom={MapZoom}>
						<Marker position={MapCenter} />
						{ShowShape && (
							<>
								<Polygon
									paths={polygonPaths}
									options={{
										fillColor: '#f00',
										fillOpacity: 0.2,
										strokeColor: '#f00',
										strokeOpacity: 0.2,
										strokeWeight: 1,
										clickable: false,
										draggable: false,
										editable: false,
										geodesic: false,
										zIndex: 1,
									}}
								/>
								<Circle
									// optional
									onLoad={onLoad}
									// optional
									//onUnmount={onUnmount}
									// required
									center={{
										lat: circleInfo?.latitude as number,
										lng: circleInfo?.longitude as number,
									}}
									// required
									options={{
										strokeColor: '#FF0000',
										strokeOpacity: 0.2,
										strokeWeight: 2,
										fillColor: '#FF0000',
										fillOpacity: 0.35,
										clickable: false,
										draggable: false,
										editable: false,
										visible: true,
										radius: circleInfo?.radius,
										zIndex: 1,
									}}
								/>
							</>
						)}
						{ShowDraw && !hideDrawingControl ? (
							<DrawingManager
								onLoad={onLoad}
								//drawingMode={google.maps.drawing.OverlayType.POLYGON}
								drawingMode={google.maps.drawing.OverlayType.MARKER}
								options={{
									drawingControl: drawingControl,

									drawingControlOptions: {
										position: google.maps.ControlPosition.TOP_CENTER,
										//drawingModes: [google.maps.drawing.OverlayType.POLYGON],
										drawingModes: [
											//google.maps.drawing.OverlayType.MARKER,
											google.maps.drawing.OverlayType.CIRCLE,
											// google.maps.drawing.OverlayType.POLYGON,
											// google.maps.drawing.OverlayType.POLYLINE,
											// google.maps.drawing.OverlayType.RECTANGLE,
										],
									},
									polygonOptions: {
										clickable: true,
										draggable: false,
										editable: false,
										fillColor: '#f0f5f7',
										fillOpacity: 0.55,
										strokeColor: '#1e8d77',
										strokeWeight: 1,
									},
									circleOptions: {
										fillColor: '#f0f5f7',
										fillOpacity: 0.55,
										strokeColor: '#1e8d77',
										strokeWeight: 1,
										clickable: false,
										editable: true,
										zIndex: 1,
									},
								}}
								onPolygonComplete={onPolygonComplete}
								onCircleComplete={onCircleComplete}
								onOverlayComplete={onOverlayComplete}
							/>
						) : (
							<></>
						)}
					</GoogleMap>
				) : (
					<>
						<Skeleton variant='rectangular' width={'100%'} height={500} />
					</>
				)}
			</Box>
		</>
	);
}

export default MapComp;
