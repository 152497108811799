import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, Autocomplete, TextField, Stack } from '@mui/material';

import axios from '../../axiosInstance';
import { API } from '../../api';

export default function Settings() {
	const [Options, setOptions] = useState<string[]>([]);
	const [TimeZone, setTimeZone] = useState<string>('');

	function handleTimeZoneChange(value: string) {
		axios.patch(API.settings.updatePatch, { timezone: value }).then(({ data }) => {
			setTimeZone(value);
		});
	}

	useEffect(() => {
		axios.get(API.settings.metadata).then(({ data }) => {
			setOptions(data?.data?.TIMEZONES.map((val: any) => val.name));
		});
	}, []);

	useEffect(() => {
		axios.get(API.settings.get).then(({ data }) => {
			setTimeZone(data?.data?.timezone?.name);
		});
	}, []);

	return (
		<>
			<Stack spacing={2} p={3} height={1}>
				<Box>
					<Grid container>
						<Grid item xs={2}>
							<Typography variant='h5' gutterBottom fontWeight={'600'}>
								Settings
							</Typography>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={3} marginTop={8}>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Time Zone:
							</Typography>

							<Autocomplete
								disablePortal
								disableClearable
								options={Options}
								value={TimeZone}
								onChange={(event: any, newValue: string) => {
									handleTimeZoneChange(newValue);
								}}
								renderInput={(params) => <TextField {...params} className='input-style' />}
							/>
						</Grid>
					</Grid>
				</Box>
			</Stack>
		</>
	);
}
