import React, { useState, useEffect } from 'react';
import { Stack, Grid, Box, Typography, Button, IconButton, Menu, MenuItem, Snackbar, Skeleton } from '@mui/material';
import { DataGridPremium, useGridApiRef, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useNavigate, useParams } from 'react-router-dom';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import ConfirmDialog from '../../Components/ConfirmDialog';
import axios from '../../axiosInstance';
import { API } from '../../api';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
function CustomToolbar() {
	return (
		<GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
			<GridToolbarExport printOptions={{ disableToolbarButton: true }} excelOptions={{ disableToolbarButton: true }} />
		</GridToolbarContainer>
	);
}
function UsersDepartment(props: any) {
	const navigate = useNavigate();
	let { id } = useParams();
	const [DepartmentName, setDepartmentName] = useState('');
	const [Employees, setEmployees] = useState([]);
	const [totalRecords, settotalRecords] = useState(0);
	const [pageSize, setPageSize] = React.useState<number>(20);
	const [page, setPage] = React.useState(0);
	const [SearchByName, setSearchByName] = useState('');
	const [RowData, setRowData] = useState<any>();
	const [OpenDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [OpenMessage, setOpenMessage] = useState(false);
	const [IsError, setIsError] = useState(false);
	const [RefreshData, setRefreshData] = useState(false);
	const [Loading, setLoading] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const [contextMenu, setContextMenu] = React.useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setContextMenu(null);
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	function getHours(num: number) {
		let hours = Math.trunc(num / 60);
		let mins = num % 60;
		return `${hours}h ${mins}m`;
	}

	const apiRef = useGridApiRef();
	useEffect(() => {
		axios
			.get(
				`${API.department.department}/${id}`
				// {
				// 	params: {
				// 		id: id,
				// 	},
				// }
			)
			.then((res) => {
				setDepartmentName(res?.data?.data?.name);
			})
			.catch((err) => {});
	}, []);

	useEffect(() => {
		setLoading(true);
		setAnchorEl(null);
		axios
			.get(API.user.user, {
				params: {
					total: true,
					skip: page * (pageSize === undefined ? 0 : pageSize),
					limit: pageSize,
					name: SearchByName.length > 0 ? SearchByName : undefined,
					department: id,
				},
			})
			.then((res) => {
				settotalRecords(res?.data?.totalRecords);
				let data = res?.data?.data.map((val: any) => ({
					id: val?.id,
					name: `${val?.firstName} ${val?.lastName}`,
					img: val?.avatar?.url || '/images/error_img.jpg',
					department: val?.department?.name || '',
					workPhone: val?.workPhone,
					extension: val?.extension,
					workingHours: val?.workingHours ? getHours(val?.workingHours) : '--:--',
					align: 'center',
				}));
				setEmployees(data);
				setLoading(false);
			})
			.catch((err) => {
				setEmployees([]);
				setLoading(false);
			});
	}, [pageSize, page, SearchByName, RefreshData, id]);
	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Name',
			align: 'left',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header left-corner',
			renderCell: (params: any) => {
				return (
					<>
						<Stack direction='row' spacing={2} justifyContent='flex-start' alignItems='center'>
							<img
								src={params?.row?.img}
								width='50px'
								height={'40px'}
								onLoad={() => {}}
								onError={(e) => {
									e.currentTarget.onerror = null; // prevents looping
									e.currentTarget.src = '/images/error_img.jpg';
								}}
							/>
							<Typography variant='subtitle2' gutterBottom sx={{ width: '70%', color: '#000' }}>
								{params?.row?.name}
							</Typography>
						</Stack>
					</>
				);
			},
		},
		{
			field: 'department',
			headerName: 'Department',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'workPhone',
			headerName: 'Work Phone',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			sortable: false,
			resizable: false,
			disableColumnMenu: true,
			headerClassName: 'table-header',
		},
		{
			field: 'extension',
			headerName: 'Extension',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			sortable: false,
			resizable: false,
			disableColumnMenu: true,
			headerClassName: 'table-header',
		},
		{
			field: 'workingHours',
			headerName: 'Semi Monthly',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'action',
			headerName: 'Actions',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			disableExport: true,
			headerClassName: 'table-header right-corner',
			renderCell: (params: any) => (
				<Box>
					<IconButton
						aria-label='action'
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup='true'
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
					>
						<MoreVertIcon />
					</IconButton>
					{RowData?.id === params?.row?.id && (
						<Menu
							id='basic-menu'
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'basic-button',
							}}
						>
							<MenuItem
								onClick={() => {
									//navigate(`/employees/new-employee/${params?.row?.id}`);
									navigate(`/employees/new-employee/${RowData.id}`);
								}}
								sx={{ width: '100px' }}
							>
								<ModeEditIcon fontSize='small' /> &nbsp; Edit
							</MenuItem>
							<MenuItem onClick={() => setOpenDeleteDialog(true)} sx={{ width: '100px' }}>
								<DeleteIcon fontSize='small' /> &nbsp; Delete
							</MenuItem>
						</Menu>
					)}
				</Box>
			),
		},
	];
	const handleContextMenu = (event: React.MouseEvent) => {
		event.preventDefault();
		setAnchorEl(null);
		let rowData = Employees.filter((val: any) => val.id === event.currentTarget.getAttribute('data-id'));
		setRowData(rowData[0]);
		setContextMenu(contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
	};
	const onDelete = () => {
		axios
			.delete(`${API.user.user}/${RowData?.id}`)
			.then((res) => {
				setOpenDeleteDialog(false);
				setIsError(false);
				setOpenMessage(true);
				setRefreshData(!RefreshData);
			})
			.catch((err) => {
				setOpenDeleteDialog(false);
				setIsError(true);
				setOpenMessage(true);
			});
		setContextMenu(null);
		setAnchorEl(null);
	};
	return (
		<>
			<Stack spacing={2} p={3} height={1}>
				<Box>
					<Grid container spacing={2}>
						<Grid item xs={2}>
							<Typography variant='h5' gutterBottom fontWeight={'600'}>
								{DepartmentName}
							</Typography>
						</Grid>
						<Grid item xs={5}>
							<input
								id='search-icon'
								style={{ textIndent: '40px', width: '100%' }}
								type='text'
								className='input-style'
								placeholder='Search by Employee'
								value={SearchByName}
								onChange={(e) => {
									setSearchByName(e.target.value);
									setPage(0);
								}}
							/>
						</Grid>
						<Grid item xs={2} />
						{Employees.length > 0 && (
							<Grid item xs={2}>
								<Link to='/employees/new-employee'>
									<Button sx={{ textTransform: 'capitalize', width: '100%' }} variant='contained'>
										Add New Employee
									</Button>
								</Link>
							</Grid>
						)}
						<Grid item xs={1} />
					</Grid>
				</Box>
				{Loading && <Skeleton variant='rectangular' width={'100%'} height={118} />}
				{Employees.length > 0 && !Loading && (
					<Box
						height={1}
						sx={{
							'& .table-header': {
								backgroundColor: '#dadada',
							},
							'& .left-corner': {
								borderTopLeftRadius: '15px',
							},
							'& .right-corner': {
								borderTopRightRadius: '15px',
							},
							'& .css-f3jnds-MuiDataGrid-columnHeaders': {
								borderTopRightRadius: '15px !important',
								borderTopLeftRadius: '15px !important',
								background: '#dadada !important',
							},
						}}
					>
						<DataGridPremium
							className='custom-grid-class'
							//onRowClick={(rowData) => navigate(`/employees/profile/${rowData?.row?.id}`)}
							onRowClick={(rowData) => setRowData(rowData?.row)}
							onCellClick={(rowData) => {
								if (rowData.field !== 'action') {
									navigate(`/employees/profile/${rowData?.row?.id}`);
								}
							}}
							columns={columns}
							rows={Employees || []}
							apiRef={apiRef}
							disableSelectionOnClick
							pagination
							paginationMode='server'
							page={page}
							onPageChange={(newPage) => setPage(newPage)}
							pageSize={pageSize}
							onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							rowsPerPageOptions={[5, 10, 20, 50]}
							rowCount={totalRecords}
							disableColumnResize
							disableColumnReorder
							disableChildrenSorting
							componentsProps={{
								row: {
									onContextMenu: handleContextMenu,
									style: { cursor: 'context-menu' },
								},
							}}
							components={{
								Toolbar: CustomToolbar,
							}}
						/>
						<Menu
							open={contextMenu !== null}
							onClose={() => setContextMenu(null)}
							anchorReference='anchorPosition'
							anchorPosition={
								contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
							}
							componentsProps={{
								root: {
									onContextMenu: (e: any) => {
										e.preventDefault();
										setContextMenu(null);
									},
								},
							}}
						>
							<MenuItem
								onClick={() => {
									//navigate(`/employees/new-employee/${params?.row?.id}`);
									navigate(`/employees/new-employee/${RowData.id}`);
								}}
								sx={{ width: '100px' }}
							>
								<ModeEditIcon fontSize='small' /> &nbsp; Edit
							</MenuItem>
							<MenuItem onClick={() => setOpenDeleteDialog(true)} sx={{ width: '100px' }}>
								<DeleteIcon fontSize='small' /> &nbsp; Delete
							</MenuItem>
						</Menu>
					</Box>
				)}
				{Employees.length === 0 && !Loading && (
					<Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
						<Box component='img' sx={{ width: '30%', display: 'block' }} alt='/' src='/images/employees.svg' />
						<Typography variant='h5' gutterBottom fontWeight={'500'}>
							No Employees in this Department Yet!
						</Typography>
						<Typography variant='subtitle2' display='block' gutterBottom sx={{ color: '#707070' }}>
							You not have any employees there You can add new employee from below.
						</Typography>
						<Link to='/employees/new-employee'>
							<Button sx={{ textTransform: 'capitalize', width: '100%' }} variant='contained'>
								Add New Employee
							</Button>
						</Link>
					</Stack>
				)}
			</Stack>

			{/** Delete */}
			<ConfirmDialog
				open={OpenDeleteDialog}
				onClose={() => setOpenDeleteDialog(false)}
				title=''
				message='Are you sure you want to delete this employee?'
				cancelLabel='Close'
				saveLabel='Delete'
				onApply={onDelete}
			/>

			<Snackbar open={OpenMessage} autoHideDuration={6000} onClose={() => setOpenMessage(false)}>
				{IsError ? (
					<Alert onClose={() => setOpenMessage(false)} severity='error' sx={{ width: '100%' }}>
						Request failed!
					</Alert>
				) : (
					<Alert onClose={() => setOpenMessage(false)} severity='success' sx={{ width: '100%' }}>
						Request succeeded.
					</Alert>
				)}
			</Snackbar>
		</>
	);
}

export default UsersDepartment;
