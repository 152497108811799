import { QRCodeSVG } from 'qrcode.react';

import { Box } from '@mui/material';

export default function Settings() {
	return (
		<>
			<Box marginLeft={'30%'} marginTop={10}>
				<br />
				<QRCodeSVG
					size={700}
					value={
						'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzMzliZWI3MGM2NGJjOTE3NWY3MGNlZCIsImlhdCI6MTY2NDcyODc1OX0.GILislz1o9X9nW8kF6n5B5LftYdtCtXXevhx8Ly3cRk'
					}
				/>
			</Box>
		</>
	);
}
