import React, { useState, useEffect } from 'react';
import {
	Grid,
	Box,
	Typography,
	Button,
	Dialog,
	Stack,
	Chip,
	Menu,
	MenuItem,
	ListItemIcon,
	IconButton,
	DialogActions,
	DialogContent,
	TextField,
	Snackbar,
	FormControl,
	Select,
	Tooltip,
	Skeleton,
} from '@mui/material';
import {
	Person as PersonIcon,
	Delete as DeleteIcon,
	Edit as EditIcon,
	FmdGood as FmdGoodIcon,
	Work as WorkIcon,
	TrendingFlat as TrendingFlatIcon,
	TabletMac as TabletMacIcon,
	//AutoMode as AutoModeIcon,
	PhoneAndroid as PhoneAndroidIcon,
	Refresh as RefreshIcon,
	CheckCircle as CheckCircleIcon,
	Cancel as CancelIcon,
	AccessTime as AccessTimeIcon,
	MoreVert as MoreVertIcon,
	ModeEdit as ModeEditIcon,
	CalendarMonth as CalendarMonthIcon,
} from '@mui/icons-material';
import { QRCodeSVG } from 'qrcode.react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LocalizationProvider as LocalizationProviderPro } from '@mui/x-date-pickers-pro';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { DataGridPremium, useGridApiRef, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';

import axios from '../../axiosInstance';
import { API } from '../../api';
import Vacation from '../Requests/Vacation';
import ConfirmDialog from '../../Components/ConfirmDialog';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
function CustomToolbar() {
	return (
		<GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
			<GridToolbarExport printOptions={{ disableToolbarButton: true }} excelOptions={{ disableToolbarButton: true }} />
		</GridToolbarContainer>
	);
}
function Profile() {
	let { id } = useParams();
	//console.log('id', id);
	const navigate = useNavigate();

	const [anchorElIconsRequested, setAnchorElIconsRequested] = React.useState<null | HTMLElement>(null);
	const openIconsRequested = Boolean(anchorElIconsRequested);
	const [OpenDialog, setOpenDialog] = useState(false);
	const [RefreshData, setRefreshData] = useState(false);
	const [openApprove, setOpenApprove] = React.useState(false);
	const [OpenDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [OpenCkeckOut, setOpenCkeckOut] = useState(false);
	const [openReject, setOpenReject] = React.useState(false);
	const [IsError, setIsError] = useState(false);
	const [OpenMessage, setOpenMessage] = useState(false);
	const [ErrorMessage, setErrorMessage] = useState(null);
	const [validDateMessage, setvalidDateMessage] = useState('');
	const [DisabledManual, setDisabledManual] = useState(true);
	const [SelectedAttendance, setSelectedAttendance] = useState<{ id: object } | any>({});
	const [SemiMonthlyDate, setSemiMonthlyDate] = useState<{ from: any; to: any }>({
		from: null,
		to: null,
	});
	const [Data, setData] = useState<{
		day: any;
		checkIn: any;
		checkOut: any;
		reason: any;
	}>({
		day: null,
		checkIn: null,
		checkOut: null,
		reason: undefined,
	});
	const [SemiMonthlyAttendance, setSemiMonthlyAttendance] = useState<
		{
			id: any;
			date: string;
			check_in: string;
			check_out: string;
			reason: any;
			check_in_time: string;
			check_out_time: string;
			has_check_out: boolean;
			hrs: string;
			requestedAt: boolean;
			status: string;
			check_in_type: string;
			check_out_type: string;
		}[]
	>([
		// {
		// 	date: 'Sunday, 01 May 2022',
		// 	check_in_time: '09:00 AM',
		// 	check_out_time: '05:00 PM',
		// 	hrs: '08h 00m',
		// },
	]);
	const [EmployeeInfo, setEmployeeInfo] = useState({
		id: null,
		img: '',
		name: '',
		job_title: '',
		rate: 0,
		amount_earned: 0,
		email: '',
		gendar: '',
		mobile_number: '',
		birthday: '',
		address: '',
		country: '',
		city: '',
		work_email: '',
		work_mobile: '',
		department: '',
		supervisor: '',
		hire_date: '',
		termination_date: '',
		check_in_time: '',
		check_out_time: '',
		vacation_level: '',
		vacation_type: '',
		qr_code: '',
		pin_code: '',
		working_hrs_today: {
			check_in_time: '',
			check_out_time: '',
			hrs: '',
		},
		working_hrs_weekly: {
			required_hrs: '',
			hrs: '',
		},
		tmw_id: '',
		vacation: {
			casual: '',
			sick: '',
		},
	});
	const [OpenUpdate, setOpenUpdate] = useState(false);
	const [validUpdateDateMessage, setValidUpdateDateMessage] = useState('');
	const [DisabledUpdateDate, setDisabledUpdateDate] = useState(false);
	const [UpdateDataAttendance, setUpdateDataAttendance] = useState<{
		day: any;
		checkIn: any;
		checkOut: any;
		reason: any;
	}>({
		day: null,
		checkIn: null,
		checkOut: null,
		reason: undefined,
	});
	const apiRef = useGridApiRef();
	const [OpenAddVacation, setOpenAddVacation] = useState(false);
	const [openApproveVacation, setOpenApproveVacation] = React.useState(false);
	const [openRejectVacation, setOpenRejectVacation] = React.useState(false);
	const [OpenDeleteVacation, setOpenDeleteVacation] = useState(false);
	const [VacationState, setVacationState] = useState<{
		type: string;
		reason: string | undefined;
		from_to: DateRange<Date>;
	}>({
		type: '', // Casual, Sick
		reason: undefined,
		from_to: [null, null],
	});
	const [Vacations, setVacations] = useState([]);
	const [Logs, setLogs] = useState([]);
	const [LoadingLogs, setLoadingLogs] = useState(false);
	const [LogPageSize, setLogPageSize] = React.useState<number>(20);
	const [LogPage, setLogPage] = React.useState(0);
	const [LogTotalRecords, setLogTotalRecords] = useState(0);
	const [LogDateRange, setLogDateRange] = useState<DateRange<Date>>([null, null]);
	const [FilterDateRange, setFilterDateRange] = useState<DateRange<Date>>([
		new Date(moment().startOf('month').toISOString()),
		new Date(moment().endOf('month').toISOString()),
	]);
	const [working_hrs_weekly_Filtered, setWorking_hrs_weekly_Filtered] = useState('--:--');
	const [totalRecords, setTotalRecords] = useState(0);
	const [pageSize, setPageSize] = React.useState<number>(20);
	const [page, setPage] = React.useState(0);
	const [RefreshVacationsData, setRefreshVacationsData] = useState(false);
	const [LoadingVacations, setLoadingVacations] = useState(false);
	const [RowDataVacation, setRowDataVacation] = useState<any>();

	const color = {
		Pending: '#a6a832',
		Accepted: '#007F5F',
		Rejected: '#dd0000',
	};
	const types = {
		Tablet: <TabletMacIcon />,
		Admin: <PersonIcon />,
		Mobile: <PhoneAndroidIcon />,
		//Automated: <AutoModeIcon />,
	};
	function getHours(num: number) {
		let hours = Math.trunc(num / 60);
		let mins = num % 60;
		return `${hours}h ${mins}m`;
	}
	useEffect(() => {
		function getTimeFromMins(mins: number) {
			// do not include the first validation check if you want, for example,
			// getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
			if (mins >= 24 * 60 || mins < 0) {
				throw new RangeError('Valid input should be greater than or equal to 0 and less than 1440.');
			}
			var h = (mins / 60) | 0,
				m = mins % 60 | 0;
			return moment.utc().hours(h).minutes(m).format('hh:mm A');
		}
		// Get user by user id
		axios
			.get(`${API.user.user}/${id}`)
			.then((res) => {
				let data = res?.data?.data;
				setEmployeeInfo((prev) => ({
					...prev,
					id: data?.id,
					img: data?.avatar?.url || '/images/error_img.jpg',
					name: `${data?.firstName} ${data?.lastName}`,
					job_title: data?.jobTitle || '',
					rate: data.rate ? data.rate : 0,
					email: data?.email || '',
					gendar: data?.gender || '',
					mobile_number: data?.phone || '',
					birthday: `${moment(data?.birthDate).format('YYYY-MM-DD')}` || '',
					address: data?.address || '',
					country: data?.address?.country || '',
					city: data?.address?.city || '',
					work_email: data?.workEmail || '',
					work_mobile: data?.workPhone || '',
					department: data?.departments?.map((val: any) => val.name).join(', ') || '',
					supervisor: data?.supervisor ? `${data?.supervisor?.firstName} ${data?.supervisor?.lastName}` : '',
					hire_date: `${moment(data?.hireDate).format('YYYY-MM-DD')}` || '',
					termination_date: `${moment(data.terminationDate).format('YYYY-MM-DD')}` || '',
					check_in_time: `${getTimeFromMins(data?.checkInTime)}` || '',
					check_out_time: `${getTimeFromMins(data?.checkOutTime)}` || '',
					vacation_level: data?.vacationLevel || '',
					vacation_type: data?.vacationType || '',
					qr_code: data?.qrCode || '',
					pin_code: data?.PIN || '',
					tmw_id: data?.tmw_id && data?.tmw_id !== 'undefined' ? data?.tmw_id : '',
					vacation: {
						casual: `${data?.vacation?.casual?.used} / ${data?.vacation?.casual?.total}`,
						sick: `${data?.vacation?.sick?.used} / ${data?.vacation?.sick?.total}`,
					},
				}));
			})
			.catch((err) => {});

		let startPeriod, endPeriod;
		// if (moment().week() % 2) {
		// 	startPeriod = moment().startOf('week').toDate();
		// 	if (moment().week() === 53) endPeriod = moment().endOf('week').toDate();
		// 	else endPeriod = moment().endOf('week').add(1, 'week').toDate();
		// } else {
		// 	startPeriod = moment().startOf('week').subtract(1, 'week').toDate();
		// 	endPeriod = moment().endOf('week').toDate();
		// }
		if (parseInt(moment(new Date()).format('D')) <= 16) {
			startPeriod = moment().startOf('month').toDate();
			endPeriod = moment(startPeriod).add(15, 'day').endOf('day').toDate();
		} else {
			startPeriod = moment().startOf('month').add(15, 'day').toDate();
			endPeriod = moment().endOf('month').endOf('day').toDate();
		}
		setSemiMonthlyDate({
			from: startPeriod,
			to: endPeriod,
		});
		// Hours Semi monthly
		axios
			.get(API.analysis.working_hours, {
				params: {
					employee: id,
					from: startPeriod,
					to: endPeriod,
				},
			})
			.then((res) => {
				//console.log('bi weekly', res.data);
				let data = res?.data?.data;
				// let hour = Math.trunc(data?.workingHours / 60);
				// let mins = data.workingHours % 60;
				setEmployeeInfo((prev) => ({
					...prev,
					working_hrs_weekly: {
						...prev.working_hrs_weekly,
						hrs: data.workingHours ? getHours(data.workingHours) : '--:--', //`${hour}h ${mins}m`,
					},
				}));
			});

		// Last 15 Days
		axios
			.get(API.attendance.attendance, {
				params: {
					employee: id,
					// from: moment().add(-7, 'day').toISOString(),
					// to: moment().toISOString(),
					from: moment().add(-15, 'day').toISOString(),
					to: moment().endOf('day').toISOString(),
				},
			})
			.then((res) => {
				let data = res?.data?.data || [];
				data = data.map((val: any) => ({
					id: val?.id,
					date: val?.checkIn?.at ? moment(val?.checkIn?.at).format('dddd DD MMMM YYYY') : '--:--',
					check_in: val?.checkIn?.at ? val?.checkIn?.at : undefined,
					check_out: val?.checkOut?.at ? val?.checkOut?.at : undefined,
					reason: val?.reason,
					check_in_time: val?.checkIn?.at ? moment(val?.checkIn?.at).format('hh:mm a') : '--:--',
					check_out_time: val?.checkOut?.at ? moment(val?.checkOut?.at).format('hh:mm a') : '--:--',
					has_check_out: val?.checkOut?.at ? true : false,
					hrs: val?.workingHours ? getHours(val?.workingHours) : '--:--',
					requestedAt: val?.requestedAt ? true : false,
					status: val.status,
					check_in_type: val?.checkIn ? val?.checkIn?.type : 'noType',
					check_out_type: val?.checkOut ? val?.checkOut?.type : 'noType',
				}));
				setSemiMonthlyAttendance([...data]);
			});
		// Today
		axios
			.get(API.attendance.attendance, {
				params: {
					employee: id,
					from: moment().startOf('day').toISOString(),
					to: moment().endOf('day').toISOString(),
				},
			})
			.then((res) => {
				//console.log(res?.data?.data);
				let data = res?.data?.data.reverse().reduce(
					(acc: any, cur: any) => {
						acc.workingHours += cur.workingHours;
						if (!acc.checkIn) acc.checkIn = cur.checkIn.at;
						if (cur.checkOut?.at) acc.checkOut = cur.checkOut.at;
						return acc;
					},
					{ checkIn: undefined, checkOut: undefined, workingHours: 0 }
				);
				//console.log(data);
				// let hours = Math.trunc(data?.workingHours / 60);
				// let mins = data.workingHours % 60;
				setEmployeeInfo((prev) => ({
					...prev,
					amount_earned: data.workingHours ? prev.rate * data.workingHours : 0,
					working_hrs_today: {
						check_in_time: data?.checkIn ? moment(data?.checkIn).format('hh:mm a') : '--:--',
						check_out_time: data?.checkOut ? moment(data?.checkOut).format('hh:mm a') : '--:--',
						hrs: data.workingHours ? getHours(data.workingHours) : '--:--', // `${hours}h ${mins}m`,
					},
				}));
			});
	}, [id, RefreshData]);
	useEffect(() => {
		if (Data.checkIn === null || Data.checkOut === null || Data.day === null) {
			setDisabledManual(true);
		} else {
			setDisabledManual(false);
		}
	}, [Data]);
	useEffect(() => {
		if (UpdateDataAttendance.checkIn === null) {
			setDisabledUpdateDate(true);
		} else {
			setDisabledUpdateDate(false);
		}
	}, [UpdateDataAttendance]);

	useEffect(() => {
		setLoadingVacations(true);
		axios
			.get(API.vacation.get, {
				params: {
					total: true,
					skip: page * (pageSize === undefined ? 0 : pageSize),
					limit: pageSize,
					employee: id,
				},
			})
			.then((res) => {
				setTotalRecords(res?.data?.totalRecords);
				let data = res?.data?.data?.map((val: any) => ({
					id: val?.id,
					name: `${val?.employee?.firstName} ${val?.employee?.lastName}`,
					img: val?.employee?.avatar?.url || '/images/error_img.jpg',
					from: moment(val?.from).format('DD-MM-YYYY, hhA'),
					to: moment(val?.to).format('DD-MM-YYYY, hhA'),
					status: val?.status,
					reason: val?.reason,
					type: val?.type,
					department: val?.department?.name,
					align: 'center',
				}));
				setVacations(data);
				setLoadingVacations(false);
			})
			.catch((err) => {
				setVacations([]);
				setLoadingVacations(false);
			});
	}, [pageSize, page, RefreshVacationsData, id]);
	useEffect(() => {
		setLoadingLogs(true);
		axios
			.get(API.log.get, {
				params: {
					total: true,
					skip: LogPage * (LogPageSize === undefined ? 0 : LogPageSize),
					limit: LogPageSize,
					from: LogDateRange[0] !== null ? moment(LogDateRange[0]).startOf('day').toISOString() : undefined,
					to: LogDateRange[1] !== null ? moment(LogDateRange[1]).endOf('day').toISOString() : undefined,
					employee: id,
				},
			})
			.then((res) => {
				setLogTotalRecords(res?.data?.totalRecords);
				let data = res?.data?.data?.map((val: any) => ({
					id: val?.id,
					createdAt: moment(val?.createdAt).format('DD-MM-YYYY, hhA'),
					gate: val?.gate?.name || '',
					type: val?.type || '',
					align: 'center',
				}));
				setLogs(data);
				setLoadingLogs(false);
			})
			.catch((err) => {
				setLogs([]);
				setLoadingLogs(false);
			});
	}, [id, LogPageSize, LogPage, LogDateRange]);

	useEffect(() => {
		// for date filter card
		if (id) {
			axios
				.get(API.analysis.working_hours, {
					params: {
						employee: id,
						from: FilterDateRange[0]?.toISOString(),
						to: FilterDateRange[1]?.toISOString(),
					},
				})
				.then((res) => {
					let data = res?.data?.data;
					let hrs = data.workingHours ? getHours(data.workingHours) : '--:--';
					setWorking_hrs_weekly_Filtered(hrs);
				});
		}
	}, [FilterDateRange, id]);

	const handleCloseIconsRequested = () => {
		setAnchorElIconsRequested(null);
	};
	const onRefreshQrCode = () => {
		axios
			.post(`${API.user.user}/${id}/logout`)
			.then((res) => {
				setRefreshData(!RefreshData);
			})
			.catch((err) => {});
	};
	const handleChange_Date = (newValue: Date | null, name: string) => {
		setvalidDateMessage('');
		// let year = moment(Data.day).set({ hour: 0, minute: 0, second: 0 }).years();
		// let month = moment(Data.day).set({ hour: 0, minute: 0, second: 0 }).month(); //+ 1;
		// let day = moment(Data.day).set({ hour: 0, minute: 0, second: 0 }).date();

		// function getCheckDate(date: Date | null) {
		// 	let hour = moment(date).hours();
		// 	let min = moment(date).minutes();
		// 	let _date = moment(Data.day).startOf('day').add(hour, 'hours').add(min, 'minutes').toISOString();
		// 	return _date;
		// }
		// if (moment(getCheckDate(newValue)) < moment(Data.checkIn)) {
		// 	setvalidDateMessage('CheckOut should be greater than checKIn');
		// }

		if (!moment(newValue).isSameOrBefore(new Date())) {
			setvalidDateMessage('The value must be less than or equal to the current time.');
		}
		if (name === 'checkOut') {
			if (moment(newValue) <= moment(Data.checkIn)) {
				setvalidDateMessage('CheckOut should be greater than checKIn');
			}
			let duration = moment.duration(moment(newValue).diff(Data.checkIn));
			let hours = duration.asHours();
			if (hours > 24) {
				setvalidDateMessage('The difference between checkIn and checkOut must be less than or equal to 24 hours');
			}
			//console.log('hours', hours);
		}
		if (name === 'checkIn') {
			if (moment(newValue) > moment(Data.checkOut)) {
				setvalidDateMessage('CheckIn should be less than checKOut');
			}
			let duration = moment.duration(moment(Data.checkOut).diff(newValue));
			let hours = duration.asHours();
			if (hours > 24) {
				setvalidDateMessage('The difference between check in and check out must be less than or equal to 24 hours');
			}
			//console.log('hours', hours);
		}
		setData((prev) => ({
			...prev,
			[name]: newValue,
		}));
	};
	const handleChange_Update_Date = (newValue: Date | null, name: string) => {
		setValidUpdateDateMessage('');
		// let year = moment(UpdateDataAttendance.day).set({ hour: 0, minute: 0, second: 0 }).years();
		// let month = moment(UpdateDataAttendance.day).set({ hour: 0, minute: 0, second: 0 }).month(); //+ 1;
		// let day = moment(UpdateDataAttendance.day).set({ hour: 0, minute: 0, second: 0 }).date();

		// function getCheckDate(date: Date | null) {
		// 	let hour = moment(date).hours();
		// 	let min = moment(date).minutes();
		// 	let _date = moment(date).set({ year: year, month: month, date: day, hour: hour, minute: min, second: 0 });
		// 	return _date;
		// }
		// if (UpdateDataAttendance.checkOut) {
		// 	if (moment(getCheckDate(newValue)) < moment(UpdateDataAttendance.checkIn)) {
		// 		setValidUpdateDateMessage('CheckOut should be greater than checKIn');
		// 	}

		// 	if (name === 'checkIn' && moment(getCheckDate(newValue)) > moment(UpdateDataAttendance.checkOut)) {
		// 		setValidUpdateDateMessage('CheckIn should be less than checKOut');
		// 	}
		// }

		if (!moment(newValue).isSameOrBefore(new Date())) {
			setValidUpdateDateMessage('The value must be less than or equal to the current time.');
		}
		if (name === 'checkOut') {
			if (moment(newValue) <= moment(UpdateDataAttendance.checkIn)) {
				setValidUpdateDateMessage('CheckOut should be greater than checKIn');
			}
			let duration = moment.duration(moment(newValue).diff(UpdateDataAttendance.checkIn));
			let hours = duration.asHours();
			if (hours > 24) {
				setValidUpdateDateMessage(
					'The difference between checkIn and checkOut must be less than or equal to 24 hours'
				);
			}
			//console.log('hours', hours);
		}
		if (name === 'checkIn') {
			if (moment(newValue) > moment(UpdateDataAttendance.checkOut)) {
				setValidUpdateDateMessage('CheckIn should be less than checKOut');
			}
			let duration = moment.duration(moment(UpdateDataAttendance.checkOut).diff(newValue));
			let hours = duration.asHours();
			if (hours > 24) {
				setValidUpdateDateMessage(
					'The difference between check in and check out must be less than or equal to 24 hours'
				);
			}
			//console.log('hours', hours);
		}

		setUpdateDataAttendance((prev) => ({
			...prev,
			[name]: newValue,
		}));
	};
	const onAccept = () => {
		axios
			.post(`${API.attendance.attendance}/${SelectedAttendance?.id}/accept`)
			.then((res) => {
				setIsError(false);
				setOpenApprove(false);
				setOpenMessage(true);
				setRefreshData(!RefreshData);
			})
			.catch((err) => {
				setIsError(true);
				setOpenApprove(false);
				setOpenMessage(true);
			});

		handleCloseIconsRequested();
		setSelectedAttendance({});
	};
	const onReject = () => {
		axios
			.post(`${API.attendance.attendance}/${SelectedAttendance?.id}/reject`)
			.then((res) => {
				setIsError(false);
				setOpenReject(false);
				setOpenMessage(true);
				setRefreshData(!RefreshData);
			})
			.catch((err) => {
				setIsError(true);
				setOpenReject(false);
				setOpenMessage(true);
			});

		handleCloseIconsRequested();
		setSelectedAttendance({});
	};
	const addCkeckOut = () => {
		// let year = moment(Data.day).set({ hour: 0, minute: 0, second: 0 }).years();
		// let month = moment(Data.day).set({ hour: 0, minute: 0, second: 0 }).month(); //+ 1;
		// let day = moment(Data.day).set({ hour: 0, minute: 0, second: 0 }).date();

		// function getCheckDate(date: Date | null) {
		// 	let hour = moment(date).hours();
		// 	let min = moment(date).minutes();
		// 	let _date = moment(Data.day).startOf('day').add(hour, 'hours').add(min, 'minutes').toISOString();
		// 	return _date;
		// }
		let requestBody = {
			at: Data.checkOut ? Data.checkOut : undefined, // Data.checkOut ? getCheckDate(Data.checkOut) : undefined,
			reason: Data.reason ? Data.reason : undefined,
		};
		axios
			.post(`/attendance/${SelectedAttendance?.id}/check-out`, requestBody)
			.then((res) => {
				setIsError(false);
				setRefreshData(!RefreshData);
				setErrorMessage(null);
				setOpenMessage(true);
			})
			.catch((err) => {
				setErrorMessage(err.response.data.msg);
				setIsError(true);
				setOpenMessage(true);
			});
		setOpenCkeckOut(false);
		setvalidDateMessage('');
		handleCloseIconsRequested();
		setSelectedAttendance({});
	};
	const updateManual = () => {
		let requestBody: {
			checkIn: {
				at: any;
			};
			checkOut?: {
				at: any;
			};
			reason: any;
		} = {
			checkIn: {
				at: UpdateDataAttendance.checkIn,
			},
			reason: UpdateDataAttendance.reason ? UpdateDataAttendance.reason : undefined,
		};
		if (UpdateDataAttendance.checkOut) {
			requestBody = {
				checkIn: {
					at: UpdateDataAttendance.checkIn,
				},
				checkOut: {
					// optional
					at: UpdateDataAttendance.checkOut ? UpdateDataAttendance.checkOut : undefined,
				},
				reason: UpdateDataAttendance.reason ? UpdateDataAttendance.reason : undefined,
			};
		}
		axios
			.put(`${API.attendance.attendance}/${SelectedAttendance?.id}`, requestBody)
			.then((res) => {
				setIsError(false);
				setRefreshData(!RefreshData);
				setOpenMessage(true);
			})
			.catch((err) => {
				setIsError(true);
				setOpenMessage(true);
			});
		setOpenUpdate(false);
		setValidUpdateDateMessage('');
		handleCloseIconsRequested();
		setSelectedAttendance({});
	};
	const onDelete = () => {
		axios
			.delete(`${API.attendance.attendance}/${SelectedAttendance?.id}`)
			.then((res) => {
				setOpenDeleteDialog(false);
				setIsError(false);
				setOpenMessage(true);
				setRefreshData(!RefreshData);
			})
			.catch((err) => {
				setOpenDeleteDialog(false);
				setIsError(true);
				setOpenMessage(true);
			});

		handleCloseIconsRequested();
		setSelectedAttendance({});
	};
	const addVacation = () => {
		let requestBody = {
			employee: id,
			type: VacationState.type,
			reason: VacationState.reason,
			from: moment(VacationState.from_to[0]).toISOString(),
			to: moment(VacationState.from_to[1]).toISOString(),
		};
		axios
			.post(API.vacation.get, requestBody)
			.then((res) => {
				setIsError(false);
				setRefreshData(!RefreshData);
				setRefreshVacationsData(!RefreshVacationsData);
				setErrorMessage(null);
				setOpenMessage(true);
			})
			.catch((err) => {
				setErrorMessage(err.response.data.msg);
				setIsError(true);
				setOpenMessage(true);
			});
		setOpenAddVacation(false);
		setVacationState({
			type: '',
			reason: undefined,
			from_to: [null, null],
		});
	};
	// Tables
	const columns: GridColDef[] = [
		{
			field: 'from',
			headerName: 'From',
			align: 'center',
			headerAlign: 'center',
			flex: 2,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header left-corner',
		},
		{
			field: 'to',
			headerName: 'To',
			align: 'center',
			headerAlign: 'center',
			flex: 2,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'reason',
			headerName: 'Reason',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			disableExport: true,
			headerClassName: 'table-header',
			renderCell: (params: any) => {
				return (
					<>
						<Tooltip title={params?.row?.reason}>
							<Typography variant='body2' gutterBottom>
								{params?.row?.reason}
							</Typography>
						</Tooltip>
					</>
				);
			},
		},
		{
			field: 'type',
			headerName: 'Type',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'status',
			headerName: 'Status',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'action',
			headerName: 'Actions',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			disableExport: true,
			headerClassName: 'table-header right-corner',
			renderCell: (params: any) => (
				<Box>
					{params.row.status === 'Pending' && (
						<CheckCircleIcon
							sx={{ color: '#007F5F', cursor: 'pointer' }}
							onClick={() => setOpenApproveVacation(true)}
						/>
					)}
					{params.row.status === 'Pending' && (
						<CancelIcon sx={{ color: '#FE0000', cursor: 'pointer' }} onClick={() => setOpenRejectVacation(true)} />
					)}
					<DeleteIcon sx={{ cursor: 'pointer' }} onClick={() => setOpenDeleteVacation(true)} />
				</Box>
			),
		},
	];
	const LogColumns: GridColDef[] = [
		{
			field: 'createdAt',
			headerName: 'Date',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header left-corner',
		},
		{
			field: 'gate',
			headerName: 'Gate',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header',
		},
		{
			field: 'type',
			headerName: 'Type',
			align: 'center',
			headerAlign: 'center',
			flex: 1,
			hideSortIcons: true,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
			headerClassName: 'table-header right-corner',
		},
	];
	const onAcceptVacation = () => {
		axios
			.post(`${API.vacation.get}/${RowDataVacation?.id}/accept`)
			.then((res) => {
				setIsError(false);
				setOpenApproveVacation(false);
				setOpenMessage(true);
				setRefreshVacationsData(!RefreshVacationsData);
			})
			.catch((err) => {
				setIsError(true);
				setOpenApproveVacation(false);
				setOpenMessage(true);
			});
	};
	const onRejectVacation = () => {
		axios
			.post(`${API.vacation.get}/${RowDataVacation?.id}/reject`)
			.then((res) => {
				setIsError(false);
				setOpenRejectVacation(false);
				setOpenMessage(true);
				setRefreshVacationsData(!RefreshVacationsData);
			})
			.catch((err) => {
				setIsError(true);
				setOpenRejectVacation(false);
				setOpenMessage(true);
			});
	};
	const onDeleteVacation = () => {
		axios
			.delete(`${API.vacation.get}/${RowDataVacation?.id}`)
			.then((res) => {
				setOpenDeleteVacation(false);
				setIsError(false);
				setOpenMessage(true);
				setRefreshVacationsData(!RefreshVacationsData);
			})
			.catch((err) => {
				setOpenDeleteVacation(false);
				setIsError(true);
				setOpenMessage(true);
			});
	};
	return (
		<>
			<Box height={1} p={3} className='profile'>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Grid container p={2} item spacing={3} justifyContent='space-between' alignItems='center'>
							<Typography variant='h5' gutterBottom fontWeight={'600'}>
								Employee Profile
							</Typography>
							<Box sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
								<Link to={`/employees/new-employee/${id}`}>
									<Button
										variant='contained'
										sx={{ textTransform: 'capitalize', marginLeft: '5px' }}
										startIcon={<EditIcon />}
									>
										Edit
									</Button>
								</Link>
								<Button
									variant='outlined'
									onClick={onRefreshQrCode}
									sx={{ textTransform: 'capitalize', marginLeft: '5px' }}
									startIcon={<RefreshIcon />}
								>
									Logout all
								</Button>
							</Box>
						</Grid>
					</Grid>
					<Grid item xs={6}>
						<Grid container item xs={12} alignItems='center'>
							<Box component='img' alt='' width={'100px'} height={'80px'} src={EmployeeInfo.img} mr={1} />
							<Box>
								<Typography variant='h6' gutterBottom>
									{EmployeeInfo.name}
								</Typography>
								<Typography variant='body2' gutterBottom sx={{ color: '#707070', fontSize: '14px' }}>
									{EmployeeInfo.job_title}
								</Typography>
								<Typography variant='body2' gutterBottom sx={{ color: '#FE0000', fontSize: '14px' }}>
									{`$ ${EmployeeInfo?.rate}/Hrs`}
								</Typography>
							</Box>
							<Box p={1} pr={2} sx={{ marginLeft: 'auto', backgroundColor: '#F1F1F1', borderRadius: '8px' }}>
								<Typography variant='subtitle2' gutterBottom>
									Amount Earned
								</Typography>
								<Typography variant='h6' gutterBottom fontWeight={600}>
									$ {EmployeeInfo.amount_earned}
								</Typography>
							</Box>
						</Grid>
						<Grid container item xs={12} mt={1}>
							<Grid item xs={12}>
								<Typography variant='h6' gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
									<PersonIcon />
									Personal
								</Typography>
							</Grid>
							<Grid container item xs={12}>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Email</span> <br />
										{EmployeeInfo.email}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Mobile Number</span> <br />
										{EmployeeInfo.mobile_number}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Gendar</span> <br />
										{EmployeeInfo.gendar}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Birthday</span> <br />
										{EmployeeInfo.birthday}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid container item xs={12} mt={1}>
							<Grid item xs={12}>
								<Typography variant='h6' gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
									<FmdGoodIcon />
									Location
								</Typography>
							</Grid>
							<Grid container item xs={12}>
								<Grid item xs={12}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Address</span> <br />
										{EmployeeInfo.address}
									</Typography>
								</Grid>
								{/* <Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Country</span> <br />
										{EmployeeInfo.country}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>City</span> <br />
										{EmployeeInfo.city}
									</Typography>
								</Grid> */}
							</Grid>
						</Grid>
						<Grid container item xs={12} mt={1}>
							<Grid item xs={12}>
								<Typography variant='h6' gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
									<WorkIcon />
									Work Details
								</Typography>
							</Grid>
							<Grid container item xs={12}>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Work-Email</span> <br />
										{EmployeeInfo.work_email}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Work-Mobile</span> <br />
										{EmployeeInfo.work_mobile}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Department</span> <br />
										{EmployeeInfo.department}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Supervisor</span> <br />
										{EmployeeInfo.supervisor}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Hire Date</span> <br />
										{EmployeeInfo.hire_date}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Termination Date</span> <br />
										{EmployeeInfo.termination_date}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Check in Time</span> <br />
										{EmployeeInfo.check_in_time}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Check Out Time</span> <br />
										{EmployeeInfo.check_out_time}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Vacation Level</span> <br />
										{EmployeeInfo.vacation_level}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Vacation Type</span> <br />
										{EmployeeInfo.vacation_type}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Vacation Sick</span> <br />
										{EmployeeInfo.vacation.sick}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Vacation Casual</span> <br />
										{EmployeeInfo.vacation.casual}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>TMW ID</span> <br />
										{EmployeeInfo.tmw_id}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Employee PIN Code</span> <br />
										{EmployeeInfo.pin_code}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant='subtitle2' gutterBottom>
										<span style={{ color: '#707070' }}>Employee QR Code</span> <br />
										<QRCodeSVG
											size={100}
											value={EmployeeInfo?.qr_code}
											onClick={() => setOpenDialog(true)}
											style={{ cursor: 'pointer' }}
										/>
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={1} />

					<Grid item xs={5}>
						<Grid container item xs={12} spacing={2}>
							<Grid item xs={12}>
								<Box
									p={1}
									pr={2}
									sx={{
										marginLeft: 'auto',
										backgroundColor: '#F1F1F1',
										borderRadius: '8px',
										height: '60px',
									}}
									display='flex'
									alignItems={'center'}
									justifyContent={'space-between'}
								>
									<Typography variant='subtitle2' gutterBottom>
										{moment(FilterDateRange[0]).format('YYYY/MM/DD')}
									</Typography>
									<Typography variant='subtitle2' gutterBottom>
										{working_hrs_weekly_Filtered}
									</Typography>
									<Typography variant='subtitle2' gutterBottom>
										{moment(FilterDateRange[1]).format('YYYY/MM/DD')}
									</Typography>
									<Box>
										<LocalizationProviderPro
											dateAdapter={AdapterDateFns}
											localeText={{ start: 'From', end: 'To' }}
											sx={{
												// '& .css-j5h6pi-MuiPopper-root': {
												// 	//transform: 'translate(180px, -130.667px) !important',
												// 	left: '-50px',
												// },

												'&.css-1smlk3z-MuiPaper-root': {
													// position: 'relative',
													// left: '-200px',
												},
											}}
										>
											<DateRangePicker
												value={FilterDateRange}
												onChange={(newValue: DateRange<Date>) => {
													newValue[1] = moment(newValue[1]).endOf('day').toDate();
													setFilterDateRange(newValue);
												}}
												renderInput={(startProps, endProps) => (
													<React.Fragment>
														<CalendarMonthIcon
															sx={{ cursor: 'pointer' }}
															onClick={() => {
																document.getElementById('from_input_id')?.click();
															}}
														/>
														<TextField
															sx={{ display: 'none' }}
															{...startProps}
															id='from_input_id'
														/>
														<TextField sx={{ display: 'none' }} {...endProps} />
													</React.Fragment>
												)}
											/>
										</LocalizationProviderPro>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box
									p={1}
									pr={2}
									sx={{
										marginLeft: 'auto',
										backgroundColor: '#F1F1F1',
										borderRadius: '8px',
										height: '112px',
									}}
								>
									<Typography variant='subtitle2' gutterBottom>
										Working Hr’s Today
									</Typography>
									<Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
										<Typography
											variant='caption'
											gutterBottom
											sx={{ display: 'flex', alignItems: 'center' }}
										>
											<Box component='img' alt='' src={'/images/check_in.png'} />
											{EmployeeInfo.working_hrs_today.check_in_time}
										</Typography>
										<Typography
											variant='caption'
											gutterBottom
											sx={{ display: 'flex', alignItems: 'center' }}
										>
											<Box component='img' alt='' src={'/images/check_out.png'} />
											{EmployeeInfo.working_hrs_today.check_out_time}
										</Typography>
									</Stack>
									<Typography variant='h4' gutterBottom fontWeight={600}>
										{EmployeeInfo.working_hrs_today.hrs}
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box
									p={1}
									pr={2}
									sx={{
										marginLeft: 'auto',
										backgroundColor: '#F1F1F1',
										borderRadius: '8px',
										height: '112px',
									}}
								>
									<Typography variant='subtitle2' gutterBottom>
										Semi Monthly
									</Typography>
									<Typography variant='caption'>
										{/* {moment(moment().startOf('week').subtract(1, 'week').toDate()).format('YYYY/MM/DD')} */}
										{moment(SemiMonthlyDate.from).format('YYYY/MM/DD')}
										&nbsp;-&nbsp;
										{moment(SemiMonthlyDate.to).format('YYYY/MM/DD')}
										{/* {moment(moment().endOf('week').toDate()).format('YYYY/MM/DD')} */}
									</Typography>
									<Typography variant='h4' fontWeight={600} gutterBottom mt={1}>
										{EmployeeInfo.working_hrs_weekly.hrs}
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
									<Typography
										variant='subtitle1'
										gutterBottom
										sx={{ display: 'flex', alignItems: 'center', fontWeight: '500' }}
									>
										Last 15 Days
									</Typography>
									<Link to={`/employees/history/${id}`}>
										<Typography
											variant='subtitle1'
											gutterBottom
											sx={{ display: 'flex', alignItems: 'center', fontWeight: '500' }}
										>
											History
											<TrendingFlatIcon />
										</Typography>
									</Link>
								</Stack>
							</Grid>
							<Grid item xs={12} maxHeight={'650px'} sx={{ overflow: 'auto' }}>
								<Grid container item spacing={2}>
									{SemiMonthlyAttendance.map((val, i) => (
										<Grid key={i} item xs={12}>
											<Box
												p={1}
												sx={{
													bgcolor: '#F1F1F1',
													borderRadius: '8px',
													height: '85px',
													borderLeft: 8,
												}}
											>
												<Stack
													direction='row'
													justifyContent='space-between'
													alignItems='center'
													spacing={2}
												>
													<Typography variant='subtitle1' gutterBottom fontWeight={500}>
														{val.date}
													</Typography>
													<Box>
														<IconButton
															aria-label='more'
															id={`requested-buttons-${val?.id}`}
															aria-controls={
																openIconsRequested ? `menu-requested-${val?.id}` : undefined
															}
															aria-expanded={openIconsRequested ? 'true' : undefined}
															aria-haspopup='true'
															onClick={(event: React.MouseEvent<HTMLElement>) => {
																setSelectedAttendance(val);
																setData((prev) => ({
																	...prev,
																	day: val?.check_in,
																	checkIn: val?.check_in,
																	checkOut: null,
																	reason: undefined,
																}));
																setUpdateDataAttendance({
																	day: val?.check_in,
																	checkIn: val?.check_in,
																	checkOut: val?.check_out ? val?.check_out : null,
																	reason: val.reason,
																});
																setAnchorElIconsRequested(event.currentTarget);
															}}
														>
															<MoreVertIcon />
														</IconButton>
														<Menu
															id={`menu-requested-${val.id}`}
															className='menu-actions'
															MenuListProps={{
																'aria-labelledby': `requested-buttons-${val.id}`,
															}}
															anchorEl={anchorElIconsRequested}
															open={openIconsRequested}
															onClose={handleCloseIconsRequested}
															PaperProps={{
																style: {
																	width: '15ch',
																	opacity: SelectedAttendance.id !== val.id ? 0 : 1,
																},
															}}
														>
															{SelectedAttendance.status === 'Pending' && (
																<MenuItem
																	className={
																		SelectedAttendance.status !== 'Pending'
																			? 'hide-pending'
																			: ''
																	}
																	onClick={() => setOpenApprove(true)}
																	sx={{
																		display:
																			SelectedAttendance.status !== 'Pending'
																				? 'none'
																				: 'flex',
																	}}
																>
																	<ListItemIcon>
																		<CheckCircleIcon fontSize='small' />
																	</ListItemIcon>
																	<Typography variant='inherit'>Accept</Typography>
																</MenuItem>
															)}
															{SelectedAttendance.status === 'Pending' && (
																<MenuItem
																	className={
																		SelectedAttendance.status !== 'Pending'
																			? 'hide-pending'
																			: ''
																	}
																	onClick={() => setOpenReject(true)}
																	sx={{
																		display:
																			SelectedAttendance.status !== 'Pending'
																				? 'none'
																				: 'flex',
																	}}
																>
																	<ListItemIcon
																		sx={{
																			display:
																				SelectedAttendance.status !== 'Pending'
																					? 'none'
																					: 'flex',
																		}}
																	>
																		<CancelIcon fontSize='small' />
																	</ListItemIcon>
																	<Typography
																		sx={{
																			display:
																				SelectedAttendance.status !== 'Pending'
																					? 'none'
																					: 'flex',
																		}}
																		variant='inherit'
																	>
																		Reject
																	</Typography>
																</MenuItem>
															)}
															{SelectedAttendance.has_check_out === false ? (
																<MenuItem
																	className={
																		SelectedAttendance.has_check_out !== false
																			? 'hide-checkout'
																			: ''
																	}
																	onClick={() => {
																		setOpenCkeckOut(true);
																	}}
																	sx={{
																		display:
																			SelectedAttendance.has_check_out !== false
																				? 'none'
																				: 'flex',
																	}}
																>
																	<ListItemIcon
																		sx={{
																			display:
																				SelectedAttendance.has_check_out !== false
																					? 'none'
																					: 'flex',
																		}}
																	>
																		<AccessTimeIcon fontSize='small' />
																	</ListItemIcon>
																	<Typography
																		sx={{
																			display:
																				SelectedAttendance.has_check_out !== false
																					? 'none'
																					: 'flex',
																		}}
																		variant='inherit'
																	>
																		CheckOut
																	</Typography>
																</MenuItem>
															) : null}
															<MenuItem
																onClick={() => {
																	// setSelectedAttendance(val);
																	// setData((prev) => ({
																	// 	...prev,
																	// 	day: val?.check_in,
																	// 	checkIn: val?.check_in,
																	// 	checkOut: undefined,
																	// 	reason: undefined,
																	// }));
																	// setUpdateDataAttendance({
																	// 	day: val?.check_in,
																	// 	checkIn: val?.check_in,
																	// 	checkOut: val?.check_out ? val?.check_out : undefined,
																	// 	reason: val.reason,
																	// });
																	setOpenUpdate(true);
																}}
															>
																<ListItemIcon>
																	<ModeEditIcon fontSize='small' />
																</ListItemIcon>
																<Typography variant='inherit'>Edit</Typography>
															</MenuItem>
															<MenuItem onClick={() => setOpenDeleteDialog(true)}>
																<ListItemIcon>
																	<DeleteIcon fontSize='small' />
																</ListItemIcon>
																<Typography variant='inherit'>Delete</Typography>
															</MenuItem>
														</Menu>
													</Box>
												</Stack>

												<Stack
													onClick={() => {
														if (val?.status === 'Pending') {
															navigate('/requests');
														}
													}}
													direction='row'
													justifyContent='flex-start'
													alignItems='center'
													spacing={2}
													sx={{ cursor: 'pointer' }}
												>
													<Typography
														variant='caption'
														gutterBottom
														sx={{
															display: 'flex',
															alignItems: 'center',
															color: '#007F5F',
															width: '30%',
														}}
													>
														{/* <Box component='img' alt='' src={'/images/check_in.png'} /> &nbsp; */}
														{val?.check_in_type !== 'Automated' ? (
															(types as any)[val?.check_in_type]
														) : (
															<Box component='img' alt='' src={'/images/check_in.png'} />
														)}
														&nbsp;
														{val.check_in_time}
													</Typography>
													<Typography
														variant='caption'
														gutterBottom
														sx={{
															display: 'flex',
															alignItems: 'center',
															color: '#707070',
															width: '30%',
														}}
													>
														{/* <Box component='img' alt='' src={'/images/check_out.png'} /> */}
														{val?.check_out_type !== 'Automated' ? (
															(types as any)[val?.check_out_type]
														) : (
															<Box component='img' alt='' src={'/images/check_out.png'} />
														)}
														&nbsp; {val.check_out_time}
													</Typography>
													<Typography
														variant='caption'
														gutterBottom
														sx={{
															display: 'flex',
															alignItems: 'center',
															color: '#000000',
															width: '30%',
														}}
													>
														<Box component='img' alt='' src={'/images/check.png'} />
														&nbsp;{val.hrs}
													</Typography>
													<Stack
														direction='row'
														justifyContent='flex-start'
														alignItems='center'
														spacing={2}
													>
														{val.requestedAt && (
															<Chip
																size='small'
																label='Manual Time'
																sx={{
																	borderRadius: '3px',
																	bgcolor: (color as any)[val?.status],
																	lineHeight: '18px',
																	color: '#fff',
																}}
															/>
														)}
													</Stack>
												</Stack>
											</Box>
										</Grid>
									))}
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container item xs={12} spacing={2}>
							<Grid item xs={10}>
								<Typography variant='h6' gutterBottom sx={{ fontWeight: '500' }}>
									Vacation
								</Typography>
							</Grid>
							<Grid item xs={2}>
								<Button
									onClick={() => setOpenAddVacation(true)}
									sx={{ textTransform: 'capitalize', width: '100%' }}
									variant='contained'
								>
									Add New Vacation
								</Button>
							</Grid>
							<Grid item xs={12} height={Vacations.length === 0 && !LoadingVacations ? '50px' : '560px'}>
								{LoadingVacations && <Skeleton variant='rectangular' width={'100%'} height={118} />}
								{Vacations.length > 0 && !LoadingVacations && (
									<Box
										height={1}
										sx={{
											'& .table-header': {
												backgroundColor: '#dadada',
											},
											'& .left-corner': {
												borderTopLeftRadius: '15px',
											},
											'& .right-corner': {
												borderTopRightRadius: '15px',
											},
											'& .css-f3jnds-MuiDataGrid-columnHeaders': {
												borderTopRightRadius: '15px !important',
												borderTopLeftRadius: '15px !important',
												background: '#dadada !important',
											},
										}}
									>
										<DataGridPremium
											className='custom-grid-class'
											onRowClick={(rowData) => setRowDataVacation(rowData?.row)}
											columns={columns}
											rows={Vacations || []}
											apiRef={apiRef}
											disableSelectionOnClick
											pagination
											paginationMode='server'
											page={page}
											onPageChange={(newPage) => setPage(newPage)}
											pageSize={pageSize}
											onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
											rowsPerPageOptions={[5, 10, 20, 50]}
											rowCount={totalRecords}
											disableColumnResize
											disableColumnReorder
											disableChildrenSorting
											components={{
												Toolbar: CustomToolbar,
											}}
										/>
									</Box>
								)}
								{Vacations.length === 0 && !LoadingVacations && (
									<Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
										<Typography variant='h5' gutterBottom fontWeight={'500'}>
											No Vacations Yet!
										</Typography>
									</Stack>
								)}
							</Grid>
							<Grid item xs={6}>
								<Typography variant='h6' gutterBottom sx={{ fontWeight: '500' }}>
									Log
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<LocalizationProviderPro
									dateAdapter={AdapterDateFns}
									localeText={{ start: 'From', end: 'To' }}
								>
									<DateRangePicker
										value={LogDateRange}
										onChange={(newValue: DateRange<Date>) => {
											setLogDateRange(newValue);
											setLogPage(0);
										}}
										renderInput={(startProps, endProps) => (
											<React.Fragment>
												<TextField
													sx={{ width: '49%', height: '59px' }}
													className='input-style'
													{...startProps}
												/>
												<Box sx={{ mx: 2 }}> </Box>
												<TextField
													sx={{ width: '49%', height: '59px' }}
													className='input-style'
													{...endProps}
												/>
											</React.Fragment>
										)}
									/>
								</LocalizationProviderPro>
							</Grid>
							<Grid item xs={12} height={Logs?.length === 0 && !LoadingLogs ? '30px' : '560px'}>
								{LoadingLogs && <Skeleton variant='rectangular' width={'100%'} height={118} />}
								{Logs?.length > 0 && !LoadingLogs && (
									<Box
										height={1}
										sx={{
											'& .table-header': {
												backgroundColor: '#dadada',
											},
											'& .left-corner': {
												borderTopLeftRadius: '15px',
											},
											'& .right-corner': {
												borderTopRightRadius: '15px',
											},
											'& .css-f3jnds-MuiDataGrid-columnHeaders': {
												borderTopRightRadius: '15px !important',
												borderTopLeftRadius: '15px !important',
												background: '#dadada !important',
											},
										}}
									>
										<DataGridPremium
											className='custom-grid-class'
											columns={LogColumns}
											rows={Logs || []}
											apiRef={apiRef}
											disableSelectionOnClick
											pagination
											paginationMode='server'
											page={page}
											onPageChange={(newPage) => setLogPage(newPage)}
											pageSize={pageSize}
											onPageSizeChange={(newPageSize) => setLogPageSize(newPageSize)}
											rowsPerPageOptions={[5, 10, 20, 50]}
											rowCount={LogTotalRecords}
											disableColumnResize
											disableColumnReorder
											disableChildrenSorting
											components={{
												Toolbar: CustomToolbar,
											}}
										/>
									</Box>
								)}
								{Logs?.length === 0 && !LoadingLogs && (
									<Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
										<Typography variant='h5' gutterBottom fontWeight={'500'}>
											No Logs Yet!
										</Typography>
									</Stack>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
			{/** Show QRCode */}
			<Dialog
				open={OpenDialog}
				keepMounted
				onClose={() => setOpenDialog(false)}
				aria-describedby='alert-dialog-slide-description'
			>
				<Box p={2}>
					<QRCodeSVG size={300} value={EmployeeInfo?.qr_code} />
				</Box>
			</Dialog>

			{/** Approve Attendance */}
			<ConfirmDialog
				open={openApprove}
				onClose={() => {
					setOpenApprove(false);
					handleCloseIconsRequested();
				}}
				title='Approve Vacation'
				message={`Are you sure you want to approve this request from ${EmployeeInfo?.name}`}
				cancelLabel='Close'
				saveLabel='Approve'
				onApply={onAccept}
			/>
			{/** Reject Attendance  */}
			<ConfirmDialog
				open={openReject}
				onClose={() => {
					setOpenReject(false);
					handleCloseIconsRequested();
				}}
				title='Reject Vacation'
				message={`Are you sure you want to reject this request from ${EmployeeInfo?.name}`}
				cancelLabel='Close'
				saveLabel='Reject'
				onApply={onReject}
			/>
			{/** Delete Attendance */}
			<ConfirmDialog
				open={OpenDeleteDialog}
				onClose={() => {
					setOpenDeleteDialog(false);
					handleCloseIconsRequested();
				}}
				title=''
				message='Are you sure you want to delete this request?'
				cancelLabel='Close'
				saveLabel='Delete'
				onApply={onDelete}
			/>
			{/** Check out */}
			<Dialog
				open={OpenCkeckOut}
				onClose={() => setOpenCkeckOut(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogContent>
					<Grid container spacing={2} height={'100%'}>
						<Grid item xs={12}>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Check Out Time
							</Typography>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DateTimePicker
									label=''
									value={Data.checkOut}
									onChange={(newValue: Date | null) => handleChange_Date(newValue, 'checkOut')}
									renderInput={(params: any) => {
										params.inputProps.placeholder = 'mm/dd/yyyy hh:mm';
										return <TextField fullWidth className='input-style' {...params} />;
									}}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='caption' style={{ color: '#FE0000' }} gutterBottom component='div'>
								{validDateMessage}
							</Typography>{' '}
						</Grid>
						<Grid item xs={12}>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Reason
							</Typography>
							<TextField
								name='reason'
								value={Data.reason}
								onChange={(e) => setData((prev: any) => ({ ...prev, reason: e.target.value }))}
								style={{ width: '100%' }}
								InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button
						variant='outlined'
						sx={{ textTransform: 'capitalize', width: '160px' }}
						onClick={() => {
							setvalidDateMessage('');
							setOpenCkeckOut(false);
							handleCloseIconsRequested();
						}}
					>
						Cancel
					</Button>
					<Button
						variant='contained'
						sx={{ textTransform: 'capitalize', width: '160px' }}
						onClick={addCkeckOut}
						autoFocus
						disabled={validDateMessage.length > 0 || DisabledManual}
					>
						Add
					</Button>
				</DialogActions>
			</Dialog>
			{/** Update Attendance  */}
			<Dialog
				open={OpenUpdate}
				onClose={() => setOpenUpdate(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogContent>
					<Grid container spacing={2} height={'100%'}>
						<Grid item xs={6}>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Check in Time
							</Typography>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DateTimePicker
									label=''
									value={UpdateDataAttendance.checkIn}
									onChange={(newValue: Date | null) => handleChange_Update_Date(newValue, 'checkIn')}
									renderInput={(params: any) => {
										params.inputProps.placeholder = 'mm/dd/yyyy hh:mm';
										return <TextField className='input-style' {...params} />;
									}}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={6}>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Check Out Time
							</Typography>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DateTimePicker
									label=''
									value={UpdateDataAttendance.checkOut}
									disabled={UpdateDataAttendance.checkOut ? false : true}
									onChange={(newValue: Date | null) => handleChange_Update_Date(newValue, 'checkOut')}
									renderInput={(params: any) => {
										params.inputProps.placeholder = 'mm/dd/yyyy hh:mm';
										return <TextField className='input-style' {...params} />;
									}}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='caption' style={{ color: '#FE0000' }} gutterBottom component='div'>
								{validUpdateDateMessage}
							</Typography>{' '}
						</Grid>
						<Grid item xs={12}>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Reason
							</Typography>
							<TextField
								name='reason'
								value={UpdateDataAttendance.reason}
								onChange={(e) => setUpdateDataAttendance((prev: any) => ({ ...prev, reason: e.target.value }))}
								style={{ width: '100%' }}
								InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button
						variant='outlined'
						sx={{ textTransform: 'capitalize', width: '160px' }}
						onClick={() => {
							setOpenUpdate(false);
							setValidUpdateDateMessage('');
							handleCloseIconsRequested();
						}}
					>
						Cancel
					</Button>
					<Button
						variant='contained'
						sx={{ textTransform: 'capitalize', width: '160px' }}
						onClick={updateManual}
						autoFocus
						disabled={validUpdateDateMessage.length > 0 || DisabledUpdateDate}
					>
						Update
					</Button>
				</DialogActions>
			</Dialog>

			{/* Add Vacation */}
			<Dialog
				open={OpenAddVacation}
				onClose={() => setOpenAddVacation(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogContent>
					<Grid container spacing={2} height={'100%'}>
						<Grid item xs={12}>
							<Typography variant='h6' gutterBottom>
								Add Vacation
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<LocalizationProviderPro dateAdapter={AdapterDateFns} localeText={{ start: 'From', end: 'To' }}>
								<DateRangePicker
									value={VacationState.from_to}
									onChange={(newValue: DateRange<Date>) =>
										setVacationState((prev: any) => ({ ...prev, from_to: newValue }))
									}
									renderInput={(startProps, endProps) => (
										<React.Fragment>
											<TextField className='input-style' fullWidth {...startProps} />
											<Box sx={{ mx: 2 }}> to </Box>
											<TextField className='input-style' fullWidth {...endProps} />
										</React.Fragment>
									)}
								/>
							</LocalizationProviderPro>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Type
							</Typography>
							<FormControl fullWidth>
								<Select
									name='type'
									value={VacationState.type}
									onChange={(e) => setVacationState((prev: any) => ({ ...prev, type: e.target.value }))}
									label='type'
									className='input-style'
								>
									<MenuItem value={'Casual'}>Casual</MenuItem>
									<MenuItem value={'Sick'}>Sick</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='subtitle1' gutterBottom component='div'>
								Reason
							</Typography>
							<TextField
								name='reason'
								value={VacationState.reason}
								onChange={(e) => setVacationState((prev: any) => ({ ...prev, reason: e.target.value }))}
								style={{ width: '100%' }}
								InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button
						variant='outlined'
						sx={{ textTransform: 'capitalize', width: '160px' }}
						onClick={() => {
							setOpenAddVacation(false);
							setVacationState({
								type: '',
								reason: undefined,
								from_to: [null, null],
							});
						}}
					>
						Cancel
					</Button>
					<Button
						variant='contained'
						sx={{ textTransform: 'capitalize', width: '160px' }}
						onClick={addVacation}
						autoFocus
					>
						Add
					</Button>
				</DialogActions>
			</Dialog>
			{/** Approve Vacation */}
			<ConfirmDialog
				open={openApproveVacation}
				onClose={() => setOpenApproveVacation(false)}
				title='Approve Vacation'
				message={`Are you sure you want to approve this vacation from ${RowDataVacation?.name}`}
				cancelLabel='Close'
				saveLabel='Approve'
				onApply={onAcceptVacation}
			/>
			{/** Reject Vacation */}
			<ConfirmDialog
				open={openRejectVacation}
				onClose={() => setOpenRejectVacation(false)}
				title='Reject Vacation'
				message={`Are you sure you want to reject this vacation from ${RowDataVacation?.name}`}
				cancelLabel='Close'
				saveLabel='Reject'
				onApply={onRejectVacation}
			/>
			{/** Delete Vacation */}
			<ConfirmDialog
				open={OpenDeleteVacation}
				onClose={() => setOpenDeleteVacation(false)}
				title=''
				message='Are you sure you want to delete this vacation?'
				cancelLabel='Close'
				saveLabel='Delete'
				onApply={onDeleteVacation}
			/>
			<Snackbar open={OpenMessage} autoHideDuration={6000} onClose={() => setOpenMessage(false)}>
				{IsError ? (
					<Alert onClose={() => setOpenMessage(false)} severity='error' sx={{ width: '100%' }}>
						{ErrorMessage !== null ? `${ErrorMessage}` : 'Request failed!'}
					</Alert>
				) : (
					<Alert onClose={() => setOpenMessage(false)} severity='success' sx={{ width: '100%' }}>
						Request succeeded.
					</Alert>
				)}
			</Snackbar>
		</>
	);
}

export default Profile;
