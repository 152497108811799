import { CSVLink } from 'react-csv';
import Button from '@mui/material/Button';

type ExcelProps = {
	ExcelData: any[];
	DisableExcel: boolean;
};
function Excel({ ExcelData, DisableExcel }: ExcelProps) {
	//console.log('ExcelData', ExcelData);

	let headers = [
		{ label: 'Name', key: 'name' },
		{ label: 'Department', key: 'department' },
		{ label: 'TWD ID', key: 'twd_id' },
		{ label: 'From', key: 'from' },
		{ label: 'To', key: 'to' },
		{ label: 'Total Hours', key: 'total_hours' },

		// { label: 'Date', key: 'date' },
		// { label: 'Check In', key: 'checkIn' },
		// { label: 'Check Out', key: 'checkOut' },
		// { label: 'Lunch Break', key: 'lunchBreak' },
		// { label: 'Total Hours', key: 'workingHours' },
	];

	const onClick = async () => {
		for (let index = 0; index <= ExcelData.length - 1; index++) {
			document.getElementById(`excel-report-${index}`)?.click();
		}
	};
	return (
		<>
			<Button id='excel-btn' onClick={onClick} disabled={DisableExcel} variant='contained'>
				Download
			</Button>
			{ExcelData?.map((val: any, i: number) => (
				<CSVLink
					id={`excel-report-${i}`}
					key={i}
					style={{ display: 'none' }}
					data={val}
					headers={headers}
					filename={val[0].name}
				></CSVLink>
			))}
		</>
	);
}

export default Excel;
