import React from 'react';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import {
	Home as HomeIcon,
	Groups as GroupsIcon,
	QrCode as QrCodeIcon,
	Settings as SettingsIcon,
	AssignmentInd as AssignmentIndIcon,
	Assignment as AssignmentIcon,
	Logout as LogoutIcon,
	Alarm as AlarmIcon,
} from '@mui/icons-material';
const drawerWidth = 240;

interface Props {
	children: React.ReactElement;
}
function Sidebar(props: Props) {
	const { children } = props;
	return (
		<>
			<Box sx={{ display: 'flex' }}>
				<Drawer
					sx={{
						width: drawerWidth,
						flexShrink: 0,
						color: '#fff',
						'& .MuiDrawer-paper': {
							width: drawerWidth,
							boxSizing: 'border-box',
							bgcolor: '#000',
						},
					}}
					variant='permanent'
					anchor='left'
				>
					<Box component='img' sx={{ width: '150px', margin: '15px auto' }} alt='/' src='/images/logo_white.png' />
					<List sx={{ bgcolor: '#000' }}>
						<Link to='/home' style={{ color: '#fff' }}>
							<ListItem disablePadding>
								<ListItemButton>
									<ListItemIcon>
										<HomeIcon style={{ color: '#fff' }} />
									</ListItemIcon>
									<ListItemText primary={'Dashboard'} />
								</ListItemButton>
							</ListItem>
						</Link>
						<Link style={{ color: '#fff' }} to='/employees'>
							<ListItem disablePadding>
								<ListItemButton>
									<ListItemIcon>
										<GroupsIcon style={{ color: '#fff' }} />
									</ListItemIcon>
									<ListItemText primary={'Employees'} />
								</ListItemButton>
							</ListItem>
						</Link>
						<Link style={{ color: '#fff' }} to='/departments'>
							<ListItem disablePadding>
								<ListItemButton>
									<ListItemIcon>
										<AssignmentIndIcon style={{ color: '#fff' }} />
									</ListItemIcon>
									<ListItemText primary={'Departments'} />
								</ListItemButton>
							</ListItem>
						</Link>
						<Link style={{ color: '#fff' }} to='/requests'>
							<ListItem disablePadding>
								<ListItemButton>
									<ListItemIcon>
										<AssignmentIcon style={{ color: '#fff' }} />
									</ListItemIcon>
									<ListItemText primary={'Requests'} />
								</ListItemButton>
							</ListItem>
						</Link>
						<Link style={{ color: '#fff' }} to='/alarms'>
							<ListItem disablePadding>
								<ListItemButton>
									<ListItemIcon>
										<AlarmIcon style={{ color: '#fff' }} />
									</ListItemIcon>
									<ListItemText primary={'Alarms'} />
								</ListItemButton>
							</ListItem>
						</Link>
						<Link style={{ color: '#fff' }} to='/gateways'>
							<ListItem disablePadding>
								<ListItemButton>
									<ListItemIcon>
										<QrCodeIcon style={{ color: '#fff' }} />
									</ListItemIcon>
									<ListItemText primary={'Gateways'} />
								</ListItemButton>
							</ListItem>
						</Link>
						<Link style={{ color: '#fff' }} to='/settings'>
							<ListItem disablePadding>
								<ListItemButton>
									<ListItemIcon>
										<SettingsIcon style={{ color: '#fff' }} />
									</ListItemIcon>
									<ListItemText primary={'Settings'} />
								</ListItemButton>
							</ListItem>
						</Link>
					</List>
					<Box sx={{ marginTop: 'auto' }}>
						<ListItemButton
							sx={{ flexGrow: 1 }}
							onClick={() => {
								localStorage.clear();
								window.location.href = '/';
							}}
						>
							<ListItemIcon sx={{ minWidth: 0, mr: 3, justifyContent: 'center' }}>
								<LogoutIcon sx={{ flexGrow: 1, transform: 'rotate(180deg)', color: '#fff' }} />
							</ListItemIcon>
							<ListItemText primary={'Log out'} sx={{ color: '#fff' }} />
						</ListItemButton>
					</Box>
				</Drawer>
				<Box component='main' sx={{ flexGrow: 1, bgcolor: 'background.default' }}>
					{children}
				</Box>
			</Box>
			{/* <Grid container spacing={2}>
				<Grid item xs={2} sx={{ bgcolor: 'primary.main', color: '#fff' }}>
					<Stack spacing={2}>
						<Link to='/employees'>Employees</Link>
						<Link to='/departments'>Departments</Link>
						<Link to='/requests'>Requests</Link>
						<Link to='/gateways'>Gateways</Link>
						<Link
							to='/login'
							onClick={() => {
								localStorage.clear();
								window.location.href = '/login';
							}}
						>
							Log out
						</Link>
					</Stack>
				</Grid>
				<Grid item xs={10}>
					{children}
				</Grid>
			</Grid> */}
		</>
	);
}

export default Sidebar;
