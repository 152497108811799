import { QRCodeSVG } from 'qrcode.react';

import { Box } from '@mui/material';

export default function Settings() {
	return (
		<>
			<Box marginLeft={'30%'} marginTop={10}>
				<br />
				<QRCodeSVG
					size={700}
					value={
						'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzMzlkNDA1MGYzOGY5MDk3YWIyYWNkMiIsImlhdCI6MTY2NDczNDIxM30.dwK6NZyChhkuaHBMJ8f90v3jy-5YN4Rd3BYew0_GRqM'
					}
				/>
			</Box>
		</>
	);
}
