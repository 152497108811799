export const API = {
	login: '/auth/otp',
	refreshtoken: '/auth/refresh-token',
	user: {
		user: '/user',
		metadata: '/user/metadata',
		basic: '/user/basic',
	},
	department: {
		department: '/department',
	},
	gate: {
		gate: '/gate',
	},
	attendance: {
		attendance: '/attendance',
	},
	analysis: {
		working_hours: '/analysis/working-hours',
		absentees: '/analysis/absentees',
		attendees: '/analysis/attendees',
		department_chart: '/analysis/department-chart',
		counters: '/analysis/counters',
	},
	settings: {
		get: '/settings',
		updatePatch: '/settings',
		metadata: '/settings/metadata',
	},
	vacation: {
		get: '/vacation',
		metadata: '/vacation/metadata',
	},
	alarm: {
		get: '/alarm',
	},
	log: {
		get: '/log',
	},
	notification: {
		subscribe: '/notification/subscribe',
	},
};
