import { useState, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';

import axios from 'axios';
import { API } from '../api';

const baseURL = process.env.REACT_APP_API_HOSTNAME || '';

const t = localStorage.getItem('qrcodeToken');
export default function Settings() {
	// 	return (
	// 		<>
	// 			<Box marginLeft={'30%'} marginTop={10}>
	// 				<br />
	// 				aahahha
	// 				<TextField />
	// 			</Box>
	// 		</>
	// 	);
	// }

	const [Data, setData] = useState<any>({
		name: '',
		qr_code: '',
	});

	const [Token, setToken] = useState<any>(t);

	useEffect(() => {
		axios
			.get(`${API.gate.gate}/mine`, {
				baseURL: baseURL,
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: `Bearer ${Token}`,
				},
			})
			.then((res) => {
				let Data = {
					name: res?.data?.data?.name,
					qr_code: res?.data?.data?.token,
				};
				setData(Data);
			})
			.catch((err) => {
				console.log(err);
			});

		setTimeout(() => {
			window.location.reload();
		}, 3000);
	}, [Token]);

	return (
		<>
			<Box marginLeft={'30%'} marginTop={10}>
				<br />
				{!Token && (
					<TextField
						onBlur={(event) => {
							setToken(event.currentTarget.value);
							localStorage.setItem('qrcodeToken', event.currentTarget.value);
						}}
						// onChange={(event) => {
						// 	console.log(event.currentTarget.value);
						// }}
						style={{ width: '50%' }}
						InputProps={{ style: { backgroundColor: '#f2f2f2' } }}
					/>
				)}
				<br />
				{Data.qr_code && <QRCodeSVG size={700} value={Data.qr_code} />}
			</Box>
		</>
	);
}
